import { enums } from '@solaborate/calls';
import { getRoomSettings } from 'api/adminConfigurations.js';
import { forwardAiAlert, saveManualAlert } from 'api/alerts.js';
import { getDeviceList, getDeviceSettings, updateDeviceSettings } from 'api/devices.js';
import { getIceServers } from 'api/iceServers.js';
import { getInterventionsCount, updateAllDrafts } from 'api/interventions.js';
import { setBioBeatDeviceState } from 'api/measurements.js';
import {
	deleteMonitoringSession,
	getFallRiskLevel,
	getMonitoringSessionDevices,
	getMonitoringSessions,
	saveMonitoringSession,
	updateMonitoringSessionDevices,
} from 'api/monitoring.js';
import {
	getDeviceOwnerPatient,
	getDeviceOwnerPatientWithRetry,
	getPatientByUserId,
	getPatientCareTeamParticipants,
	getPatientInfoObservations,
	getPatientsAiSettings,
	getPrimaryPatientCondition,
	updatePatientAiSettings,
} from 'api/patients.js';
import { snoozeAlertBasedOnTask } from 'api/tasks.js';
import { getCheckedInNurses, getTeams } from 'api/teams.js';
import { getTvWidgets } from 'api/whiteboard.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import ConferenceModal from 'calls/views/ConferenceModal.jsx';
import MonitoringTimeline from 'calls/views/MonitoringTimeline.jsx';
import classNames from 'classnames';
import Alert from 'components/Alert.jsx';
import AlertFeed from 'components/AlertFeedMayo.jsx';
import Button from 'components/Button.jsx';
import { outGoingCallSound } from 'components/CallSounds.jsx';
import ChangeRoomName from 'components/ChangeRoomName.jsx';
import Form from 'components/Form.jsx';
import Grid from 'components/Grid.jsx';
import Input from 'components/Input.jsx';
import LifeSignalsPopup from 'components/LifeSignalsPopup.jsx';
import Loader from 'components/Loader.jsx';
import Modal from 'components/Modal.jsx';
import { VerbalRediretionLanguages } from 'components/Monitoring/enums.js';
import HandoverSession from 'components/Monitoring/HandoverSession.jsx';
import Notification from 'components/Notification.jsx';
import ReOrderFeeds from 'components/ReOrderFeeds.jsx';
import StreamPermissions from 'components/StreamPermissions.jsx';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/Tabs.jsx';
import TransferFeeds from 'components/TransferFeeds.jsx';
import { AiAnalyticType } from 'constants/alerts.js';
import {
	CallWorkflowType,
	GeneralSettings,
	MixedViewVariants,
	MonitoringSettings,
	SettingsCategory,
} from 'constants/configurationEnums.js';
import {
	AiAlertId,
	AiAlertNotificationType,
	AnalyticsCategory,
	CallTypes,
	CameraEventTypes,
	CameraTiltAction,
	CameraTiltDirection,
	CameraType,
	ConditionType,
	ConferenceEndReason,
	DeviceListLevel,
	HelloSettings,
	InviteParticipantFailureReason,
	ManualAlertTypes,
	MeasureDeviceType,
	MeasurementActivityTypes,
	MediaPermissions,
	MediaTypes,
	ObjectType,
	ObservationType,
	OutOfRoomEnums,
	ParticipantRemoveReason,
	ParticipantState,
	PatientAiSetting,
	Roles,
	RTCPeerConnectionEnum,
	ScreenTypes,
	SectorTypes,
	SocketState,
	StreamError,
	UserRoles,
	WhiteboardWidgets,
	WindowSize,
} from 'constants/enums.js';
import { APP_CONFIG, healthCareCdnUrl } from 'constants/global-variables.js';
import {
	CareTeamParticipantRole,
	fallRiskOptions,
	humptyDumptyFallAssessmentScore,
	primaryAdverseEvent,
	primaryPatientCondition,
	primaryPatientRiskFactor,
	privacyModeOptions,
} from 'constants/monitoring.js';
import { generateCustomStyles } from 'constants/react-select-style.js';
import SocketEvents from 'constants/socket-events.js';
import SectorListMayo from 'containers/ SectorListMayo.jsx';
import EhrIntegration from 'containers/EhrIntegration.jsx';
import EmergencyCall from 'containers/EmergencyCall.jsx';
import StreamSettingsTab from 'containers/Monitoring/StreamSettingsTab.jsx';
import VideoFeed from 'containers/Monitoring/VideoFeedMayo.jsx';
import { LOCALES } from 'i18n-translations/locales.js';
import translate from 'i18n-translations/translate.jsx';
import AmbientMonitoring from 'icons/Monitoring/AmbientMonitoring.jsx';
import VideoMonitoring from 'icons/Monitoring/VideoMonitoring.jsx';
import RoomTree from 'icons/TreeView/RoomTree.jsx';
import TreeViewIcon from 'icons/TreeView/TreeViewIcon.jsx';
import { getCompanyId, getUserId, getUserInfo, getUserRole } from 'infrastructure/auth.js';
import { isSettingEnabled } from 'infrastructure/helpers/aiHelper.js';
import { getAlertDetailsById, getNotificationOptions } from 'infrastructure/helpers/alertsHelper.js';
import {
	askForPermission,
	checkForPermission,
	checkIfMediaDevicesPlugged,
	findDeviceById,
	findSectorById,
	getAge,
	getConfigurationMenu,
	getConfigurationValue,
	getConfigurationVariant,
	getStorage,
	getVideoBitrateConfig,
	isJSON,
	skipDuplicatedObjects,
} from 'infrastructure/helpers/commonHelpers.js';
import { minutesToMilliseconds } from 'infrastructure/helpers/dateHelper.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { withSocketFunctionsContext } from 'infrastructure/socket-client/SocketFunctions.jsx';
import _ from 'lodash';
import { ConferenceInfo, FromUser } from 'owt/base/conference.js';
import CallManager from 'owt/p2p/CallManager.js';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { bindActionCreators } from 'redux';
import PTT from 'services/walkieTalkie/ptt.js';
import { actionCreators as aiSettingsActionCreators } from 'state/aiSettings/actions.js';
import callsActionCreators from 'state/calls/actions.js';
import { actionCreators as healthDataActionCreators } from 'state/healthData/actions.js';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import { actionCreators as patientNotesActionCreators } from 'state/patientNotes/actions.js';
import { user } from 'state/user/reducer.js';
import styled from 'styled-components';
import { v4 } from 'uuid';
import IncomingCall from 'views/IncomingCall.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';

const getScreenType = () => {
	if (window.innerWidth <= WindowSize.TABLET && window.innerWidth > WindowSize.MOBILE) {
		return ScreenTypes.TABLET;
	}
	if (window.innerWidth <= WindowSize.MOBILE) {
		return ScreenTypes.MOBILE;
	}
	return ScreenTypes.LAPTOP;
};

class Monitoring extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.callManager = new CallManager(this.context, {
			useCallStats: APP_CONFIG.useCallStats && APP_CONFIG.sendCallStatsOnMonitoring,
			sendCallStatsInterval: APP_CONFIG.sendCallStatsInterval,
		});
		this.pressToTalkAudioRef = React.createRef();
	}

	FeedTypes = {
		Monitoring: 0,
		AmbientMonitoring: 1,
	};

	state = {
		videoFeeds: [],
		conferenceInfo: null,
		fullScreenDeviceId: 0,
		isFeedZoomed: false,
		monitoringSessions: [],
		isFetchingSessionsLoading: true,
		showSessionPopup: false,
		newSessionName: '',
		currentSessionId: null,
		showDeleteSessionModal: false,
		isSessionNotificationVissible: false,
		showMaxPeerConnectionsModal: false,
		currentSector: null,
		disableButtons: false,
		socketState: SocketState.CONNECTED,
		hasActiveConference: false,
		conferenceEndReason: null,
		shouldShowSwitchToHelloCamError: false,
		isVitalSignsVisible: false,
		errorFetchingSessions: '',
		isReorderFeedsVissible: false,
		isTransferSessionVisible: false,
		isSessionLoading: false,
		isObserverConferenceModalOpen: false,
		hasObserverConferenceStarted: false,
		observerConferenceData: {
			participants: [],
			conferenceName: '',
			callType: null,
			roomId: null,
		},
		incomingConferenceInfo: null,
		selectedFeed: null,
		isConversationShown: false,
		channels: [],
		selectedChannel: null,
		isTeamsLoading: true,
		unReadMessages: [],
		isEmergencyCallOpen: false,
		showAllowMicrophoneModal: false,
		recordingStatus: false,
		newEventFired: null,
		sessionIsBeingSaved: false,
		audioFromMonitoringFeeds: false,
		isMicrophoneForAllFeedsEnabled: false,
		isAiDrawingsActive: false,
		isPttJoining: false,
		hasPttJoined: false,
		isNoteShowing: false,
		previousFeedAudioEnabled: {},
		statAlarmError: null,
		voiceOverError: null,
		isStartConferenceInProgress: false,
		alertToMap: null,
		feedToClose: null,
		sessionToClose: null,
		sessionToOpen: null,
		currentTab: 0,
		feedType: this.FeedTypes.Monitoring,
		draggingItem: null,
		feedToSetPrivacyTimer: null,
		privacyTimer: null,
		timerError: '',
		sessionIdMessage: 'copySessionId',
		privacyModeOptions: _.cloneDeep(privacyModeOptions(this.props.intl)),
		processingBusyParticipantsQueue: [],
		processingStatAlarmParticipantsQueue: [],
		reasonToDiscontinueMonitoring: null,
		isTimelineCollapsed: false,
		isStreamSettingsTabOpen: false,
		screenType: getScreenType(),
		discontinueMonitoringReasons: new Map(),
		patientOutOfRoomOptions: new Map(),
	};

	participants = {};

	currentlyProcessingStatAlarmPatients = false;

	currentlyProcessingBusyParticipants = false;

	companyId = getCompanyId();

	userId = getUserId();

	userInfo = getUserInfo();

	playingAlert = null;

	pressToTalkAudioRef = null;

	patientsListRef = null;

	ptt = null;

	bioBeatConfig = APP_CONFIG.bioBeatMapping ? JSON.parse(APP_CONFIG.bioBeatMapping) : [];

	alertInfoTimeout = null;

	analyticsDataTimeout = null;

	feedsToInviteQueue = [];

	fallRiskCode = '3045000080';

	humptyDumptyFallsAssessmentScore = '3045000062';

	bioBeatDeviceFeedState = {
		OFF: 0,
		ON: 1,
	};

	voiceOverLanguages = [
		{ label: this.props.intl.formatMessage({ id: 'english' }), value: LOCALES.ENGLISH, img: 'us-flag.svg' },
		{ label: this.props.intl.formatMessage({ id: 'albanian' }), value: LOCALES.ALBANIAN, img: 'al-flag.svg' },
		{ label: this.props.intl.formatMessage({ id: 'german' }), value: LOCALES.GERMAN, img: 'germany-flag.svg' },
		{ label: this.props.intl.formatMessage({ id: 'spanish' }), value: LOCALES.SPANISH, img: 'spain-flag.svg' },
		{ label: this.props.intl.formatMessage({ id: 'arabic' }), value: LOCALES.ARABIC, img: 'uae-flag.svg' },
	];

	previousParticipantId = null;

	currentSession = { id: 0, name: translate('currentSession'), isActive: true, isCurrent: true };

	userRole = null;

	chatAlertTimeouts = [];

	micStatus = null;

	languages = [
		{ label: 'English', id: VerbalRediretionLanguages.ENGLISH, locale: '' },
		{ label: 'Spanish', id: VerbalRediretionLanguages.SPANISH, locale: 'spanish-' },
		{ label: 'Arabic', id: VerbalRediretionLanguages.ARABIC, locale: 'arabic-' },
		{ label: 'Tagalog', id: VerbalRediretionLanguages.TAGALOG, locale: 'tagalog-' },
		{
			label: 'Mandarin Chinese',
			id: VerbalRediretionLanguages.MANDARIN_CHINESE,
			locale: 'chinese-mandarin-',
		},
	];

	fetchDraftsCount = async (patientId, deviceId) => {
		const response = await getInterventionsCount(patientId, deviceId);
		if (response.error) {
			this.setState({ error: response.error.message });
			return;
		}
		const obj = { ...this.props.patientNotes.savedMode[deviceId], draftsCount: response.totalCount };
		this.props.patientNotesActions.setPatientDataSaved(obj, deviceId);
	};

	handleAiAlertResponse = async data => {
		if (!data.isDraft || !data.deviceId) {
			return;
		}
		const obj = { ...this.props.patientNotes.savedMode[data.deviceId], draftsCount: data.interventionsTotalCount };
		this.props.patientNotesActions.setPatientDataSaved(obj, data.deviceId);
	};

	getFallRiskLabel = found =>
		fallRiskOptions(this.props.intl).find(item => {
			if (item.high) {
				return found?.value >= item.low && found?.value <= item.high;
			}
			return found?.value >= item.low;
		})?.label;

	getHumptyDumptyFallAssessmentLabel = found =>
		humptyDumptyFallAssessmentScore(this.props.intl).find(item => {
			if (item.high) {
				return found?.value >= item.low && found?.value <= item.high;
			}
			return found?.value >= item.low;
		})?.label;

	handleObservationAdded = data => {
		if (!data?.deviceId || !data?.patientId) {
			return;
		}
		const { videoFeeds } = this.state;
		const newVideoFeeds = videoFeeds.map(item => {
			if (item.deviceId === data.deviceId && item.deviceOwner?.userId === data.patientId) {
				const found = data.observations.find(
					item => item.code === this.fallRiskCode || item.code === this.humptyDumptyFallsAssessmentScore
				);
				const newItem = { ...item };
				if (found) {
					if (found.code === this.fallRiskCode) {
						newItem.lastCondition = this.getFallRiskLabel(found);
					}
					if (found.code === this.humptyDumptyFallsAssessmentScore) {
						newItem.lastCondition = this.getHumptyDumptyFallAssessmentLabel(found);
					}
				}
				return newItem;
			}
			return item;
		});
		this.setState({ videoFeeds: newVideoFeeds });
	};

	handleInterventionUpdate = data => {
		if (!data.deviceId) {
			return;
		}
		const obj = { ...this.props.patientNotes.savedMode[data.deviceId], draftsCount: data.interventionsTotalCount };
		this.props.patientNotesActions.setPatientDataSaved(obj, data.deviceId);
	};

	handleManualResponse = async data => {
		if (!data.deviceId) {
			return;
		}

		const found = this.state.videoFeeds.find(item => item.deviceId === data.deviceId);

		if (!found) {
			return;
		}

		const isPatientOutOfRoom = data.alertTypeName?.toLowerCase() === OutOfRoomEnums.PATIENT_OUT_OF_ROOM.toLowerCase();
		const isPatientReturnedToRoom = data.alertTypeName?.toLowerCase() === OutOfRoomEnums.PATIENT_RETURNED_TO_ROOM.toLowerCase();

		let outOfRoomReason = '';

		if (data.comment && this.state.patientOutOfRoomOptions.get(found.healthSystemId).find(item => item.option === data.comment)) {
			outOfRoomReason = data.comment;
		} else {
			outOfRoomReason = this.props.intl.formatMessage({ id: 'other' });
		}

		const obj = {
			...this.props.patientNotes.savedMode[data.deviceId],
			draftsCount: data.interventionsTotalCount,
			...((isPatientOutOfRoom || isPatientReturnedToRoom) && {
				isPatientOutOfRoom,
				outOfRoomReason,
			}),
		};
		if (data.manualAlertTypeId === ManualAlertTypes.DISCONTINUE_MONITORING || isPatientReturnedToRoom) {
			this.props.patientNotesActions.setPatientDataSaved(obj, data.deviceId);
			return;
		}
		if (data.isDraft) {
			this.props.patientNotesActions.setPatientDataSaved(obj, data.deviceId);
		}
	};

	setOutOfRoomOn = (outOfRoom, deviceId, healthSystemId) => {
		let outOfRoomReason = '';
		if (
			outOfRoom.properties &&
			isJSON(outOfRoom.properties) &&
			this.state.patientOutOfRoomOptions
				.get(healthSystemId)
				.find(item => item.option === JSON.parse(outOfRoom.properties).Comment)
		) {
			outOfRoomReason = JSON.parse(outOfRoom.properties).Comment;
		} else {
			outOfRoomReason = this.props.intl.formatMessage({ id: 'other' });
		}
		const obj = {
			...this.props.patientNotes.savedMode[deviceId],
			isPatientOutOfRoom: true,
			outOfRoomReason,
		};
		this.props.patientNotesActions.setPatientDataSaved(obj, deviceId);
	};

	handleTvWidgetUpdated = data => {
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];
		const selectedFeed = newVideoFeeds.find(item => item.deviceId === data.deviceId);
		if (!selectedFeed?.deviceOwner?.mrn || data?.deviceId !== selectedFeed.deviceId) {
			return;
		}
		const widget = data?.tvWidgets?.[0];
		if (!widget || widget?.widgetType !== WhiteboardWidgets.CARE_TEAM || !widget?.value || !isJSON(widget?.value)) {
			return;
		}
		const careTeam = JSON.parse(widget.value);
		if (selectedFeed.deviceOwner) {
			selectedFeed.deviceOwner.careTeam = careTeam;
			this.setState({ videoFeeds: newVideoFeeds });
		}
	};

	handleResize = () => this.setState({ screenType: getScreenType() });

	componentDidMount = async () => {
		window.addEventListener('resize', this.handleResize);
		this.setState({ feedType: this.getDefaultFeedType() });
		this.micStatus = await checkForPermission(MediaTypes.MICROPHONE);
		this.micStatus.onchange = this.onDevicePermissionChange;
		this.userRole = getUserRole();
		this.setMonitoringSessions();
		this.callManager
			.on('peer-stream', this.handlePeerStream)
			.on('update-participant', this.handleUpdateParticipant)
			.on('participant-status', this.handleParticipantStatus)
			.on('participant-busy', this.enqueueBusyHandling)
			.on('device-controls-locked', data =>
				this.setState(prevState => {
					return {
						...prevState,
						videoFeeds: prevState.videoFeeds.map(feed => {
							const newFeed = { ...feed };
							if (newFeed.deviceId === data.deviceId) {
								newFeed.showDeviceControlsLockedModal = true;
							}
							return newFeed;
						}),
					};
				})
			)
			.on('camera-response', this.cameraResponseListener)
			.on('initial-state', this.handleDeviceStateChanged)
			.on('device-state', this.handleDeviceStateChanged)
			.on('socket-state', this.handleSocketState)
			.on('peer-connection-state', this.handlePeerConnectionState)
			.on('end-call', this.handleEndCall)
			.on('local-audio-error', this.onLocalAudioError)
			.on('participant-toggled-streams', this.onAudioTrackToggled)
			.on('re-add-feed', this.deviceStatusChanged)
			.on('feed-reassign', this.onFeedReassign)
			.on('on-disconnected-by-call', this.handleDisconnectedByCall)
			.on('feed-is-online', this.onDeviceOnline)
			.on('p2p-publish-failed', this.handlePeerConnectionFailed)
			.bindSocketEventListeners(false, false);
		this.bindWindowListeners();
		this.context.on(SocketEvents.Conference.ON_DEVICE_CONTROLS_LOCKED, this.onDeviceLockedEventHandler);
		this.context.on(SocketEvents.Alerts.ON_NEW_AI_ALERT, this.onAiAlert);
		this.context.on(SocketEvents.Alerts.ALERT_ANALYTICS_ADDED, this.onNewAnalyticsAdded);
		this.context.on(SocketEvents.Alerts.ON_NEW_MEASUREMENT_ALERT, this.onMeasurementAlert);
		this.context.on(SocketEvents.HelloDevice.RTSP_CAMERA_EVENT, this.handleRTSPcamera);
		this.context.on(SocketEvents.HealthCare.ON_DEVICE_SETTINGS_UPDATED, this.handleDevicePrivacySettingUpdated);
		this.context.on(SocketEvents.HelloDevice.PRIVACY_MODE_UPDATE, this.handlePrivacyModeUpdate);
		this.context.on(SocketEvents.BACKGROUND.BIO_BEAT_DATA, this.onHandleBioBeatData);
		this.context.on(SocketEvents.Conference.ON_MEDIA_CONTROLS_RESPONSE, this.handleDevicesList);
		this.context.on(SocketEvents.Conference.ALERT_PATIENT_AT_RISK_RESPONSE, this.enqueueStatAlarmList);
		this.context.on(SocketEvents.LifeSignals.UNASSIGN_EXTERNAL_DEVICE, this.onUnassignExternalDevice);
		this.context.on(SocketEvents.Alerts.MANUAL_ACTIVITY_ADDED, this.onAddedManualActivity);
		this.context.on(SocketEvents.Alerts.PATIENT_AI_SETTINGS_UPDATED, this.onPatientAiSettingsUpdated);
		this.context.on(SocketEvents.Alerts.AI_NOTIFICATION_TYPES_UPDATED, this.onAiNotificationTypesUpdated);
		this.context.on(SocketEvents.Patient.PATIENT_DISCHARGE, this.onPatientDischarged);
		this.context.on(SocketEvents.HealthCare.MONITORING_SESSION_ADDED, this.onMonitoringHandoverSessionAdded);
		this.context.on(SocketEvents.Alerts.NEW_AI_ALERT_ACTIVITY, this.handleAiAlertResponse);
		this.context.on(SocketEvents.HealthCare.ALERTS_MANUAL_ADDED, this.handleManualResponse);
		this.context.on(SocketEvents.HealthCare.ON_TV_WIDGETS_UPDATED, this.handleTvWidgetUpdated);
		this.context.on(SocketEvents.HealthCare.PATIENT_OBSERVATIONS_ADDED, this.handleObservationAdded);
		this.context.on(SocketEvents.Alerts.ALERTS_INTERVENTION_UPDATED, this.handleInterventionUpdate);

		const userObj = {
			id: this.userInfo.userId,
			name: `${this.userInfo.firstName} ${this.userInfo.lastName}`,
			profilePicture: this.userInfo.profilePicture,
		};
		this.ptt = new PTT(userObj, this.pressToTalkAudioRef.current, this.context, await getIceServers());
		this.props.setIncomingCallRenderedElsewhere(true);
		this.getTeams();

		this.props.healthSystemActions.updateMonitoringDevices({
			inCallDevices: [],
			initiatedDevices: [],
		});
		if (getStorage().activeBioBeatItems && JSON.parse(getStorage().activeBioBeatItems)?.length > 0) {
			const newFeeds = _.cloneDeep(JSON.parse(getStorage().activeBioBeatItems)).map(async item => {
				const { taskId, patientUserId, patientUserGuid, deviceId } = item;
				await this.setBioBeatDeviceStatusToOff({ taskId, patientUserId, patientUserGuid, deviceId });
			});
			await Promise.all(newFeeds);
			getStorage().removeItem('activeBioBeatItems');
		}
	};

	componentDidUpdate = prevProps => {
		if (prevProps.user.userSession.healthSystem.id !== this.props.user.userSession.healthSystem.id) {
			this.getTeams();
		}

		if (
			getConfigurationVariant(prevProps.healthSystemConfigurations[MonitoringSettings.MixedMonitoringView]) !==
			getConfigurationVariant(this.props.healthSystemConfigurations[MonitoringSettings.MixedMonitoringView])
		) {
			this.setState({ feedType: this.getDefaultFeedType() });
		}
	};

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
		this.callManager.unbindSocketEventListeners().unbindTimeouts();
		this.unbindWindowListeners();
		this.context.off(SocketEvents.Conference.ON_DEVICE_CONTROLS_LOCKED, this.onDeviceLockedEventHandler);
		this.context.off(SocketEvents.Alerts.ON_NEW_AI_ALERT, this.onAiAlert);
		this.context.off(SocketEvents.Alerts.ALERT_ANALYTICS_ADDED, this.onNewAnalyticsAdded);
		this.context.off(SocketEvents.Alerts.ON_NEW_MEASUREMENT_ALERT, this.onMeasurementAlert);
		this.context.off(SocketEvents.HelloDevice.RTSP_CAMERA_EVENT, this.handleRTSPcamera);
		this.context.off(SocketEvents.BACKGROUND.BIO_BEAT_DATA, this.onHandleBioBeatData);
		this.context.off(SocketEvents.Conference.ON_MEDIA_CONTROLS_RESPONSE, this.handleDevicesList);
		this.context.off(SocketEvents.Conference.ALERT_PATIENT_AT_RISK_RESPONSE, this.enqueueStatAlarmList);
		this.context.off(SocketEvents.LifeSignals.UNASSIGN_EXTERNAL_DEVICE, this.onUnassignExternalDevice);
		this.context.off(SocketEvents.Alerts.MANUAL_ACTIVITY_ADDED, this.onAddedManualActivity);
		this.context.off(SocketEvents.Alerts.PATIENT_AI_SETTINGS_UPDATED, this.onPatientAiSettingsUpdated);
		this.context.off(SocketEvents.Alerts.AI_NOTIFICATION_TYPES_UPDATED, this.onAiNotificationTypesUpdated);
		this.context.off(SocketEvents.Patient.PATIENT_DISCHARGE, this.onPatientDischarged);
		this.context.off(SocketEvents.HealthCare.MONITORING_SESSION_ADDED, this.onMonitoringHandoverSessionAdded);
		this.context.off(SocketEvents.Alerts.NEW_AI_ALERT_ACTIVITY, this.handleAiAlertResponse);
		this.context.off(SocketEvents.HealthCare.ALERTS_MANUAL_ADDED, this.handleManualResponse);
		this.context.off(SocketEvents.HealthCare.ON_TV_WIDGETS_UPDATED, this.handleTvWidgetUpdated);
		this.context.off(SocketEvents.HealthCare.PATIENT_OBSERVATIONS_ADDED, this.handleObservationAdded);
		this.context.off(SocketEvents.Alerts.ALERTS_INTERVENTION_UPDATED, this.handleInterventionUpdate);

		const { videoFeeds } = this.state;
		const feeds = [...videoFeeds];

		if (feeds.length) {
			this.props.healthSystemActions.updateMonitoringDevices({
				inCallDevices: [],
				initiatedDevices: [],
			});

			feeds.forEach(feed => {
				if (feed.audioTrack) {
					feed.audioTrack.stop();
				}
			});

			this.endMonitoring();
		}

		if (this.playingAlert) {
			this.playingAlert.pause();
			this.playingAlert.currentTime = 0;
		}
		this.chatAlertTimeouts.forEach(element => {
			clearTimeout(element);
		});
		this.props.setIncomingCallRenderedElsewhere(false);

		if (this.ptt) {
			this.ptt.leave();
		}
	}

	getDefaultFeedType = () =>
		getConfigurationVariant(this.props.healthSystemConfigurations[MonitoringSettings.MixedMonitoringView]) ===
		MixedViewVariants.AMBIENT_MONITORING
			? this.FeedTypes.AmbientMonitoring
			: this.FeedTypes.Monitoring;

	toggleIsFullScreen = () => {
		this.props.healthSystemActions.toggleFullscreen();
	};

	onDevicePermissionChange = res => {
		if (res.target.state === MediaPermissions.GRANTED || res.target.state === MediaPermissions.PROMPT) {
			this.props.healthSystemActions.setStreamPermissionMessage(null);
		} else {
			const { videoFeeds } = this.state;
			videoFeeds.forEach(feed => {
				if (feed.isMyMicActive) {
					this.toggleMonitoringMic(feed);
				}
			});
		}
	};

	onFeedReassign = async ({ objectId }) => {
		const feed = this.state.videoFeeds.find(x => x.deviceId === objectId);
		if (!feed) {
			return;
		}

		const devicesResponse = await getDeviceList(DeviceListLevel.ROOM, feed.roomId);
		if (devicesResponse.error) {
			this.setState({ error: devicesResponse.error.message });
			return;
		}

		let deviceOwner = { patientFormattedName: '', age: null };
		const isDefaultOwner = devicesResponse?.[0].isDefaultOwner;

		if (isDefaultOwner) {
			this.setState(prevState => ({
				videoFeeds: prevState.videoFeeds.map(videoFeed => {
					let feed = videoFeed;
					if (feed.deviceId === objectId) {
						feed = { ...feed };
						feed.lastCondition = '';
						feed.isDefaultOwner = isDefaultOwner;
						feed.deviceOwner = deviceOwner;
					}

					return feed;
				}),
			}));
			return;
		}

		const deviceOwnerResponse = await getDeviceOwnerPatient(objectId);
		if (deviceOwnerResponse.error) {
			this.setState({ error: deviceOwnerResponse.error.message });
			return;
		}
		deviceOwner = { ...deviceOwnerResponse };
		let lastConditionResponse = null;

		if (!isDefaultOwner && !deviceOwner.isVirtualPatient) {
			lastConditionResponse = await getFallRiskLevel(deviceOwner.healthcareUserId);
			if (lastConditionResponse.error) {
				this.setState({ error: lastConditionResponse.error.message });
			}
			const patientAge = getAge(deviceOwnerResponse?.dateOfBirth);
			deviceOwner = {
				...deviceOwner,
				age: patientAge,
				patientFormattedName: this.getPatientFormattedName(deviceOwnerResponse),
				mrn: deviceOwnerResponse.mrn,
			};
			if (deviceOwner.healthcareUserId) {
				const tvWidgetsRes = await getTvWidgets(deviceOwner.healthcareUserId);
				if (tvWidgetsRes.error) {
					this.setState({ error: this.props.intl.formatMessage({ id: 'somethingWentWrong' }) });
					return;
				}
				const careTeamData = tvWidgetsRes.tvWidgets?.find(item => item.widgetType === WhiteboardWidgets.CARE_TEAM)?.value;
				const careTeam = isJSON(careTeamData) ? JSON.parse(careTeamData) : '';
				deviceOwner.careTeam = careTeam;
			}
		}

		this.setState(prevState => ({
			videoFeeds: prevState.videoFeeds.map(videoFeed => {
				let feed = videoFeed;
				if (feed.deviceId === objectId) {
					feed = { ...feed };
					const found = lastConditionResponse?.observations?.find(
						item => item.code === this.fallRiskCode || item.code === this.humptyDumptyFallsAssessmentScore
					);
					if (found) {
						if (found.code === this.fallRiskCode) {
							feed.lastCondition = this.getFallRiskLabel(found);
						}
						if (found.code === this.humptyDumptyFallsAssessmentScore) {
							feed.lastCondition = this.getHumptyDumptyFallAssessmentLabel(found);
						}
					}
					feed.isDefaultOwner = isDefaultOwner;
					feed.deviceOwner = deviceOwner;
				}
				return feed;
			}),
		}));
	};

	setDeviceOwner = async objectId => {
		if (!objectId) {
			return;
		}
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];
		const feed = newVideoFeeds.find(x => x.deviceId === objectId);
		if (!feed) {
			return;
		}
		let deviceOwner = { patientFormattedName: '', age: null, source: '' };
		const deviceOwnerResponse = await getDeviceOwnerPatient(objectId);
		if (deviceOwnerResponse.error) {
			this.setState({ error: deviceOwnerResponse.error.message });
			return;
		}
		deviceOwner = { ...deviceOwnerResponse, patientFormattedName: this.getPatientFormattedName(deviceOwnerResponse) };
		if (!deviceOwner.isVirtualPatient) {
			const patientAge = getAge(deviceOwnerResponse?.dateOfBirth);
			deviceOwner = {
				...deviceOwner,
				age: patientAge,
				patientFormattedName: this.getPatientFormattedName(deviceOwnerResponse),
				mrn: deviceOwnerResponse.mrn,
			};
			if (deviceOwner.healthcareUserId) {
				const tvWidgetsRes = await getTvWidgets(deviceOwner.healthcareUserId);
				if (tvWidgetsRes.error) {
					this.setState({ error: this.props.intl.formatMessage({ id: 'somethingWentWrong' }) });
					return;
				}
				const careTeamData = tvWidgetsRes.tvWidgets?.find(item => item.widgetType === WhiteboardWidgets.CARE_TEAM)?.value;
				const careTeam = isJSON(careTeamData) ? JSON.parse(careTeamData) : '';
				deviceOwner.careTeam = careTeam;
			}
		}
		feed.deviceOwner = deviceOwner;
		this.setState({
			videoFeeds: newVideoFeeds,
			...(this.state.selectedFeed?.deviceId === objectId && {
				selectedFeed: feed,
			}),
		});
	};

	handleDisconnectedByCall = data => {
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds].map(item => {
			const newItem = { ...item };
			if (newItem?.participantId === data?.participantId && newItem?.isStatAlarmActive) {
				newItem.isStatAlarmActive = false;
			}
			return newItem;
		});
		this.setState({
			videoFeeds: newVideoFeeds,
		});
	};

	handlePeerConnectionFailed = error => {
		if (!this.state.showMaxPeerConnectionsModal && error?.message.includes('Cannot create so many PeerConnections')) {
			this.setState({ showMaxPeerConnectionsModal: true });
		}
	};

	handleRTSPcamera = ({ helloDeviceId, isGridView }) => {
		const { videoFeeds } = this.state;
		const selectedFeedIndex = videoFeeds.findIndex(item => item.deviceId === helloDeviceId);
		if (selectedFeedIndex === -1) {
			return;
		}
		const [expandedFeed] = videoFeeds.splice(selectedFeedIndex, 1);
		expandedFeed.isGridView = isGridView;
		videoFeeds.splice(selectedFeedIndex, 0, expandedFeed);
		this.setState({ videoFeeds });
	};

	getHierarchyNaming = feed => {
		if (!feed) {
			return {};
		}
		return {
			hospital: feed.hospitalName,
			department: feed.departmentName,
			floor: feed.floorName,
			room: feed.roomName,
		};
	};

	careEventFired = event => {
		this.setState({ newEventFired: event });
	};

	getTeams = async () => {
		if (
			![UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN, UserRoles.VIRTUAL_SITTER].includes(this.userRole) ||
			!this.props.user.userSession.healthSystem.id
		) {
			return;
		}
		const response = await getTeams(this.props.user.userSession.healthSystem.id);
		if (!response.error) {
			let channels = [];
			response.teams.forEach(team => {
				channels = channels.concat(team.channels.map(channel => ({ ...channel, team })));
			});
			this.setState({
				channels,
				isTeamsLoading: false,
			});
		} else {
			this.setState({
				isTeamsLoading: false,
			});
		}
	};

	onNewIvBagDataAdded = (data, found) => {
		this.updateAnalyticsInfo({
			...data,
			...found,
			text: `${this.props.intl.formatMessage({ id: 'ivBagFluidLevel' })}: ${data.value}%`,
		});
	};

	updateAnalyticsInfo = data => {
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];
		const selectedFeed = newVideoFeeds.find(item => item.deviceId === data.deviceId);
		if (!selectedFeed) {
			return;
		}

		selectedFeed.analyticsData = data;
		this.setState({ videoFeeds: newVideoFeeds });
		this.analyticsDataTimeout = setTimeout(() => {
			this.onAnalyticsToastMessageClose(data.deviceId);
		}, 5000);
	};

	onNewAnalyticsAdded = data => {
		const found = AiAnalyticType().find(el => el.id === data.analyticsType);
		if (found?.category === AnalyticsCategory.IV_BAG_FLUID_LEVEL) {
			this.onNewIvBagDataAdded(data, found);
		}
	};

	onAiAlert = data => {
		const { alertTypeId, workflowTypes = [] } = data;

		if (!workflowTypes.includes(CallWorkflowType.MONITORING)) {
			return;
		}
		if (alertTypeId === AiAlertId.HANDS_DISINFECTED) {
			this.updateVideoFeedAlertInfo(data);
		} else {
			this.playVoiceOver({ ...data, isAiAlert: true });
		}
	};

	updateVideoFeedAlertInfo = data => {
		const alertData = { imgUrl: null, text: null };
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];
		const selectedFeed = newVideoFeeds.find(item => item.deviceId === data.deviceId);
		if (!selectedFeed) {
			return;
		}
		alertData.text = translate('handsDisinfectedSuccessfully');
		alertData.imgUrl = `${healthCareCdnUrl}alert-history/hand-washing-green.svg`;
		selectedFeed.alertInfo = { ...data, ...alertData };
		this.setState({ videoFeeds: newVideoFeeds });
		this.alertInfoTimeout = setTimeout(() => {
			this.onAlertInfoClose(data.deviceId);
		}, 5000);
	};

	updateVideoFeedAiToastMessage = ({ deviceId, alertId }) => {
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];
		const selectedFeed = newVideoFeeds.find(item => item.deviceId === deviceId);
		if (!selectedFeed) {
			return;
		}
		const toastToSave = {
			message: translate('patientIsInactiveToastMessage', { role: this.props.customDisplayNames.nurseDisplayName }),
			text: translate('patientInactive'),
			buttonText: translate('forwardSomethings', { value: this.props.customDisplayNames.nurseDisplayName }),
			imgUrl: `${healthCareCdnUrl}alert-history/inactive-same-position.svg`,
			alertId,
			isToastDetails: true,
			isAiAlert: true,
			deviceId,
		};
		selectedFeed.activeAlerts = skipDuplicatedObjects([...selectedFeed?.activeAlerts, toastToSave]);

		if (Object.keys(selectedFeed.warning).length !== 0) {
			selectedFeed.aiToastDetails = {};
		} else {
			selectedFeed.aiToastDetails = toastToSave;
		}
		this.setState(prevState => ({
			videoFeeds: newVideoFeeds,
			selectedFeed: prevState.selectedFeed?.deviceId === selectedFeed.deviceId ? selectedFeed : prevState.selectedFeed,
		}));
	};

	setMonitoringSessions = async () => {
		const monitoringSessionsRes = await getMonitoringSessions();
		if (monitoringSessionsRes.error) {
			this.setState({
				errorFetchingSessions: `${this.props.intl.formatMessage({ id: 'failedToLoad' })}`,
				isFetchingSessionsLoading: false,
			});
		} else {
			this.setState({
				monitoringSessions: [...monitoringSessionsRes, this.currentSession],
				isFetchingSessionsLoading: false,
			});
		}
	};

	onMonitoringHandoverSessionAdded = data => {
		const existingMonitoringSessions = _.cloneDeep(this.state.monitoringSessions).reduce((acc, item) => {
			if (!item.userCreated || item.userCreated?.id === item.nurseId) {
				if (item.isCurrent) {
					acc.push({ ...item, isActive: true });
				} else {
					acc.push(item);
				}
			}
			return acc;
		}, []);

		this.setState({
			monitoringSessions: [...existingMonitoringSessions, data.monitoringSession],
		});
	};

	handlePeerConnectionState = ({ peerConnectionState, participantObjectId }) => {
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];
		const selectedFeed = newVideoFeeds.find(item => item.deviceId === participantObjectId);
		if (!selectedFeed) {
			return;
		}
		selectedFeed.peerConnectionState = peerConnectionState;
		switch (peerConnectionState) {
			case RTCPeerConnectionEnum.CONNECTION_STATE.CONNECTED:
				selectedFeed.status = ParticipantState.CONNECTED.type;
				if (this.state.previousFeedAudioEnabled[selectedFeed.deviceId]) {
					this.callManager.toggleParticipantTrack(CallTypes.AUDIO, false, selectedFeed.participantId);
				}
				break;
			case RTCPeerConnectionEnum.CONNECTION_STATE.DISCONNECTED:
				selectedFeed.status = ParticipantState.RECONNECTING.type;
				if (selectedFeed.isStatAlarmActive) {
					selectedFeed.isStatAlarmActive = false;
					selectedFeed.voiceOverAlert = null;
					selectedFeed.isVoiceOverOtherInitiator = null;
				}
				break;
			case RTCPeerConnectionEnum.CONNECTION_STATE.FAILED:
				selectedFeed.status = ParticipantState.RECONNECTING.type;
				break;
			default:
				break;
		}
		this.setState({ videoFeeds: newVideoFeeds });
	};

	postEndMessage = () => {
		if (window.opener && this.state.conferenceInfo) {
			window.opener.postMessage(
				JSON.stringify({
					event: 'call.ended',
					callType: 'esitter',
					roomSid: this.state.conferenceInfo.conferenceId,
					participantId: this.state.conferenceInfo.participantId,
					participantSid: this.state.conferenceInfo.participantId,
				}),
				'*'
			);
		}
	};

	handleEndCall = data => {
		if ([ConferenceEndReason.CLEANUP, ConferenceEndReason.UNDEFINED, ConferenceEndReason.OWNER_LEFT].includes(data.endReason)) {
			this.closeFeedsAndUpdateTree(this.state.videoFeeds);
		}
		if (data.endReason === ConferenceEndReason.PARTICIPANT_BUSY && this.state.videoFeeds.length === 1) {
			this.removeFeed(this.state.videoFeeds[0].deviceId);
		}

		this.participants = {};
		this.setState(
			prevState => {
				let videoFeeds = prevState.videoFeeds;

				if (prevState.videoFeeds.length === 1 || data.endReason === ConferenceEndReason.CLEANUP) {
					videoFeeds = [];
				}
				return {
					...prevState,
					conferenceInfo: null,
					isFeedZoomed: false,
					hasActiveConference: data.hasActiveConference,
					conferenceEndReason: data.endReason,
					videoFeeds,
				};
			},
			() => {
				this.props.healthSystemActions.updateMonitoringDevices({
					inCallDevices: [],
					initiatedDevices: [],
				});
				if (data.endReason === ConferenceEndReason.PARTICIPANT_IDLE) {
					window.location.href = '/logout';
				}
			}
		);
	};

	handleSocketState = ({ socketState }) => {
		if (this.state.socketState.type === socketState.type) {
			return;
		}
		const newState = { socketState };
		switch (socketState.type) {
			case SocketState.DISCONNECTED.type:
				Object.assign(newState, {
					disableButtons: true,
				});
				break;
			case SocketState.CONNECTED.type:
				Object.assign(newState, {
					disableButtons: false,
				});
				break;
			default:
				break;
		}
		this.setState(newState);
	};

	handleDeviceStateChanged = data => {
		const {
			helloDeviceId,
			isNightVision,
			zoomLevel,
			cameraType,
			RTSPcameras,
			isCameraPrivacyOn,
			isMicPrivacyOn,
			mediaDevices,
			voiceOverInfo,
			isStatAlarmActive,
		} = data;
		// TODO check for RTSPcameras
		const { videoFeeds } = this.state;
		const selectedFeedIndex = videoFeeds.findIndex(item => item.deviceId === helloDeviceId);
		if (selectedFeedIndex === -1) {
			return;
		}
		const [expandedFeed] = videoFeeds.splice(selectedFeedIndex, 1);
		expandedFeed.zoomLevel = zoomLevel;
		expandedFeed.cameraType = cameraType;
		expandedFeed.isNightVisionActive = isNightVision;
		expandedFeed.RTSPcameras = RTSPcameras;
		expandedFeed.isGridView = !!RTSPcameras;
		expandedFeed.isCameraPrivacyOn = isCameraPrivacyOn;
		expandedFeed.isMicPrivacyOn = isMicPrivacyOn;
		expandedFeed.mediaDevices = mediaDevices;
		expandedFeed.voiceOverAlert = voiceOverInfo?.isEnabled
			? this.voiceOverLanguages.find(el => el.value === voiceOverInfo.language)
			: null;
		if (voiceOverInfo?.isEnabled && voiceOverInfo?.initiator?.id !== this.state.conferenceInfo.participantId) {
			expandedFeed.isVoiceOverOtherInitiator = voiceOverInfo?.initiator;
		} else {
			expandedFeed.isVoiceOverOtherInitiator = null;
		}
		expandedFeed.isStatAlarmActive = isStatAlarmActive;
		videoFeeds.splice(selectedFeedIndex, 0, expandedFeed);
		this.setState({ videoFeeds });
	};

	updateMediaDevices = (feed, data) => {
		const { videoFeeds } = this.state;
		const expandedFeed = videoFeeds.find(item => item.deviceId === feed.deviceId);
		expandedFeed.mediaDevices = data;
		this.setState({ videoFeeds });
	};

	handleParticipantStatus = statusResponse => {
		const { status, objectId, reason } = statusResponse;
		const { videoFeeds } = this.state;
		const expandedFeed = videoFeeds.find(item => item.deviceId === objectId);

		if (
			!expandedFeed ||
			(expandedFeed.status === ParticipantState.LEFT_CALL.type &&
				status !== ParticipantState.REMOVED.type &&
				expandedFeed.type === this.FeedTypes.Monitoring) ||
			expandedFeed.status === ParticipantState.DISCONNECTED.type ||
			(status === ParticipantState.LEFT_CALL.type &&
				[ParticipantRemoveReason.DISCONNECTED, ParticipantRemoveReason.DISCONNECTED_PARTICIPANT_CLEANUP].includes(reason))
		) {
			return;
		}

		expandedFeed.status = status;
		expandedFeed.reason = reason;
		this.setState({ videoFeeds });
	};

	onDeviceOnline = data => {
		const found = this.state.videoFeeds.find(item => item?.deviceId === data?.objectId);
		if (found && this.state.conferenceInfo) {
			this.callManager.addDeviceToMonitoring({
				conferenceId: this.state.conferenceInfo.conferenceId,
				participantId: this.state.conferenceInfo.participantId,
				participant: {
					objectType: ObjectType.HELLO_DEVICE,
					objectId: found.deviceId,
					isAmbient: found.feedType === this.FeedTypes.AmbientMonitoring,
				},
			});
		}
	};

	enqueueStatAlarmList = data => {
		if (!this.state.videoFeeds.some(feed => feed.deviceId === data.deviceId && feed.isStatAlarmActive !== data.data)) {
			const found = this.state.videoFeeds.find(item => item.deviceId === data.deviceId);

			if (found && this.shouldAllowForwardStatAlarmToNurses(found, data)) {
				const statusId = data.data ? ManualAlertTypes.START_STAT_ALARM : ManualAlertTypes.STOP_STAT_ALARM;
				this.forwardStatAlarmStatusToNurses(found, statusId, '');
			}
			return;
		}
		this.setState(
			prevState => ({
				processingStatAlarmParticipantsQueue: [...prevState.processingStatAlarmParticipantsQueue, data],
			}),
			() => {
				if (!this.currentlyProcessingStatAlarmPatients) {
					this.processStatAlarmQueue();
				}
			}
		);
	};

	processStatAlarmQueue = () => {
		if (this.state.processingStatAlarmParticipantsQueue.length === 0) {
			return;
		}
		this.currentlyProcessingStatAlarmPatients = true;
		this.processNextStatAlarm();
	};

	processNextStatAlarm = () => {
		const { processingStatAlarmParticipantsQueue } = this.state;
		if (processingStatAlarmParticipantsQueue.length === 0) {
			this.currentlyProcessingStatAlarmPatients = false;
			return;
		}

		const [dataToProcess, ...remainingQueue] = processingStatAlarmParticipantsQueue;

		this.setState({ processingStatAlarmParticipantsQueue: remainingQueue }, () => {
			this.onAlertPatientResponse(dataToProcess);
		});
	};

	finishStatAlarmProcessing = () => {
		this.currentlyProcessingStatAlarmPatients = false;
		this.processStatAlarmQueue();
	};

	enqueueBusyHandling = data => {
		this.setState(
			prevState => ({
				processingBusyParticipantsQueue: [...prevState.processingBusyParticipantsQueue, data],
			}),
			() => {
				if (!this.currentlyProcessingBusyParticipants) {
					this.processBusyQueue();
				}
			}
		);
	};

	processBusyQueue = () => {
		if (this.currentlyProcessingBusyParticipants || this.state.processingBusyParticipantsQueue.length === 0) {
			return;
		}
		this.currentlyProcessingBusyParticipants = true;
		const [dataToProcess, ...remainingQueue] = this.state.processingBusyParticipantsQueue;
		this.setState({ processingBusyParticipantsQueue: remainingQueue }, () => {
			this.handleParticipantBusy(dataToProcess);
		});
	};

	finishBusyProcessing = () => {
		this.currentlyProcessingBusyParticipants = false;
		if (this.state.processingBusyParticipantsQueue.length > 0) {
			this.processBusyQueue();
		}
	};

	handleParticipantBusy = data => {
		const { videoFeeds } = this.state;
		if (!videoFeeds.some(item => item.deviceId === data.objectId)) {
			this.finishBusyProcessing();
			return;
		}
		const { activeConferences } = data;
		const nursesInConference = activeConferences.reduce((result, item) => {
			const user = item.participants.find(participant => participant.objectType === ObjectType.USER);
			if (user) {
				result.push(user);
			}
			return result;
		}, []);

		const newVideoFeeds = videoFeeds.map(item => {
			if (item.deviceId === data.objectId) {
				const newItem = { ...item };
				newItem.status = ParticipantState.BUSY.type;
				if (nursesInConference.length > 1) {
					newItem.nursesInConference = nursesInConference;
				}

				const conference = activeConferences?.find(ac => ac.initialCallType === CallTypes.MONITORING);
				const monitoringNurse = conference?.participants.find(p => p.objectType === ObjectType.USER);
				if (conference && !monitoringNurse) {
					// eslint-disable-next-line no-console
					console.error('On participant busy - monitoring nurse is missing from participants');
					newItem.onPatientBusyNurse = monitoringNurse;
				}

				return newItem;
			}

			return item;
		});
		this.setState({ videoFeeds: newVideoFeeds }, () => {
			this.finishBusyProcessing();
		});
	};

	handleCallStarted = data => {
		this.setState(
			{
				conferenceInfo: data.conferenceInfo,
				hasActiveConference: false,
				isSessionLoading: false,
				isStartConferenceInProgress: false,
			},
			() => {
				this.feedsToInviteQueue.forEach(deviceId => {
					this.addFeed({ deviceId });
				});

				this.feedsToInviteQueue = [];
			}
		);
	};

	handleUpdateParticipant = data => {
		if (!this.state.conferenceInfo || this.state.videoFeeds?.length === 0) {
			return;
		}

		data.forEach(updatedParticipant => {
			this.participants[updatedParticipant.id] = {
				id: updatedParticipant.id,
				objectId: updatedParticipant.objectId,
			};
		});

		const getVideoFeeds = prevVideoFeeds =>
			prevVideoFeeds.map(videoFeed => {
				const updatedFeed = data.find(feed => feed.objectId === videoFeed.deviceId);
				let feed = videoFeed;

				if (updatedFeed) {
					// TODO: this should be uncommented when all other cases of setState for videoFeeds are fixed
					// feed = { ...feed };
					feed.participantId = updatedFeed.id;
					feed.feedType = updatedFeed.isAmbient ? this.FeedTypes.AmbientMonitoring : this.FeedTypes.Monitoring;
				}

				return feed;
			});

		this.setState(prevState => ({ videoFeeds: getVideoFeeds(prevState.videoFeeds) }));
	};

	handlePeerStream = data => {
		const { mediaStream, origin } = data.peerSrc;
		const { videoFeeds } = this.state;

		const selectedFeedIndex = this.getVideoFeedIndexByParticipantId(origin, videoFeeds);
		if (selectedFeedIndex === -1) {
			return;
		}

		const [feed] = videoFeeds.splice(selectedFeedIndex, 1);
		feed.src = mediaStream;
		feed.status = ParticipantState.CONNECTED.type;

		videoFeeds.splice(selectedFeedIndex, 0, feed);
		this.addIdToInCallDevicesDevice(feed.deviceId);
		this.handleBitrateChange();
		this.setState({ videoFeeds });
	};

	handleDevicePrivacySettingUpdated = data => {
		if (data.settingTypeId === HelloSettings.PRIVACY_MODE) {
			const { videoFeeds } = this.state;
			const feed = videoFeeds.find(item => item.deviceId === data.deviceId);
			if (feed) {
				feed.aiPrivacyStatus = data.settingValue === 'true';
			}
			this.setState({ videoFeeds });
		}
	};

	handlePrivacyModeUpdate = data => {
		const { videoFeeds } = this.state;
		const feed = videoFeeds.find(item => item.deviceId === data.deviceId);
		if (feed) {
			feed.aiPrivacyStatus = data.privacyMode;
			if (!data.privacyMode) {
				feed.privacyTimerMilliseconds = 0;
			}
			this.setState(prevState => ({
				videoFeeds,
				...(feed.deviceId === prevState.selectedFeed?.deviceId && {
					selectedFeed: {
						...prevState.selectedFeed,
						aiPrivacyStatus: data.privacyMode,
						privacyTimerMilliseconds: !data.privacyMode ? 0 : prevState.selectedFeed.privacyTimerMilliseconds,
					},
				}),
			}));
		}
	};

	toggleOffPrivacy = async deviceId => {
		const { videoFeeds } = this.state;
		const selectedFeed = videoFeeds.find(item => item.deviceId === deviceId);
		const monitoringSource = 1;

		const settingValue = JSON.stringify({
			source: monitoringSource,
			active: false,
			timer: null,
		});
		if (selectedFeed && selectedFeed.aiPrivacyStatus) {
			const response = await updateDeviceSettings({
				deviceId,
				settingTypeId: HelloSettings.PRIVACY_MODE_TIMEOUT,
				settingValue,
			});
			if (!response.error) {
				selectedFeed.aiPrivacyStatus = false;
				selectedFeed.privacyTimerMilliseconds = 0;
				const prevSelectedFeed = { ...this.state.selectedFeed };
				prevSelectedFeed.aiPrivacyStatus = false;
				prevSelectedFeed.privacyTimerMilliseconds = 0;
				this.setState({ videoFeeds, selectedFeed: prevSelectedFeed });
			} else {
				this.setState({ error: this.props.intl.formatMessage({ id: 'anErrorOccurred' }) });
			}
		}
	};

	closePrivacyModeError = (event, feed) => {
		if (event) {
			event.stopPropagation();
		}
		const { videoFeeds } = this.state;
		const newVideoFeeds = videoFeeds.map(item => {
			if (item.deviceId === feed.deviceId) {
				const newItem = { ...item };
				newItem.privacyModeError = false;
				return newItem;
			}
			return item;
		});

		this.setState({
			videoFeeds: newVideoFeeds,
		});
	};

	togglePrivacyMode = async (deviceId, privacyMode, patientId, timer = null) => {
		const { videoFeeds } = this.state;
		const selectedFeed = videoFeeds.find(item => item.deviceId === deviceId);
		if (selectedFeed?.dataAcquisition === 'true') {
			const newVideoFeeds = videoFeeds.map(item => {
				if (item.deviceId === deviceId) {
					const newItem = { ...item };
					newItem.privacyModeError = true;
					return newItem;
				}
				return item;
			});

			this.setState({
				videoFeeds: newVideoFeeds,
			});

			return privacyMode;
		}
		const monitoringSource = 1;
		let start = null;
		let end = null;

		if (timer) {
			start = Math.floor(Date.now() / 1000);
			end = start + Math.ceil(minutesToMilliseconds(timer.value) / 1000);
		}

		const settingValue = JSON.stringify({
			source: monitoringSource,
			active: !!timer,
			timer: timer
				? {
						start: start.toString(),
						end: end.toString(),
					}
				: null,
		});

		const { error } = await updateDeviceSettings({
			deviceId,
			settingTypeId: HelloSettings.PRIVACY_MODE_TIMEOUT,
			settingValue,
		});
		if (!error) {
			if (selectedFeed) {
				selectedFeed.aiPrivacyStatus = !privacyMode;
				selectedFeed.privacyTimerMilliseconds = timer ? Date.now() + minutesToMilliseconds(timer.value) : 0;
				const prevSelectedFeed = { ...this.state.selectedFeed };
				prevSelectedFeed.aiPrivacyStatus = !privacyMode;
				prevSelectedFeed.privacyTimerMilliseconds = timer ? Date.now() + minutesToMilliseconds(timer.value) : 0;
				this.setState({ videoFeeds, selectedFeed: prevSelectedFeed });

				let existingPatientId = patientId;

				if (!patientId) {
					const deviceOwnerResponse = await getDeviceOwnerPatient(deviceId);
					if (deviceOwnerResponse.error) {
						this.setState({ error: deviceOwnerResponse.error.message });
						return privacyMode;
					}
					existingPatientId = deviceOwnerResponse?.healthcareUserId;
				}

				const dataToSend = {
					patientId: existingPatientId,
					deviceId: deviceId,
					manualAlertTypeId: selectedFeed.aiPrivacyStatus ? ManualAlertTypes.PRIVACY_MODE_ON : ManualAlertTypes.PRIVACY_MODE_OFF,
					isDraft: selectedFeed.aiPrivacyStatus,
					conferenceId: this.state.conferenceInfo?.conferenceId,
				};
				const manualAlertSaveResponse = await saveManualAlert(dataToSend);
				if (manualAlertSaveResponse.error) {
					this.setState({ error: this.props.intl.formatMessage({ id: 'anErrorOccurred' }) });
					return privacyMode;
				}
			}
		} else {
			this.setState({ error: this.props.intl.formatMessage({ id: 'anErrorOccurred' }) });
			return privacyMode;
		}
		return !privacyMode;
	};

	toggleOrShowPrivacyTimer = (deviceId, aiPrivacyStatus, patientId) => {
		if (aiPrivacyStatus) {
			this.togglePrivacyMode(deviceId, aiPrivacyStatus, patientId);
			return;
		}
		this.setState({
			feedToSetPrivacyTimer: {
				deviceId,
				aiPrivacyStatus,
				patientId,
			},
		});
	};

	onPrivacyTimerSubmitModal = () => {
		this.togglePrivacyMode(
			this.state.feedToSetPrivacyTimer.deviceId,
			this.state.feedToSetPrivacyTimer.aiPrivacyStatus,
			this.state.feedToSetPrivacyTimer.patientId,
			this.state.privacyTimer
		);
		this.setState({
			feedToSetPrivacyTimer: null,
			privacyTimer: null,
		});
	};

	setAlertSnoozePerFeed = async (deviceId, alertType) => {
		const newVideoFeeds = _.cloneDeep(this.state.videoFeeds);
		const selectedFeed = newVideoFeeds.find(item => item.deviceId === deviceId);
		let arr = [];
		if (selectedFeed && selectedFeed.bioBeatTaskId) {
			const { bioBeatTaskId } = selectedFeed;
			const alertMinutes = alertType === MeasurementActivityTypes.ACKNOWLEDGED ? 15 : 5;
			const response = await snoozeAlertBasedOnTask(bioBeatTaskId, alertMinutes);
			if (response.error) {
				this.setState({ error: response?.error?.message });
				return;
			}

			if (getStorage().snoozedAlerts) {
				arr = JSON.parse(getStorage().snoozedAlerts);
			}
			const found = arr.find(item => item.deviceId === deviceId);
			if (found) {
				found.time = new Date();
				found.alertType = alertType;
				found.taskId = bioBeatTaskId;
			} else {
				arr.push({
					deviceId,
					time: new Date(),
					alertType,
					taskId: bioBeatTaskId,
				});
			}
			getStorage().setItem('snoozedAlerts', JSON.stringify(arr));
		}
	};

	onMeasurementAlert = async ({ deviceId, measurementAlertType, alertId, patientUserId = null }) => {
		const params = {
			alertTypeId: measurementAlertType.id,
			deviceId,
			alertId,
			warningIndex: measurementAlertType.warningIndex,
			isAiAlert: false,
			patientUserId,
		};

		this.playVoiceOver(params);
	};

	isAnyFeedMicActive = () => this.state.videoFeeds.some(feed => feed.isMyMicActive);

	shouldShowAlert = (deviceId, selectedFeed) => {
		let shouldShowAlert = true;
		const parsedAlerts = getStorage().snoozedAlerts ? JSON.parse(getStorage().snoozedAlerts) : [];
		const foundFeed = parsedAlerts.find(item => item.deviceId === selectedFeed.deviceId);
		const shouldCheckForSnooze = !deviceId && foundFeed && selectedFeed.bioBeatTaskId === foundFeed.taskId;

		if (shouldCheckForSnooze) {
			const now = new Date();
			const snoozedTime = foundFeed?.time;
			const diffMs = now.getTime() - new Date(snoozedTime).getTime();
			const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
			const alertsMinutes = foundFeed?.alertType === MeasurementActivityTypes.ACKNOWLEDGED ? 15 : 5;
			if (diffMins < alertsMinutes) {
				shouldShowAlert = false;
			}
		}
		return shouldShowAlert;
	};

	playVoiceOver = async ({ alertTypeId, deviceId, alertId, warningIndex, isAiAlert, patientUserId }) => {
		const newVideoFeeds = _.cloneDeep(this.state.videoFeeds);
		let selectedFeedIndex = -1;
		if (deviceId) {
			selectedFeedIndex = newVideoFeeds.findIndex(item => item.deviceId === deviceId);
		}

		if (!deviceId && patientUserId) {
			selectedFeedIndex = newVideoFeeds.findIndex(item => item.deviceOwner.userId === patientUserId && item.isBioBeatActive);
		}
		if (
			selectedFeedIndex === -1 ||
			(newVideoFeeds[selectedFeedIndex].feedType === this.FeedTypes.Monitoring &&
				newVideoFeeds[selectedFeedIndex].status !== ParticipantState.CONNECTED.type)
		) {
			return;
		}
		const [selectedFeed] = newVideoFeeds.splice(selectedFeedIndex, 1);

		if (selectedFeed?.aiToastDetails && Object.keys(selectedFeed?.aiToastDetails).length > 0) {
			selectedFeed.aiToastDetails = {};
		}

		if (this.shouldShowAlert(deviceId, selectedFeed)) {
			if (this.playingAlert) {
				this.playingAlert.currentTime = 0;
				this.playingAlert.pause();
			}

			const hasNotificationType = notificationType =>
				getNotificationOptions({ alertTypeId, deviceId, aiSettingList: this.props.aiSettings }).includes(notificationType);

			const hasSilentMode = hasNotificationType(AiAlertNotificationType.SILENT_MODE);
			const hasToastMessageType = hasNotificationType(AiAlertNotificationType.TOAST_MESSAGE);
			const hasMobileAppType = hasNotificationType(AiAlertNotificationType.MOBILE_APP);

			if (hasSilentMode) {
				return;
			}
			if (hasMobileAppType) {
				await this.forwardAiAlert(selectedFeed, alertId);
				return;
			}
			if ([AiAlertId.PATIENT_INACTIVE, AiAlertId.PATIENT_INACTIVE_INFORMATION].includes(alertTypeId) && hasToastMessageType) {
				this.updateVideoFeedAiToastMessage({ deviceId, alertId });
				return;
			}

			const { text, alertCategory } = this.getHealthConcern({ alertTypeId, isAiAlert });

			const alertToSave = {
				value: true,
				alertTypeId,
				text,
				alertId,
				isAiAlert,
				alertCategory,
				index: warningIndex,
				isToastMessage: hasToastMessageType,
			};

			let newAlerts = [alertToSave, ...selectedFeed?.activeAlerts];
			this.getHealthConcern({ alertTypeId: alertToSave?.alertTypeId, isAiAlert, hasVoiceOver: true });

			if (!this.isAnyFeedMicActive() && !alertToSave?.isToastMessage) {
				this.tryToPlayAlertSound(deviceId, alertToSave?.isAiAlert);
			}

			selectedFeed.warning = { ...alertToSave };
			selectedFeed.activeAlerts = skipDuplicatedObjects(newAlerts);
			newVideoFeeds.splice(selectedFeedIndex, 0, selectedFeed);
			this.setState(prevState => ({
				videoFeeds: newVideoFeeds,
				selectedFeed: prevState.selectedFeed?.deviceId === selectedFeed.deviceId ? selectedFeed : prevState.selectedFeed,
			}));
		}
	};

	onPatientDischarged = data => {
		const found = this.state.videoFeeds.find(item => item.deviceId === data?.deviceId);
		if (!found) {
			return;
		}
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds].map(item => {
			if (item.deviceId === data?.deviceId) {
				const newItem = { ...item };
				newItem.isPatientDischarged = data?.type;
				return newItem;
			}
			return item;
		});
		this.setState(
			{
				videoFeeds: newVideoFeeds,
				...(this.state.selectedFeed?.deviceId === data?.deviceId && {
					selectedFeed: null,
				}),
			},
			() => {
				if (found.isMyMicActive) {
					this.toggleMyMicrophone(found);
				}

				if (found.isPeerSpeakerActive) {
					this.togglePeerSpeaker(found);
				}
			}
		);
	};

	handleNewConditionCreated = data => {
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds].map(item => {
			if (item.deviceOwner?.userId === data?.userId) {
				const newItem = { ...item };
				newItem.lastCondition = { code: data?.code, display: data?.display };

				return newItem;
			}
			return item;
		});

		this.setState({ videoFeeds: newVideoFeeds });
	};

	getHealthConcern = ({ alertTypeId, isAiAlert = false, hasVoiceOver = false }) => {
		const { text, voiceOver, alertCategory } = getAlertDetailsById({ alertTypeId, isAiAlert });
		if (hasVoiceOver && voiceOver) {
			this.playingAlert = voiceOver;
		}
		return { text, alertCategory };
	};

	stopVoiceOver = ({ alertTypeId, deviceId, hasMultipleAlerts = false }) => {
		const { videoFeeds } = this.state;
		const newFeeds = [...videoFeeds];
		const selectedFeedIndex = newFeeds.findIndex(item => item.deviceId === deviceId);
		const [selectedFeed] = newFeeds.splice(selectedFeedIndex, 1);
		let newAlerts = [];
		if (hasMultipleAlerts) {
			newAlerts = selectedFeed.activeAlerts?.filter(alert => alert.alertTypeId !== alertTypeId);
			selectedFeed.activeAlerts = newAlerts;
		}
		const alertsWithoutToast = newAlerts?.filter(alert => !alert.isToastDetails);

		if (alertsWithoutToast.length > 0 && alertTypeId) {
			if (this.playingAlert) {
				this.playingAlert.currentTime = 0;
				this.playingAlert.pause();
			}
			const alertToShow = alertsWithoutToast[0];
			this.getHealthConcern({ alertTypeId: alertToShow?.alertTypeId, isAiAlert: true, hasVoiceOver: true });

			if (!this.isAnyFeedMicActive() && !alertToShow?.isToastMessage) {
				this.tryToPlayAlertSound(deviceId, alertToShow?.isAiAlert);
			}

			selectedFeed.warning = { ...alertToShow };
		} else {
			if (alertTypeId === AiAlertId.FALL_DETECTION) {
				this.onFallDetected(false, selectedFeed);
			}
			if (this.playingAlert) {
				this.playingAlert.currentTime = 0;
				this.playingAlert.pause();
			}
			selectedFeed.warning = {};
		}
		newFeeds.splice(selectedFeedIndex, 0, selectedFeed);
		this.setState(prevState => ({
			videoFeeds: newFeeds,
			selectedFeed: prevState.selectedFeed?.deviceId === selectedFeed.deviceId ? selectedFeed : prevState.selectedFeed,
		}));
	};

	stopBioBeatMeasurements = async videoFeeds => {
		const newFeeds = [...videoFeeds].map(item => {
			if (!item.isBioBeatActive || !item.bioBeatTaskId) {
				return null;
			}

			return this.setBioBeatDeviceStatusToOff({
				taskId: item.bioBeatTaskId,
				patientUserId: item.deviceOwner.userId,
				patientUserGuid: item.deviceOwner.healthcareUserId,
				deviceId: item.deviceId,
			});
		});
		await Promise.all(newFeeds);
		if (getStorage().activeBioBeatItems) {
			getStorage().removeItem('activeBioBeatItems');
		}
	};

	beforeUnloadEvent = event => {
		if (this.state.conferenceInfo) {
			event.preventDefault();
			event.returnValue = '';
		}
	};

	unloadEvent = () => {
		if (this.state.conferenceInfo) {
			this.callManager.endCall({ endReason: ConferenceEndReason.PARTICIPANT_LEFT });
			this.postEndMessage();
			this.stopBioBeatMeasurements(_.cloneDeep(this.state.videoFeeds));
		}
	};

	handleUserIdleMessage = message => {
		if (message.data === 'IN_CALL') {
			if (this.state.conferenceInfo) {
				this.callManager.endCall({ endReason: ConferenceEndReason.PARTICIPANT_IDLE });
			} else {
				window.location.href = '/logout';
			}
		}
	};

	bindWindowListeners = () => {
		window.addEventListener('beforeunload', this.beforeUnloadEvent);
		window.addEventListener('unload', this.unloadEvent);
		window.addEventListener('message', this.handleUserIdleMessage);
		document.addEventListener('fullscreenchange', this.toggleIsFullScreen);
	};

	unbindWindowListeners = () => {
		window.removeEventListener('beforeunload', this.beforeUnloadEvent);
		window.removeEventListener('unload', this.unloadEvent);
		window.removeEventListener('message', this.handleUserIdleMessage);
		document.removeEventListener('fullscreenchange', this.toggleIsFullScreen);
	};

	prepareStartConferenceInfo = async () => {
		const userProfile = JSON.parse(getStorage().getItem('userProfile'));
		const fromUser = new FromUser(
			`${userProfile.firstName} ${userProfile.lastName}`,
			userProfile.jobTitle,
			userProfile.profilePicture,
			undefined
		);
		const conferenceId = v4();
		const participantId = v4();
		const roleId = Roles.find(r => r.role === this.userRole).id;

		const devices = await navigator.mediaDevices.enumerateDevices();
		const inputDevices = {
			devices,
			permissions: {
				microphone: this.micStatus.state,
			},
		};
		const instigator = {
			objectId: userProfile.userId,
			objectType: ObjectType.USER,
			role: roleId,
		};

		return new ConferenceInfo(
			CallTypes.MONITORING, // callType
			conferenceId, // conferenceId
			'Monitoring', // conferenceName
			null, // conversationId
			fromUser, // from
			false, // isAudio
			false, // isChat
			false, // isDirectToHello
			false, // isMeetingRoom
			false, // isMultiparty
			false, // isScreen
			false, // isVideo
			participantId, // participantId
			[], // participants
			inputDevices,
			true, // isSecurityCam,
			instigator // instigator
		);
	};

	getPatientFormattedName = deviceOwner => {
		const { fullName } = deviceOwner;
		const ownerLastName = fullName.split(' ').slice(-1).join(' ');
		const ownerFirstName = fullName.split(' ').slice(0, -1).join(' ');
		return `${ownerLastName}${ownerFirstName.length > 0 ? ', ' : ''}${ownerFirstName}`;
	};

	removeItemsOtherThanPatientInfo = (obj1, obj2) => {
		return Object.keys(obj2).reduce((acc, key) => {
			if (key in obj1) {
				acc[key] = obj2[key];
			}
			return acc;
		}, {});
	};

	getDisplayFromObservationByTypeId = (arr, id) => arr.find(item => item.observationTypeId === id)?.display || '';

	getArrayByType = ({ arr, field, id, initialArr }) =>
		arr.reduce((acc, curr) => {
			if (curr[field] === id) {
				acc.push({
					...curr,
					label: initialArr.find(x => curr.code === x.code)?.label,
					value: initialArr.find(x => curr.code === x.code)?.value,
				});
			}
			return acc;
		}, []);

	getCareTeamParticipantName = (careTeamParticipants, participantRole) =>
		careTeamParticipants?.find(participant => participant.participantRole === participantRole)?.name || '';

	fetchNoteAndLanguage = async (deviceId, owner) => {
		const notes = {
			patientName: '',
			patientAge: null,
			birthDate: '',
			genderIdentity: null,
			nurseNameNumber: '',
			preferredLanguage: null,
			nurseAssistantNameNumber: '',
			tlNameNumber: '',
			primaryAdverseEvent: [],
			primaryPatientCondition: [],
			primaryPatientRiskFactor: [],
			titleBarNotes: '',
			additionalNotes: '',
		};
		const [patientInformation, patientBasicInformation, primaryConditionRes, patientInfoObservations, careTeamParticipants] =
			await Promise.all([
				getDeviceOwnerPatient(deviceId),
				getPatientByUserId(owner.userId),
				getPrimaryPatientCondition(owner.healthcareUserId),
				getPatientInfoObservations(owner.healthcareUserId),
				getPatientCareTeamParticipants(this.props.user.userSession.healthSystem.id, owner.healthcareUserId),
			]);

		if (
			patientInformation.error ||
			patientBasicInformation.error ||
			primaryConditionRes.error ||
			patientInfoObservations.error ||
			careTeamParticipants.error
		) {
			this.setState({ error: translate('somethingWentWrong') });
		} else {
			const { profile } = patientBasicInformation.patient;
			const newParsed = {
				...notes,
				patientName: `${profile.firstName || ''} ${profile.lastName || ''}`.trim(),
				patientAge: getAge(profile.birthDate) || '',
				birthDate: profile.birthDate,
				genderIdentity: patientBasicInformation.patient.genderIdentity,
				preferredLanguage: profile?.communicationLanguage || null,
				nurseNameNumber: this.getCareTeamParticipantName(
					careTeamParticipants.careTeamParticipants,
					CareTeamParticipantRole.REGISTERED_NURSE
				),
				nurseAssistantNameNumber: this.getCareTeamParticipantName(
					careTeamParticipants.careTeamParticipants,
					CareTeamParticipantRole.PATIENT_CARE_TECHNICIAN
				),
				tlNameNumber: this.getCareTeamParticipantName(
					careTeamParticipants.careTeamParticipants,
					CareTeamParticipantRole.NURSING_TEAM_LEADER
				),
				primaryAdverseEvent: this.getArrayByType({
					arr: patientInfoObservations.observations,
					field: 'observationTypeId',
					id: ObservationType.PRIMARY_ADVERSE_EVENT,
					initialArr: primaryAdverseEvent(this.props.intl),
				}),
				primaryPatientRiskFactor: this.getArrayByType({
					arr: patientInfoObservations.observations,
					field: 'observationTypeId',
					id: ObservationType.PRIMARY_PATIENT_RISK_FACTOR,
					initialArr: primaryPatientRiskFactor(this.props.intl),
				}),
				titleBarNotes: this.getDisplayFromObservationByTypeId(
					patientInfoObservations.observations,
					ObservationType.ADDITIONAL_TITLE_BAR_NOTES
				),
				additionalNotes: this.getDisplayFromObservationByTypeId(
					patientInfoObservations.observations,
					ObservationType.ADDITIONAL_PATIENT_NOTES
				),
				primaryPatientCondition: this.getArrayByType({
					arr: primaryConditionRes.conditions,
					field: 'conditionType',
					id: ConditionType.PRIMARY_PATIENT_CONDITION,
					initialArr: primaryPatientCondition(this.props.intl),
				}),
			};

			const noteItems = this.removeItemsOtherThanPatientInfo(notes, newParsed);
			const newObj = {
				...this.props.patientNotes.savedMode[deviceId],
				...noteItems,
				notes: '',
				hasFetchedNotes: true,
			};
			this.props.patientNotesActions.setPatientDataSaved(newObj, deviceId);
		}
	};

	addFeed = async ({ deviceId, feedType = this.state.feedType, isInitial = true }) => {
		if (isInitial) {
			this.props.patientNotesActions.setPatientDataSaved({}, deviceId);
		}

		const { conferenceInfo } = this.state;
		const treeData = this.props.healthSystems.treeData.tree;

		const foundSector = findSectorById(treeData, deviceId);

		const sector = foundSector || this.state.videoFeeds.find(item => item.deviceId === deviceId)?.companyPathInfo;

		if (conferenceInfo) {
			const response = await this.callManager.addDeviceToMonitoring({
				conferenceId: conferenceInfo.conferenceId,
				participantId: conferenceInfo.participantId,
				participant: {
					objectType: ObjectType.HELLO_DEVICE,
					objectId: deviceId,
					isAmbient: feedType === this.FeedTypes.AmbientMonitoring,
				},
			});
			if (response?.failedInvitationToParticipants?.length) {
				this.addIdToInitiatedDevice(deviceId);
				this.setState(prevState => {
					return {
						...prevState,
						videoFeeds: prevState.videoFeeds.map(feed => {
							const newFeed = { ...feed };
							if (feed.deviceId === deviceId) {
								const failedFeed = response.failedInvitationToParticipants.find(f => f.objectId === deviceId);
								if (failedFeed) {
									newFeed.status =
										failedFeed.reason === InviteParticipantFailureReason.INVITE_DENIED
											? ParticipantState.INVITE_DENIED.type
											: ParticipantState.FAILED_TO_GET_INFO.type;
								}
							}
							return newFeed;
						}),
					};
				});
				return;
			}
			const newTree = [...this.props.healthSystems.treeData.tree];
			this.props.healthSystemActions.setTreeData(newTree, this.props.healthSystems.treeData.preSelected);
		}
		const selectedFeed = this.state.videoFeeds.find(item => item.deviceId === deviceId);
		if (!selectedFeed) {
			return;
		}
		let isDefaultOwner = false;
		const devicesResponse = await getDeviceList(DeviceListLevel.ROOM, selectedFeed.roomId);
		if (devicesResponse.error) {
			this.setState({ error: devicesResponse.error.message });
		} else {
			isDefaultOwner = devicesResponse[0].isDefaultOwner;
		}
		let deviceOwner = { patientFormattedName: '', age: null, source: '' };
		let lastCondition = '';
		const deviceSettingsRes = await getDeviceSettings(deviceId);
		if (!isDefaultOwner) {
			const deviceOwnerResponse = await getDeviceOwnerPatientWithRetry(deviceId);
			if (deviceOwnerResponse.error) {
				this.setState({ error: deviceOwnerResponse.error.message });
				return;
			}
			deviceOwner = { ...deviceOwnerResponse, patientFormattedName: this.getPatientFormattedName(deviceOwnerResponse) };
			this.fetchDraftsCount(deviceOwner.healthcareUserId, deviceId);
			if (!deviceOwner.isVirtualPatient) {
				const lastConditionResponse = await getFallRiskLevel(deviceOwner?.healthcareUserId);
				if (lastConditionResponse.error) {
					this.setState({ error: lastConditionResponse.error.message });
				} else {
					const found = lastConditionResponse?.observations?.find(
						item => item.code === this.fallRiskCode || item.code === this.humptyDumptyFallsAssessmentScore
					);
					if (found) {
						if (found.code === this.fallRiskCode) {
							lastCondition = this.getFallRiskLabel(found);
						}
						if (found.code === this.humptyDumptyFallsAssessmentScore) {
							lastCondition = this.getHumptyDumptyFallAssessmentLabel(found);
						}
					}
				}
				const patientAge = getAge(deviceOwnerResponse?.dateOfBirth);
				deviceOwner = {
					...deviceOwner,
					age: patientAge,
					patientFormattedName: this.getPatientFormattedName(deviceOwnerResponse),
					mrn: deviceOwnerResponse.mrn,
				};
				if (deviceOwner.healthcareUserId) {
					const tvWidgetsRes = await getTvWidgets(deviceOwner.healthcareUserId);
					if (tvWidgetsRes.error) {
						this.setState({ error: this.props.intl.formatMessage({ id: 'somethingWentWrong' }) });
						return;
					}
					const careTeamData = tvWidgetsRes.tvWidgets?.find(item => item.widgetType === WhiteboardWidgets.CARE_TEAM)?.value;
					const careTeam = isJSON(careTeamData) ? JSON.parse(careTeamData) : '';
					deviceOwner.careTeam = careTeam;
				}
			}
			const foundIndex = this.state.videoFeeds.findIndex(feed => feed.deviceId === deviceId);
			if (foundIndex === -1) {
				return;
			}

			if (isInitial && deviceOwner) {
				this.fetchNoteAndLanguage(deviceId, deviceOwner);
			}

			if (deviceOwner?.healthcareUserId) {
				this.fetchPatientAiSettings({
					patientId: deviceOwner?.healthcareUserId,
					deviceId,
					roomId: selectedFeed.roomId,
					...(!deviceSettingsRes.error && {
						dataAcquisition: deviceSettingsRes.deviceSettings.find(item => item.settingTypeId === HelloSettings.DATA_ACQUISITION)
							?.value,
					}),
				});
			}
		}

		const foundIndex = this.state.videoFeeds.findIndex(feed => feed.deviceId === deviceId);
		if (foundIndex === -1) {
			return;
		}

		this.setState(prevState => {
			const feeds = [...prevState.videoFeeds];
			if (feeds.length > 0 && feeds?.[foundIndex]) {
				if (!isDefaultOwner) {
					feeds[foundIndex].deviceOwner = deviceOwner;
					feeds[foundIndex].lastCondition = lastCondition;
					feeds[foundIndex].isDefaultOwner = isDefaultOwner;
					if (
						!isDefaultOwner &&
						!deviceSettingsRes.error &&
						deviceSettingsRes.deviceSettings.find(item => item.settingTypeId === HelloSettings.PRIVACY_MODE_TIMEOUT)?.value &&
						isJSON(
							deviceSettingsRes.deviceSettings.find(item => item.settingTypeId === HelloSettings.PRIVACY_MODE_TIMEOUT)?.value
						)
					) {
						feeds[foundIndex].aiPrivacyStatus = JSON.parse(
							deviceSettingsRes.deviceSettings.find(item => item.settingTypeId === HelloSettings.PRIVACY_MODE_TIMEOUT)?.value
						).active;
					}
				}
				feeds[foundIndex].isDefaultOwner = isDefaultOwner;
				if (sector) {
					feeds[foundIndex].roomType = sector.roomType;
				}
			}

			return {
				...prevState,
				videoFeeds: feeds,
				...(deviceSettingsRes.error && {
					error: deviceSettingsRes.error.message,
				}),
			};
		});

		this.addIdToInitiatedDevice(deviceId);
	};

	fetchPatientAiSettings = async ({ patientId, deviceId, roomId, dataAcquisition }) => {
		const response = await getPatientsAiSettings({ patientId, deviceId, roomId, workflowType: CallWorkflowType.MONITORING });
		if (response.error) {
			this.setState({ error: response.error.message });
			return;
		}

		const mappedResponse = { deviceId, settings: response.patientAiSettings };

		const isAiDrawingsEnabled = isSettingEnabled(response.patientAiSettings, PatientAiSetting.AI_DRAWING);

		const selectedFeed = this.state.videoFeeds.find(item => item.deviceId === deviceId);
		if (!selectedFeed) {
			return;
		}
		this.props.aiSettingsActions.setPatientAiSettings(mappedResponse);
		this.onAiSettingClick(isAiDrawingsEnabled, selectedFeed, dataAcquisition);
	};

	onPatientAiSettingsUpdated = async ({ patientAiSettings, deviceId, workflowType }) => {
		const { videoFeeds } = this.state;
		if (!videoFeeds) {
			return;
		}
		const selectedFeed = videoFeeds.find(item => item.deviceId === deviceId);
		if (!selectedFeed || selectedFeed?.deviceId !== deviceId || workflowType === CallWorkflowType.ROUNDING) {
			return;
		}

		const isAiDrawingsEnabled = isSettingEnabled(patientAiSettings, PatientAiSetting.AI_DRAWING);

		const deviceSettingsRes = await getDeviceSettings(deviceId);
		if (deviceSettingsRes.error) {
			this.setState({ error: deviceSettingsRes.error.message });
			return;
		}
		const dataAcquisition = deviceSettingsRes.deviceSettings.find(
			item => item.settingTypeId === HelloSettings.DATA_ACQUISITION
		)?.value;

		this.onAiSettingClick(isAiDrawingsEnabled, selectedFeed, dataAcquisition);
		this.props.aiSettingsActions.setPatientAiSettings({ deviceId, settings: patientAiSettings });
	};

	onAiNotificationTypesUpdated = ({ deviceId, patientAiSettings }) => {
		const { videoFeeds } = this.state;
		const selectedFeed = videoFeeds.find(item => item.deviceId === deviceId);
		if (selectedFeed?.deviceId !== deviceId) {
			return;
		}
		const aiSettingsList = _.cloneDeep(this.props.aiSettings);
		const found = aiSettingsList.find(item => item.deviceId === deviceId);
		if (!found) {
			return;
		}
		const updatedArr = found.settings.map(item => {
			const match = patientAiSettings.find(el => el.settingTypeId === item.settingTypeId);
			if (match) {
				return { ...item, notificationOptions: match.notificationOptions };
			}
			return item;
		});
		this.props.aiSettingsActions.setPatientAiSettings({ deviceId: selectedFeed.deviceId, settings: updatedArr });
	};

	setBioBeatDeviceStatusToOff = async ({ taskId, patientUserId, patientUserGuid, deviceId }) => {
		const response = await setBioBeatDeviceState({
			taskId,
			deviceId: this.bioBeatConfig.find(item => item.deviceId === deviceId)?.biobeatId.toString(),
			state: this.bioBeatDeviceFeedState.OFF,
			patientUserId,
			patientUserGuid,
		});
		return response;
	};

	removeFeed = async (key, isFromSession = false, reason = '', sendDirectlyToDraft = false) => {
		this.ptt.leave();
		const { videoFeeds } = this.state;
		let { conferenceInfo } = this.state;
		const feedIndex = videoFeeds.findIndex(item => item.deviceId === key);
		const feed = videoFeeds.find(item => item.deviceId === key);
		if (!feed) {
			return;
		}
		if (feed.audioTrack) {
			feed.audioTrack.stop();
		}
		if (feed.warning.value && this.playingAlert) {
			this.playingAlert.currentTime = 0;
			this.playingAlert.pause();
		}
		if (feed.warning.alertTypeId === AiAlertId.FALL_DETECTED) {
			this.onFallDetected(false, feed);
		}
		const { departmentId, floorId, hospitalId, roomId, participantId } = feed;
		if (hospitalId) {
			this.props.healthSystemActions.removeDeviceFromMonitoring({ hospitalId, departmentId, floorId, roomId, deviceId: key });
		}
		this.setState(prevState => {
			const feedWithAudio = { ...prevState.previousFeedAudioEnabled };
			delete feedWithAudio[feed.deviceId];
			return {
				previousFeedAudioEnabled: feedWithAudio,
			};
		});

		const [removedFeed] = videoFeeds.splice(feedIndex, 1);
		if (conferenceInfo && !isFromSession) {
			this.callManager.removeDeviceFromMonitoring({
				conferenceId: conferenceInfo.conferenceId,
				participantId: conferenceInfo.participantId,
				actioneeParticipantId: participantId,
			});
		}

		this.removeIdFromMonitoringDevices(removedFeed.deviceId);

		if (videoFeeds.length === 0) {
			this.endMonitoring();
			conferenceInfo = null;
			this.setState(prevState => ({
				monitoringSessions: prevState.monitoringSessions.map(item => ({ ...item, isActive: !item.id })),
				audioFromMonitoringFeeds: false,
				isMicrophoneForAllFeedsEnabled: false,
				isTimelineCollapsed: false,
				isStreamSettingsTabOpen: false,
			}));
		}
		const { isFeedExpanded } = feed;

		this.closeBioBeatOnRemoveFeed(feed, key);

		this.setState(prevState => ({
			videoFeeds,
			conferenceInfo,
			isFeedZoomed: isFeedExpanded ? !isFeedExpanded : prevState.isFeedZoomed,
			selectedFeed: prevState.selectedFeed?.deviceId === feed.deviceId ? null : prevState.selectedFeed,
			hasPttJoined: false,
			...(prevState.selectedFeed?.deviceId === feed.deviceId && {
				currentTab: 0,
			}),
		}));

		this.handleBitrateChange();
		if (feed.feedType === this.FeedTypes.Monitoring) {
			this.callManager.startMeasuringIoTDevice({
				iotDevice: MeasureDeviceType.WATCH,
				helloDeviceId: feed.deviceOwner?.userId,
				conferenceId: conferenceInfo?.conferenceId,
				participantId,
				iotDeviceType: '',
				objectType: enums.ObjectTypes.USER,
				measureDeviceType: MeasureDeviceType.WATCH,
				startMeasure: false,
				doctorId: getUserInfo().userId,
				deviceId: feed.deviceId,
			});
			this.props.healthDataActions.setWatchMeasurements({ value: false, deviceId: feed.deviceId });
		}

		if (!feed?.isDefaultOwner && reason) {
			this.discontinueMonitoring(feed, reason);
		}

		if (!feed?.isDefaultOwner && !reason && sendDirectlyToDraft) {
			this.saveSpecificFeedToDraft(feed);
		}
	};

	saveSpecificFeedToDraft = async feed => {
		if (!feed) {
			return;
		}

		let patientId = feed.deviceOwner?.healthcareUserId;

		if (!feed.isDefaultOwner && !feed.deviceOwner?.healthcareUserId) {
			const deviceOwnerResponse = await getDeviceOwnerPatient(feed.deviceId);
			if (deviceOwnerResponse.error) {
				this.setState({ error: deviceOwnerResponse.error.message });
				return;
			}
			patientId = deviceOwnerResponse?.healthcareUserId;
		}

		const updateDraftsResponse = await updateAllDrafts([{ deviceId: feed.deviceId, patientUserId: patientId }]);

		if (updateDraftsResponse.error) {
			this.setState({
				error: this.props.intl.formatMessage({ id: 'somethingWentWrong' }),
			});
		}
	};

	discontinueMonitoring = async (feed, comment) => {
		if (!feed) {
			return;
		}

		let patientId = feed.deviceOwner?.healthcareUserId;

		if (!feed.isDefaultOwner && !feed.deviceOwner?.healthcareUserId) {
			const deviceOwnerResponse = await getDeviceOwnerPatient(feed.deviceId);
			if (deviceOwnerResponse.error) {
				this.setState({ error: deviceOwnerResponse.error.message });
				return;
			}
			patientId = deviceOwnerResponse?.healthcareUserId;
		}

		const dataToSend = {
			patientId,
			deviceId: feed.deviceId,
			manualAlertTypeId: ManualAlertTypes.DISCONTINUE_MONITORING,
			comment,
			isDraft: false,
			conferenceId: this.state.conferenceInfo?.conferenceId,
			participantId: feed.participantId,
		};
		const [response, updateDraftsResponse] = await Promise.all([
			saveManualAlert(dataToSend),
			updateAllDrafts([{ deviceId: feed.deviceId, patientUserId: patientId }]),
		]);

		if (response.error || updateDraftsResponse.error) {
			this.setState({ error: this.props.intl.formatMessage({ id: 'somethingWentWrong' }), reasonToDiscontinueMonitoring: null });
			return;
		}
		this.setState({
			reasonToDiscontinueMonitoring: null,
		});
	};

	saveAllSessionDraftsToHistory = async () => {
		const { videoFeeds } = this.state;
		const filteredFeeds = videoFeeds.reduce((acc, feed) => {
			if (!feed?.isDefaultOwner && feed?.deviceOwner?.healthcareUserId) {
				acc.push({ deviceId: feed.deviceId, patientUserId: feed.deviceOwner?.healthcareUserId });
			}
			return acc;
		}, []);

		if (filteredFeeds?.length === 0) {
			return;
		}

		const response = await updateAllDrafts(filteredFeeds);

		if (response.error) {
			this.setState({ error: response.error.message });
		}
	};

	closeBioBeatOnRemoveFeed = async (feed, key) => {
		if (feed.isBioBeatActive && feed.bioBeatTaskId) {
			const response = await this.setBioBeatDeviceStatusToOff({
				taskId: feed.bioBeatTaskId,
				patientUserId: feed.deviceOwner.userId,
				patientUserGuid: feed.deviceOwner.healthcareUserId,
				deviceId: key,
			});
			if (response.error) {
				this.setState({
					error: response?.error?.message,
				});
			}
			const getStorageBioBeat = getStorage().activeBioBeatItems ? JSON.parse(getStorage().activeBioBeatItems) : [];

			const foundIndex = getStorageBioBeat.findIndex(item => item.deviceId === key);

			if (foundIndex !== -1) {
				getStorageBioBeat.splice(foundIndex, 1);
			}

			getStorage().setItem('activeBioBeatItems', JSON.stringify(getStorageBioBeat));
		}
	};

	endMonitoring = () => {
		this.postEndMessage();
		this.setState({
			conferenceInfo: null,
		});
		this.callManager.endCall({ endReason: ConferenceEndReason.INITIATOR_LEFT });
	};

	changeFeedBitrate = (deviceId, minBitrate, maxBitrate) => {
		const { videoFeeds, conferenceInfo } = this.state;
		const feed = videoFeeds.find(item => item.deviceId === deviceId);
		if (!conferenceInfo || !feed || feed.feedType === this.FeedTypes.AmbientMonitoring) {
			return;
		}
		const { participantId } = feed;
		this.callManager.requestToChangeBitrate({
			conferenceId: conferenceInfo.conferenceId,
			participantId: conferenceInfo.participantId,
			actioneeParticipantId: participantId,
			settings: {
				minBitrate,
				maxBitrate,
			},
		});
	};

	handleBitrateChange = () => {
		const { videoFeeds } = this.state;
		const videoFeedsLength = Object.keys(videoFeeds).length;
		const expandedFeed = Object.values(videoFeeds).find(videoFeed => videoFeed.isFeedExpanded);
		const videoBitrateConfig = getVideoBitrateConfig(this.companyId);
		const setting = videoBitrateConfig.find(config => config.maxFeeds >= videoFeedsLength);

		if (expandedFeed) {
			videoFeeds.forEach(item => {
				if (expandedFeed.deviceId !== item.deviceId) {
					this.changeFeedBitrate(item.deviceId, setting.minBitrate, setting.maxBitrate);
				} else {
					this.changeFeedBitrate(item.deviceId, videoBitrateConfig[0].minBitrate, videoBitrateConfig[0].maxBitrate);
				}
			});
		} else {
			videoFeeds.forEach(item => {
				this.changeFeedBitrate(item.deviceId, setting.minBitrate, setting.maxBitrate);
			});
		}
	};

	toggleExpandFeed = async (feed, event) => {
		if (event) {
			event.stopPropagation();
		}

		await this.ptt.leave();
		const { videoFeeds } = this.state;
		const newFeeds = _.cloneDeep(videoFeeds);
		const selectedFeedIndex = newFeeds.findIndex(item => item.deviceId === feed.deviceId);
		const [clickedFeed] = newFeeds.splice(selectedFeedIndex, 1);
		clickedFeed.isFeedExpanded = !feed.isFeedExpanded;
		if (clickedFeed?.deviceId !== this.state.selectedFeed?.deviceId) {
			clickedFeed.isLifeSignalsPopupOpen = false;
		}
		if (!clickedFeed.isFeedExpanded && clickedFeed?.isAlertTimelineVisible) {
			clickedFeed.isAlertTimelineVisible = false;
		}
		newFeeds.forEach(item => {
			// eslint-disable-next-line no-param-reassign
			item.isFeedExpanded = false;
			item.isAlertTimelineVisible = false;
			item.isLifeSignalsPopupOpen = false;
		});
		newFeeds.splice(selectedFeedIndex, 0, clickedFeed);

		const preSelected = { ...this.state.selectedFeed };

		this.setState(
			{
				isFeedZoomed: clickedFeed.isFeedExpanded,
				videoFeeds: newFeeds,
				...(clickedFeed.isFeedExpanded && { selectedFeed: clickedFeed }),
				isTimelineCollapsed: false,
			},
			() => {
				this.handleBitrateChange();
				if (
					clickedFeed.isFeedExpanded &&
					this.state.selectedFeed?.deviceId !== preSelected?.deviceId &&
					!this.state.selectedFeed?.isPeerSpeakerActive
				) {
					this.togglePeerSpeaker(this.state.selectedFeed, null, true);
				}
				this.toggleOffOtherMicrophones(this.state.selectedFeed);
			}
		);

		const isPttConfigOn = await this.getWalkieTalkieSetting(clickedFeed.roomId);

		if (!isPttConfigOn) {
			return;
		}

		if (clickedFeed.isFeedExpanded && clickedFeed.floorId) {
			this.ptt
				.join(clickedFeed.floorId, this.getSelectedFloor(feed.deviceId)?.conversationId)
				.then(() => {
					this.setState(prevState => {
						const feeds = _.cloneDeep(prevState.videoFeeds);
						if (feeds.length > 0 && feeds[selectedFeedIndex]) {
							feeds[selectedFeedIndex].isPttAvailable = true;
						}

						return { ...prevState, videoFeeds: feeds };
					});
				})
				.catch(err => {
					// eslint-disable-next-line
					console.warn('Unable to join ptt on feed:', err);
					this.setState(prevState => {
						const feeds = _.cloneDeep(prevState.videoFeeds);
						if (feeds.length > 0 && feeds[selectedFeedIndex]) {
							feeds[selectedFeedIndex].isPttAvailable = false;
						}
						return { ...prevState, videoFeeds: feeds };
					});
				});
		}
	};

	toggleNightVision = async (feed, event = null) => {
		if (event) {
			event.stopPropagation();
		}
		const { conferenceInfo } = this.state;
		const filteredNightvisionDevices = feed.mediaDevices?.filter(device => device.capabilities?.nightvision?.isSupported);
		let response;
		try {
			if (filteredNightvisionDevices?.length > 1) {
				filteredNightvisionDevices.forEach(async device => {
					response = await this.sendCameraEvent(
						SocketEvents.Conference.ON_MEDIA_CONTROLS,
						{
							command: 'nightVision',
							type: 'camera',
							data: JSON.stringify({
								cameraId: device?.id,
								enable: !feed.isNightVisionActive,
							}),
							participantId: conferenceInfo.participantId,
							conferenceId: conferenceInfo.conferenceId,
							actioneeParticipantId: feed.participantId,
						},
						feed
					);
				});
			} else {
				const activeCamera = feed.mediaDevices?.find(device => device.isActive);
				if (!activeCamera?.capabilities?.nightvision?.isSupported) {
					response = await this.sendCameraEvent(
						SocketEvents.Conference.ON_MEDIA_CONTROLS,
						{
							command: 'activeDevice',
							type: 'camera',
							data:
								activeCamera?.type === CameraType.HELLO
									? feed.mediaDevices?.find(device => device.type === CameraType.HUDDLE)?.id
									: feed.mediaDevices?.find(device => device.type === CameraType.HELLO)?.id,
							participantId: conferenceInfo.participantId,
							conferenceId: conferenceInfo.conferenceId,
							actioneeParticipantId: feed.participantId,
						},
						feed
					);
				}
				response = await this.sendCameraEvent(
					SocketEvents.Conference.ON_MEDIA_CONTROLS,
					{
						command: 'nightVision',
						type: 'camera',
						data: JSON.stringify({
							cameraId: feed.mediaDevices?.find(device => device.type === CameraType.HELLO)?.id,
							enable: !feed.isNightVisionActive,
						}),
						participantId: conferenceInfo.participantId,
						conferenceId: conferenceInfo.conferenceId,
						actioneeParticipantId: feed.participantId,
					},
					feed
				);
			}

			if (response?.deviceControlsLocked) {
				this.setState(prevState => {
					return {
						...prevState,
						videoFeeds: prevState.videoFeeds.map(item => {
							const newFeed = { ...item };
							if (feed.deviceId === newFeed.deviceId) {
								newFeed.showDeviceControlsLockedModal = true;
							}
							return newFeed;
						}),
					};
				});
			} else {
				this.toggleNightVisionIcon(feed);
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.log(err);
		}
	};

	checkForMicrophonePermission = async () => {
		const pluggedDevices = await checkIfMediaDevicesPlugged();

		if (!pluggedDevices.microphone) {
			this.props.healthSystemActions.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.MICROPHONE_NOT_FOUND.type,
			});
			return false;
		}

		if (this.micStatus.state === MediaPermissions.DENIED) {
			await askForPermission({ audio: true });
			this.props.healthSystemActions.setStreamPermissionMessage({
				component: 'popup',
				type: StreamError.MICROPHONE_BLOCKED.type,
			});
			return false;
		}

		if (this.micStatus.state === MediaPermissions.PROMPT) {
			this.props.healthSystemActions.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.MICROPHONE_BLOCKED.type,
			});
			await askForPermission({ audio: true });
			this.props.healthSystemActions.setStreamPermissionMessage(null);
			return false;
		}
		return true;
	};

	toggleMyMicrophone = async (feed, event = null) => {
		if (event) {
			event.stopPropagation();
		}
		const hasPermission = await this.checkForMicrophonePermission();
		if (hasPermission) {
			this.toggleMonitoringMic(feed);
		}
	};

	toggleWalkieTalkie = async isWalkieTalkieOn => {
		const hasPermission = await this.checkForMicrophonePermission();
		if (!hasPermission) {
			return false;
		}
		const response = await this.ptt.mic(isWalkieTalkieOn);
		if (response?.error) {
			this.props.healthSystemActions.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.MICROPHONE_NOT_FOUND.type,
			});
			return false;
		}
		return true;
	};

	toggleMonitoringMic = async feed => {
		if (feed.audioTrack) {
			if (this.prevFeed && this.prevFeed.audioTrack && this.prevFeed.audioTrack.id !== feed.audioTrack.id) {
				this.prevFeed.audioTrack.enabled = false;
				this.callManager.monitoringTrackToggled(false, this.prevFeed.participantId);
			}
			this.prevFeed = feed;
			// eslint-disable-next-line no-param-reassign
			feed.audioTrack.enabled = !feed.audioTrack.enabled;
			this.removePreviousActiveMic(this.state.videoFeeds, feed);
			this.callManager.monitoringTrackToggled(feed.audioTrack.enabled, feed.participantId);
		} else {
			const { videoFeeds } = this.state;
			videoFeeds.forEach(videoFeed => {
				if (videoFeed.audioTrack && videoFeed.audioTrack.enabled) {
					// eslint-disable-next-line no-param-reassign
					videoFeed.audioTrack.enabled = false;
					this.callManager.monitoringTrackToggled(false, videoFeed.participantId);
				}
			});
			this.callManager.callerParticipantId = feed.participantId;
			const audioStream = await this.callManager.toggleAudio(feed.participantId);

			if (this.callManager.p2p.hasPeerConnectionChannelForRemoteId(feed.participantId)) {
				const pc = this.callManager.p2p.getChannel(feed.participantId).getRTCPeerConnection();
				pc.onconnectionstatechange = event =>
					this.callManager.onConnectionStateChange(
						event,
						feed.participantId,
						feed.audioTrack && feed.audioTrack.readyState === 'live' ? audioStream : null
					);
			}
			if (!audioStream || audioStream.error) {
				const { videoFeeds: feeds } = this.state;

				const newVideoFeeds = feeds.map(item => {
					const newItem = { ...item };
					if (this.prevFeed && this.prevFeed.audioTrack && item.participantId === this.prevFeed.participantId) {
						newItem.isMyMicActive = false;
					}
					if (item.participantId === feed.participantId) {
						newItem.showDeviceControlsLockedModal = !audioStream;
					}
					return newItem;
				});

				this.setState({ videoFeeds: newVideoFeeds });

				if (audioStream && audioStream.error) {
					this.props.healthSystemActions.setStreamPermissionMessage({
						component: 'modal',
						type: StreamError.MICROPHONE_NOT_FOUND.type,
					});
				}
				return;
			}

			const audioTrack = audioStream.mediaStream.getAudioTracks()[0];
			// eslint-disable-next-line no-param-reassign
			feed.audioTrack = audioTrack;
			this.prevFeed = feed;
			this.removePreviousActiveMic(this.state.videoFeeds, feed);
		}

		if (this.hasAnyFeedWarning()) {
			const hasNotificationType = notificationType =>
				getNotificationOptions({
					alertTypeId: feed.warning?.alertTypeId,
					deviceId: feed.deviceId,
					aiSettingList: this.props.aiSettings,
				}).includes(notificationType);

			const hasToastMessageType = hasNotificationType(AiAlertNotificationType.TOAST_MESSAGE);
			if (
				(!this.isAnyFeedMicActive() && !hasToastMessageType && feed.warning.isAiAlert) ||
				(!this.isAnyFeedMicActive() && feed.warning?.value && !feed.warning.isAiAlert)
			) {
				this.tryToPlayAlertSound(feed.deviceId, feed.warning.isAiAlert);
			}
			if (this.isAnyFeedMicActive() && this.playingAlert) {
				this.playingAlert.currentTime = 0;
				this.playingAlert.pause();
			}
		}
	};

	tryToPlayAlertSound = async (deviceId, isAiAlert = false) => {
		const aiSettingsList = _.cloneDeep(this.props.aiSettings);
		const found = aiSettingsList.find(item => item.deviceId === deviceId);
		if (!found) {
			return;
		}
		const isSoundEnabled = found.settings.some(
			item => item.settingTypeId === PatientAiSetting.SOUND_ON_AI_ALERT && item.isEnabled
		);
		if (!isSoundEnabled && isAiAlert) {
			return;
		}

		try {
			this.playingAlert.loop = true;
			await this.playingAlert.play();
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('Autoplay failed:', error);
		}
	};

	hasAnyFeedWarning = () => this.state.videoFeeds.some(feed => Object.keys(feed.warning).length > 0);

	togglePeerSpeaker = async (feed, event = null, enable = false) => {
		if (event) {
			event.stopPropagation();
		}

		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];

		this.callManager.callerParticipantId = feed.participantId;
		const response = await this.callManager.toggleParticipantTrack(CallTypes.AUDIO, feed.isPeerSpeakerActive);
		if (response.deviceControlsLocked) {
			this.setState(prevState => {
				return {
					...prevState,
					videoFeeds: prevState.videoFeeds.map(item => {
						const newFeed = { ...item };
						if (feed.deviceId === newFeed.deviceId) {
							newFeed.showDeviceControlsLockedModal = true;
						}
						return newFeed;
					}),
				};
			});
			this.callManager.toggleParticipantTrack(CallTypes.AUDIO, feed.isPeerSpeakerActive);
			return;
		}

		const selectedFeedIndex = newVideoFeeds.findIndex(item => item.deviceId === feed.deviceId);
		const [selectedFeed] = newVideoFeeds.splice(selectedFeedIndex, 1);
		if (!enable) {
			selectedFeed.isPeerSpeakerActive = !feed.isPeerSpeakerActive;
		} else {
			selectedFeed.isPeerSpeakerActive = true;
		}
		this.setState(prevState => ({
			previousFeedAudioEnabled: {
				...(prevState.audioFromMonitoringFeeds && { ...prevState.previousFeedAudioEnabled }),
				[selectedFeed.deviceId]: selectedFeed.isPeerSpeakerActive,
			},
		}));

		// if peer(hello) is not in another call than disable speaker to the previous hellos
		if (
			!getConfigurationValue(this.props.healthSystemConfigurations[MonitoringSettings.EnableMultipleFeedAudio]) ||
			!this.state.audioFromMonitoringFeeds
		) {
			const previousFeeds = videoFeeds.filter(
				videoFeed => videoFeed.participantId !== feed.participantId && videoFeed.isPeerSpeakerActive
			);
			if (previousFeeds?.length) {
				previousFeeds.forEach(previousFeed => {
					this.callManager.toggleParticipantTrack(CallTypes.AUDIO, true, previousFeed.participantId);
					previousFeed.isPeerSpeakerActive = false;
				});
			}
		}

		newVideoFeeds.splice(selectedFeedIndex, 0, selectedFeed);

		this.setState({ videoFeeds: newVideoFeeds });
	};

	toggleAllSpeakers = async audioFromMonitoringFeeds => {
		const allVideoFeeds = [...this.state.videoFeeds];
		const videoFeeds = await Promise.all(
			allVideoFeeds.map(async feed => {
				const newFeed = { ...feed };
				if (feed.status !== ParticipantState.CONNECTED.type) {
					return newFeed;
				}
				this.callManager.callerParticipantId = newFeed.participantId;
				if (audioFromMonitoringFeeds === newFeed.isPeerSpeakerActive) {
					return newFeed;
				}
				const response = await this.callManager.toggleParticipantTrack(CallTypes.AUDIO, newFeed.isPeerSpeakerActive);
				if (response.deviceControlsLocked) {
					return newFeed;
				}
				newFeed.isPeerSpeakerActive = !newFeed.isPeerSpeakerActive;
				this.setState(prevState => ({
					previousFeedAudioEnabled: {
						...prevState.previousFeedAudioEnabled,
						[feed.deviceId]: newFeed.isPeerSpeakerActive,
					},
				}));
				return newFeed;
			})
		);
		this.setState({ videoFeeds, audioFromMonitoringFeeds });
	};

	toggleAllMicrophones = async () => {
		const setFeeds = async feeds => {
			const videoFeeds = await Promise.all(
				feeds.map(feed => this.enableMicrophone(feed, this.state.isMicrophoneForAllFeedsEnabled))
			);
			this.setState({ videoFeeds });
		};

		this.setState(
			prevState => ({ isMicrophoneForAllFeedsEnabled: !prevState.isMicrophoneForAllFeedsEnabled }),
			() => {
				setFeeds(this.state.videoFeeds);
			}
		);
	};

	enableMicrophone = async (feed, isEnabled) => {
		if (feed.feedType === this.FeedTypes.AmbientMonitoring) {
			return feed;
		}
		if (feed.audioTrack) {
			feed.audioTrack.enabled = isEnabled;
			feed.isMyMicActive = isEnabled;
			this.callManager.monitoringTrackToggled(isEnabled);
			return feed;
		}

		this.callManager.callerParticipantId = feed.participantId;
		const audioStream = await this.callManager.toggleAudio(feed.participantId);

		if (this.callManager.p2p.hasPeerConnectionChannelForRemoteId(feed.participantId)) {
			const pc = this.callManager.p2p.getChannel(feed.participantId).getRTCPeerConnection();
			pc.onconnectionstatechange = event =>
				this.callManager.onConnectionStateChange(
					event,
					feed.participantId,
					feed.audioTrack && feed.audioTrack.readyState === 'live' ? audioStream : null
				);
		}

		if (!audioStream || audioStream.error) {
			return feed;
		}

		const audioTrack = audioStream.mediaStream.getAudioTracks()[0];
		feed.audioTrack = audioTrack;
		feed.isMyMicActive = isEnabled;

		return feed;
	};

	removePreviousActiveMic = (previousFeeds, currentFeed) => {
		if (this.state.videoFeeds.length === 0) {
			return;
		}
		previousFeeds.forEach(item => {
			if (item.participantId === currentFeed.participantId) {
				item.isMyMicActive = currentFeed.audioTrack.enabled;
			} else {
				item.isMyMicActive = false;
				if (item?.audioTrack?.enabled) {
					item.audioTrack.enabled = false;
				}
			}
		});
		this.setState({
			videoFeeds: previousFeeds,
			isMicrophoneForAllFeedsEnabled: false,
		});
	};

	toggleNightVisionIcon = feed => {
		let selectedFeed = { ...this.state.selectedFeed };
		this.setState(prevState => {
			const newVideoFeeds = prevState.videoFeeds.map(item => {
				const newFeed = { ...item };
				if (newFeed.deviceId === feed.deviceId) {
					newFeed.isNightVisionActive = !newFeed.isNightVisionActive;
					selectedFeed = newFeed;
				}
				return newFeed;
			});
			return { videoFeeds: newVideoFeeds, selectedFeed };
		});
	};

	onDeviceLockedEventHandler = async data => {
		const { deviceId } = data;
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];
		const selectedFeedIndex = newVideoFeeds.findIndex(item => item.deviceId === deviceId);
		const [selectedFeed] = newVideoFeeds.splice(selectedFeedIndex, 1);
		selectedFeed.showDeviceControlsLockedModal = false;
		if (selectedFeed.audioTrack) {
			if (selectedFeed.audioTrack.enabled && selectedFeed.isMyMicActive) {
				this.removePreviousActiveMic(newVideoFeeds, selectedFeed);
				selectedFeed.isMyMicActive = !selectedFeed.isMyMicActive;
				selectedFeed.audioTrack.enabled = false;
				selectedFeed.showDeviceControlsLockedModal = true;
			}
			this.callManager.removeTrackById(selectedFeed.participantId, selectedFeed.audioTrack.id);
			selectedFeed.audioTrack.stop();
			delete selectedFeed.audioTrack;
		}
		if (selectedFeed.isPeerSpeakerActive) {
			selectedFeed.showDeviceControlsLockedModal = true;
			selectedFeed.isPeerSpeakerActive = false;
		}
		newVideoFeeds.splice(selectedFeedIndex, 0, selectedFeed);
		this.setState({ videoFeeds: newVideoFeeds });
	};

	setActiveSession = async session => {
		this.setState(prevState => ({
			monitoringSessions: prevState.monitoringSessions.map(item => ({ ...item, isActive: item.id === session.id })),
		}));
	};

	getMappedFeed = (deviceId, otherHsCompanyPathInfo = null) => {
		let sector = findSectorById(this.props.healthSystems.treeData.tree, deviceId);
		if (!sector && otherHsCompanyPathInfo) {
			sector = otherHsCompanyPathInfo;
		}
		if (!sector || this.state.videoFeeds.some(feed => feed.deviceId === deviceId)) {
			return null;
		}
		const {
			roomId,
			hospitalId,
			departmentId,
			floorId,
			name,
			aiPrivacyStatus,
			deviceFamily,
			roomName,
			hospitalName,
			departmentName,
			floorName,
			healthSystemId,
		} = sector;

		return {
			disabledTiltDirections: {},
			events: [],
			warning: {},
			peerConnectionState: RTCPeerConnectionEnum.CONNECTION_STATE.NEW,
			roomName: name || roomName,
			roomId,
			hospitalId,
			departmentId,
			floorId,
			hospitalName,
			departmentName,
			floorName,
			aiPrivacyStatus,
			src: null,
			status: ParticipantState.CONNECTING.type,
			zoomLevel: 0,
			cameraType: CameraType.HELLO,
			isPeerSpeakerActive: false,
			deviceOwner: { patientFormattedName: '', id: null, age: null },
			deviceFamily,
			lastCondition: '',
			deviceId,
			isCameraPrivacyOn: false,
			isMicPrivacyOn: false,
			isPttAvailable: true,
			isOnline: true,
			alertInfo: {},
			isPtzActive: true,
			ivBagPercentage: 0,
			healthSystemId: healthSystemId || this.props.user.userSession.healthSystem.id,
			feedType: this.state.feedType,
			initialFeedType: this.state.feedType,
			companyPathInfo: null,
			activeAlerts: [],
			isPatientDischarged: null,
		};
	};

	queueOrAddFeedOnStartConference = deviceId => {
		if (this.state.isStartConferenceInProgress) {
			this.feedsToInviteQueue.push(deviceId);
			return;
		}
		this.addFeed({ deviceId });
	};

	openSession = async (event, session) => {
		event.preventDefault();
		if (session.isActive) {
			return;
		}
		if (this.state.isSessionLoading) {
			return;
		}
		if (this.state.videoFeeds.length > 0) {
			this.closeCurrentSession();
			return;
		}
		this.setActiveSession(session);
		this.setState({ isSessionLoading: true });
		if (!session.id) {
			this.setState({ isSessionLoading: false });
			return;
		}
		const initialDevices = await getMonitoringSessionDevices(session.id, true);
		if (initialDevices.error) {
			this.setState({ error: initialDevices.error.message, isSessionLoading: false });
			return;
		}
		const devices = initialDevices.filter(item => item.hasAccess);
		const videoFeeds = devices.reduce((result, item) => {
			const feed = this.getMappedFeed(item.deviceId, item?.device?.companyPathInfo);
			if (item?.device?.companyPathInfo) {
				feed.companyPathInfo = item.device.companyPathInfo;
			}
			if (feed) {
				result.push(feed);
			}
			return result;
		}, []);
		this.isSession = true;
		this.setState(
			{ currentSessionId: session.id, videoFeeds, isSessionLoading: true, isStartConferenceInProgress: true, error: null },
			async () => {
				const data = await this.callManager.startMonitoring(await this.prepareStartConferenceInfo());
				if (data.response?.failureReason === enums.StartConferenceFailureReasons.GET_INITIATOR_INFO_FAILED) {
					const activeSession = this.state.monitoringSessions.find(s => s.id === this.state.currentSessionId);
					this.closeCurrentSession(true, activeSession);
					this.setState({ error: translate('failedToGetInitiatorInfo'), isSessionLoading: false });
					return;
				}
				this.handleCallStarted(data);

				devices.forEach(({ deviceId }) => {
					if (videoFeeds.length) {
						this.queueOrAddFeedOnStartConference(deviceId);
					}
				});
				this.setState({ isSessionLoading: false });
			}
		);
	};

	openSharedView = (event, sharedViewFromAnotherNurse) => {
		if (
			this.state.videoFeeds.length === 0 ||
			(this.state.currentSessionId && this.state.currentSessionId === sharedViewFromAnotherNurse?.id)
		) {
			this.openSession(event, sharedViewFromAnotherNurse);
			return;
		}
		this.setState({
			sessionToOpen: {
				event,
				session: sharedViewFromAnotherNurse,
			},
		});
	};

	toggleReOrder = session => {
		if (this.state.videoFeeds.length <= 1 || (session && !session.isActive)) {
			return;
		}
		this.setState(prevState => ({ isReorderFeedsVissible: !prevState.isReorderFeedsVissible }));
	};

	toggleTransfer = session => {
		if (this.state.videoFeeds.length < 1 || (session && !session.isActive)) {
			return;
		}
		this.setState(prevState => ({ isTransferSessionVisible: !prevState.isTransferSessionVisible }));
	};

	onSubmitTransferSession = async nurseId => {
		if (!nurseId) {
			return;
		}
		const sessionData = {
			name: 'Shared View',
			nurseId,
			sessionDevices: [],
		};

		this.state.videoFeeds.forEach((feed, index) => {
			sessionData.sessionDevices.push({ healthSystemId: feed.healthSystemId, id: +feed.deviceId, orderNo: index });
		});
		const response = await saveMonitoringSession(sessionData);
		if (response?.error) {
			this.setState({ error: response.error.message, sessionIsBeingSaved: false });
			return;
		}
		this.setState({
			isTransferSessionVisible: false,
		});
	};

	saveReorder = videoFeeds => {
		this.setState(
			prevState => ({ videoFeeds, isReorderFeedsVissible: !prevState.isReorderFeedsVissible }),
			() => {
				this.saveSession();
			}
		);
	};

	saveSession = session => {
		if (this.state.videoFeeds.length === 0 || (session && !session.isActive)) {
			return;
		}
		if (this.state.currentSessionId) {
			if (session?.userCreated && session?.userCreated?.id !== session?.nurseId) {
				this.toggleSessionModal(true);
			} else {
				this.updateSession(this.state.currentSessionId);
			}
		} else {
			this.toggleSessionModal(true);
		}
	};

	createNewSession = async name => {
		if (!name || (name && name.trim() === '')) {
			return;
		}
		if (this.state.sessionIsBeingSaved) return;
		this.setState({ sessionIsBeingSaved: true });
		const sessionData = {
			name: name,
			sessionDevices: [],
		};

		this.state.videoFeeds.forEach((feed, index) => {
			sessionData.sessionDevices.push({ healthSystemId: feed.healthSystemId, id: +feed.deviceId, orderNo: index });
		});
		const response = await saveMonitoringSession(sessionData);
		if (response?.error) {
			this.setState({ error: response.error.message, sessionIsBeingSaved: false });
			return;
		}
		this.toggleSessionModal(false);
		const sessions = await getMonitoringSessions();
		if (sessions.error) {
			this.setState({
				errorFetchingSessions: `${this.props.intl.formatMessage({ id: 'failedToLoad' })}`,
				sessionIsBeingSaved: false,
			});
			return;
		}
		const savedSession = sessions[sessions.length - 1];
		savedSession.isActive = true;
		const currentSession = { ...this.currentSession };
		currentSession.isActive = false;
		this.setState({
			monitoringSessions: [...sessions, currentSession],
			currentSessionId: savedSession.id,
			sessionIsBeingSaved: false,
		});

		const foundSession = this.state.monitoringSessions.find(item => item.id === this.state.currentSessionId);

		if (foundSession?.userCreated && foundSession?.userCreated?.id !== foundSession?.nurseId) {
			this.deleteSession(foundSession, true);
		}
	};

	updateSession = async sessionId => {
		const allSessions = this.state.videoFeeds.map((feed, index) => ({
			healthSystemId: feed.healthSystemId,
			id: +feed.deviceId,
			orderNo: index,
		}));
		const response = await updateMonitoringSessionDevices(sessionId, { sessionDevices: allSessions });
		if (!response.error) {
			this.showSessionUpdatedNotification();
		} else {
			this.setState({ error: response.error.message });
		}
	};

	showSessionUpdatedNotification = () => {
		this.setState(
			{
				isSessionNotificationVissible: true,
			},
			() => {
				setTimeout(() => {
					this.setState({
						isSessionNotificationVissible: false,
					});
				}, 2000);
			}
		);
	};

	closeFeedsAndUpdateTree = feeds => {
		feeds.forEach(({ deviceId }) => {
			this.removeFeed(deviceId, true);
		});
	};

	closeCurrentSession = async (activateCurrentSession, clickedSession) => {
		const { videoFeeds } = this.state;
		if ((videoFeeds.length === 0 || (clickedSession && !clickedSession.isActive)) && !this.state.currentSessionId) {
			return;
		}

		const feeds = [...videoFeeds];

		this.closeFeedsAndUpdateTree(feeds);
		this.props.healthSystemActions.updateMonitoringDevices({
			inCallDevices: [],
			initiatedDevices: [],
		});
		this.setState({
			currentSessionId: null,
			newSessionName: '',
			currentTab: 0,
		});
		if (activateCurrentSession) {
			const currentSession = this.state.monitoringSessions.find(session => session.isCurrent);
			this.setActiveSession(currentSession);
		}
		this.stopBioBeatMeasurements(_.cloneDeep(this.state.videoFeeds));
	};

	setSessionName = event => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	toggleSessionModal = isOpen => {
		this.setState({
			showSessionPopup: isOpen,
		});
	};

	toggleDeleteSessionModal = session => {
		if (session && (session.isCurrent || !session.isActive)) {
			return;
		}
		this.setState(prevState => ({
			showDeleteSessionModal: !prevState.showDeleteSessionModal,
			sessionToDelete: session,
		}));
	};

	deleteSession = async (session, isNewSessionBeingCreated = false) => {
		const deleteMoitoringSessionRes = await deleteMonitoringSession(session.id);
		if (deleteMoitoringSessionRes.error) {
			this.setState({ error: deleteMoitoringSessionRes.error.message });
			return;
		}
		const monitoringSessions = await getMonitoringSessions();
		if (monitoringSessions.error) {
			this.setState({ error: monitoringSessions.error.message });
			return;
		}

		const currentSession = { ...this.currentSession };

		const savedSession = monitoringSessions[monitoringSessions.length - 1];

		if (isNewSessionBeingCreated) {
			currentSession.isActive = false;
			savedSession.isActive = true;
		}

		this.setState({
			monitoringSessions: [...monitoringSessions, currentSession],
		});
		if (!isNewSessionBeingCreated && this.state.currentSessionId === session.id) {
			this.setState({ currentSessionId: null });
		}

		this.stopBioBeatMeasurements(_.cloneDeep(this.state.videoFeeds));

		if (!isNewSessionBeingCreated) {
			this.toggleDeleteSessionModal(null);
			this.closeCurrentSession(true);
		}
	};

	getWalkieTalkieSetting = async roomId => {
		const roomSettings = await getRoomSettings(roomId, SettingsCategory.MONITORING);
		if (!roomSettings.error) {
			return roomSettings.settings.find(config => config.settingTypeId === MonitoringSettings.WalkieTalkie)?.value === 'true';
		}
		return false;
	};

	getDiscontinueReasonSetting = async roomId => {
		const roomSettings = await getRoomSettings(roomId, SettingsCategory.MONITORING);
		if (!roomSettings.error) {
			return (
				roomSettings.settings.find(config => config.settingTypeId === MonitoringSettings.DiscontinueMonitoring)?.value === 'true'
			);
		}
		return false;
	};

	onAddDevice = async selection => {
		const maxNumberOfFeeds = 32;
		const { floorId, helloDeviceId } = selection;
		const selectedFeed = this.state.videoFeeds.find(item => item.deviceId === helloDeviceId);
		if (selectedFeed) {
			return;
		}
		if (this.state.videoFeeds.length === maxNumberOfFeeds) {
			return;
		}

		if (this.state.videoFeeds.length === 0 && !this.state.isStartConferenceInProgress) {
			this.setState({
				isStartConferenceInProgress: true,
				error: null,
			});
			const data = await this.callManager.startMonitoring(await this.prepareStartConferenceInfo());
			if (data.response?.failureReason === enums.StartConferenceFailureReasons.GET_INITIATOR_INFO_FAILED) {
				const activeSession = this.state.monitoringSessions.find(s => s.id === this.state.currentSessionId);
				this.closeCurrentSession(true, activeSession);
				this.setState({ error: translate('failedToGetInitiatorInfo'), isPttJoining: false });
				return;
			}
			this.handleCallStarted(data);

			if (!data.conferenceStarted) {
				return;
			}
		}

		const isPttConfigOn = await this.getWalkieTalkieSetting(selection.roomId);

		if (
			isPttConfigOn &&
			this.state.videoFeeds.length === 0 &&
			this.state.feedType === this.FeedTypes.Monitoring &&
			!this.state.isPttJoining &&
			!this.state.hasPttJoined
		) {
			this.setState({
				isPttJoining: true,
			});
			this.ptt
				.join(floorId, this.getSelectedFloor(helloDeviceId)?.conversationId)
				.then(() => {
					this.setState({ isPttJoining: false, hasPttJoined: true });
				})
				.catch(err => {
					// eslint-disable-next-line
					console.warn('Unable to join ptt on feed:', err);
					this.setState(prevState => {
						const feeds = _.cloneDeep(prevState.videoFeeds);
						if (feeds.length > 0) {
							feeds[0].isPttAvailable = false;
						}
						return { ...prevState, videoFeeds: feeds, isPttJoining: false, hasPttJoined: false };
					});
				});
		}

		const feed = this.getMappedFeed(helloDeviceId);
		if (feed) {
			this.setState(
				prevState => ({
					videoFeeds: [...prevState.videoFeeds, feed],
				}),
				() => {
					this.queueOrAddFeedOnStartConference(helloDeviceId);
				}
			);
		}
	};

	onFallDetected = async (showFall, feed) => {
		const { conferenceInfo } = this.state;
		if (!conferenceInfo) {
			return;
		}

		const data = {
			isFallDetected: showFall,
			helloDeviceId: feed.deviceId,
			conferenceId: conferenceInfo.conferenceId,
			participantId: feed.participantId,
			patientId: null,
			addToHealthMeasurements: false,
		};
		this.callManager.sendFallDetected(data);
	};

	sendCameraEvent = async (eventType, data, feed) => {
		let response;
		if (!data) {
			return null;
		}
		switch (eventType) {
			case SocketEvents.Conference.ON_MEDIA_CONTROLS:
				response = await this.callManager.sendMediaControlsEvent(data);
				break;
			case SocketEvents.HelloDevice.MOVE_CAMERA:
				response = await this.doSendPanTiltCameraEvent(data);
				break;
			case SocketEvents.HelloDevice.COMMAND:
				response = await this.callManager.rebootHuddleCam(data);
				break;
			default:
				response = await this.callManager.sendCameraEvent(eventType, data);
		}

		if (response.deviceControlsLocked) {
			this.setState(prevState => {
				return {
					...prevState,
					videoFeeds: prevState.videoFeeds.map(item => {
						const newFeed = { ...item };
						if (feed.deviceId === newFeed.deviceId) {
							newFeed.showDeviceControlsLockedModal = true;
						}
						return newFeed;
					}),
				};
			});
		}
		return response;
	};

	doSendPanTiltCameraEvent = async ({ direction, action }) => {
		const { videoFeeds, conferenceInfo } = this.state;
		const expandedFeed = videoFeeds.length === 1 ? videoFeeds[0] : videoFeeds.find(feed => feed.isFeedExpanded);
		if (!expandedFeed) {
			return {};
		}
		const { conferenceId } = conferenceInfo;
		const { participantId, deviceId } = expandedFeed;
		return this.callManager.panTiltCamera({ direction, helloDeviceId: deviceId, action, conferenceId, participantId });
	};

	cameraResponseListener = ({ event, message, isSuccessful, objectId }) => {
		const { videoFeeds } = this.state;
		const selectedFeedIndex = videoFeeds.findIndex(item => item.deviceId === objectId);
		if (selectedFeedIndex === -1) {
			return;
		}
		const [selectedFeed] = videoFeeds.splice(selectedFeedIndex, 1);
		switch (event) {
			case CameraEventTypes.SWITCH:
				if (isSuccessful) {
					selectedFeed.cameraType = message;
					selectedFeed.zoomLevel = 0;
				}
				break;
			case CameraEventTypes.ZOOM:
				selectedFeed.zoomLevel = +message;
				break;
			case CameraEventTypes.TILT: {
				const { disabledTiltDirections } = selectedFeed;
				if (isSuccessful) {
					if ([CameraTiltDirection.UP, CameraTiltDirection.DOWN].includes(message)) {
						disabledTiltDirections[CameraTiltDirection.UP] = false;
						disabledTiltDirections[CameraTiltDirection.DOWN] = false;
					} else {
						disabledTiltDirections[CameraTiltDirection.LEFT] = false;
						disabledTiltDirections[CameraTiltDirection.RIGHT] = false;
					}
				} else {
					disabledTiltDirections[message] = true;
					this.doSendPanTiltCameraEvent({ direction: message, action: CameraTiltAction.STOP });
				}
				selectedFeed.disabledTiltDirections = disabledTiltDirections;
				break;
			}
			case CameraEventTypes.NIGHT_VISION:
				selectedFeed.isNightVisionActive = isSuccessful;
				break;
			case CameraEventTypes.CAMERA_PRIVACY_STATE: {
				selectedFeed.isCameraPrivacyOn = isSuccessful;
				break;
			}
			case CameraEventTypes.HELLO_MIC_PRIVACY_STATE: {
				selectedFeed.isMicPrivacyOn = isSuccessful;
				break;
			}
			default:
		}
		videoFeeds.splice(selectedFeedIndex, 0, selectedFeed);
		this.setState({ videoFeeds });
		if (!isSuccessful && message) {
			selectedFeed.cameraMessage = message;
		}
	};

	setActiveDevice = (deviceId, cameraType) => {
		const { videoFeeds } = this.state;

		if (!videoFeeds.some(x => x.deviceId === deviceId)) {
			return;
		}

		const feeds = videoFeeds.map(item => {
			if (item.deviceId === deviceId) {
				const newItem = { ...item };
				newItem.cameraType = cameraType;
				newItem.zoomLevel = 0;
				return newItem;
			}
			return item;
		});

		this.setState({
			videoFeeds: feeds,
		});
	};

	getConferenceEndedText = conferenceEndReason => {
		if (conferenceEndReason === ConferenceEndReason.DROPPED) {
			return translate('failedToReconnect');
		}

		if (conferenceEndReason === ConferenceEndReason.TERMINATED_BY_ADMINISTRATOR) {
			return translate('sessionEndedByAdmin');
		}

		if (conferenceEndReason === ConferenceEndReason.CLEANUP) {
			return translate('sessionMaintenance');
		}
		if (conferenceEndReason === ConferenceEndReason.OWNER_LEFT) {
			return translate('sessionDisrupted');
		}

		if (conferenceEndReason === ConferenceEndReason.UNDEFINED) {
			return translate('somethingWentWrong');
		}

		return undefined;
	};

	getVideoFeedIndexByParticipantId = (participantId, videoFeeds) => {
		const participant = this.participants[participantId];
		if (!participant) {
			// eslint-disable-next-line no-console
			console.warn('Participant not found for remote ID:', participantId);
			return -1;
		}

		const { objectId } = participant;
		if (!objectId) {
			// eslint-disable-next-line no-console
			console.warn('objectId not set for participant!');
			return -1;
		}

		return videoFeeds.findIndex(item => item.deviceId === objectId);
	};

	onAudioTrackToggled = data => {
		if (!data) {
			return;
		}

		const { participantId, isAudio } = data;
		const { videoFeeds } = this.state;

		const selectedFeedIndex = this.getVideoFeedIndexByParticipantId(participantId, videoFeeds);
		if (selectedFeedIndex === -1) {
			return;
		}

		const [feed] = videoFeeds.splice(selectedFeedIndex, 1);

		feed.isPeerSpeakerActive = isAudio;

		videoFeeds.splice(selectedFeedIndex, 0, feed);

		this.setState({ videoFeeds });
	};

	onLocalAudioError = ({ participantId, trackDeviceNotFound, inputDevices }) => {
		const { videoFeeds } = this.state;

		const selectedFeedIndex = this.getVideoFeedIndexByParticipantId(participantId, videoFeeds);
		if (selectedFeedIndex === -1) {
			return;
		}

		const [feed] = videoFeeds.splice(selectedFeedIndex, 1);

		if (feed.audioTrack.enabled && (!trackDeviceNotFound || !inputDevices.length)) {
			this.props.healthSystemActions.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.MICROPHONE_NOT_FOUND.type,
			});
		}

		feed.isMyMicActive = false;
		delete feed.audioTrack;

		videoFeeds.splice(selectedFeedIndex, 0, feed);

		this.setState({ videoFeeds });
	};

	onToggleCameraSwitch = feed => {
		const { participantId, deviceId, cameraType, isCameraPrivacyOn } = feed;
		const { conferenceId } = this.state.conferenceInfo;

		const notAllowedToSwitchToHelloCam = cameraType === CameraType.HUDDLE && isCameraPrivacyOn;
		if (notAllowedToSwitchToHelloCam) {
			this.setState({ shouldShowSwitchToHelloCamError: true });
			return;
		}

		this.sendCameraEvent(SocketEvents.HelloDevice.SWITCH_CAMERA, { participantId, conferenceId, helloDeviceId: deviceId }, feed);
	};

	toggleVitalSigns = feed => {
		let { videoFeeds } = this.state;
		videoFeeds = videoFeeds.map(videoFeed => ({ ...videoFeed, isVitalSignsVisible: false, isEhrIntegrationVisible: false }));
		const selectedFeed = videoFeeds.find(item => feed.deviceId === item.deviceId);
		selectedFeed.isVitalSignsVisible = !feed.isVitalSignsVisible;
		selectedFeed.isEhrIntegrationVisible = !feed.isEhrIntegrationVisible;
		selectedFeed.isAlertTimelineVisible = false;
		if (feed?.deviceId !== this.state.selectedFeed?.deviceId) {
			selectedFeed.isLifeSignalsPopupOpen = false;
		}
		this.setState({
			videoFeeds,
			selectedFeed,
		});
	};

	toggleBioBeat = async (deviceId, status, wearableId = '') => {
		const { videoFeeds } = this.state;
		const newVideoFeeds = _.cloneDeep(videoFeeds);
		const selectedFeedIndex = newVideoFeeds.findIndex(item => item.deviceId === deviceId);
		const [selectedFeed] = newVideoFeeds.splice(selectedFeedIndex, 1);

		const sendData = [
			{
				settingTypeId: PatientAiSetting.VITAL_SIGNS_AI,
				isEnabled: false,
				workflowType: CallWorkflowType.MONITORING,
			},
		];

		const params = {
			patientId: selectedFeed.deviceOwner?.healthcareUserId,
			deviceId,
			roomId: selectedFeed.roomId,
			sendData,
		};

		const [bioBeatResponse, aiSettingsResponse] = await Promise.all([
			setBioBeatDeviceState({
				taskId: status ? null : selectedFeed.bioBeatTaskId,
				deviceId: wearableId,
				state: status ? this.bioBeatDeviceFeedState.ON : this.bioBeatDeviceFeedState.OFF,
				patientUserId: selectedFeed.deviceOwner.userId,
				patientUserGuid: selectedFeed.deviceOwner.healthcareUserId,
			}),
			updatePatientAiSettings(params),
		]);

		if (aiSettingsResponse.error) {
			this.setState({
				error: aiSettingsResponse.error.message,
			});
		}

		if (bioBeatResponse.error) {
			this.setState({
				error: bioBeatResponse.error.message,
			});
		}

		if (!aiSettingsResponse.error) {
			const aiSettingsList = _.cloneDeep(this.props.aiSettings);
			const found = aiSettingsList.find(item => item.deviceId === deviceId);
			this.props.aiSettingsActions.setPatientAiSettings({
				settings: [
					...found.settings.filter(item => item.settingTypeId !== PatientAiSetting.VITAL_SIGNS_AI),
					{ settingTypeId: PatientAiSetting.VITAL_SIGNS_AI, value: 'false' },
				],
				deviceId,
			});
		}

		if (!bioBeatResponse.error) {
			selectedFeed.isBioBeatActive = status;

			selectedFeed.bioBeatTaskId = bioBeatResponse.taskId;

			this.updateLocalBioBeatItems(deviceId, selectedFeed);

			newVideoFeeds.splice(selectedFeedIndex, 0, selectedFeed);
			this.setState({ videoFeeds: newVideoFeeds, selectedFeed });
		}
	};

	toggleLifeSignals = async (deviceId, status) => {
		const videoFeeds = [...this.state.videoFeeds];
		const { selectedFeed } = this.state;
		let selectedObj = { ...selectedFeed };

		const newVideoFeeds = videoFeeds.map(item => {
			if (item.deviceId === deviceId) {
				const newFeed = { ...item };
				newFeed.isLifeSignalsActive = status;
				newFeed.isMindrayActive = false;
				selectedObj = newFeed;
				return newFeed;
			}
			return item;
		});

		this.context.emit(SocketEvents.Conference.LIFE_SIGNALS_TOGGLE, {
			toggle: status,
			conferenceId: this.state.conferenceInfo?.conferenceId,
			participantId: this.state.conferenceInfo?.participantId,
			actioneeParticipantId: selectedObj.participantId,
			patientId: selectedObj.deviceOwner?.userId,
		});
		this.setState({ videoFeeds: newVideoFeeds, selectedFeed: selectedObj });
	};

	toggleMindray = (deviceId, status) => {
		const videoFeeds = [...this.state.videoFeeds];
		const { selectedFeed } = this.state;
		let selectedObj = { ...selectedFeed };

		const newVideoFeeds = videoFeeds.map(item => {
			if (item.deviceId === deviceId) {
				const newFeed = { ...item };
				newFeed.isMindrayActive = status;
				newFeed.isLifeSignalsActive = false;
				selectedObj = newFeed;
				return newFeed;
			}
			return item;
		});
		this.setState({ videoFeeds: newVideoFeeds, selectedFeed: selectedObj });
	};

	forwardAiAlert = async (feed, alertId) => {
		const { hospital, department, floor, room } = this.getHierarchyNaming(feed.deviceId);
		const dataToSend = {
			conversationId: this.getSelectedFloor(feed.deviceId)?.conversationId,
			alertId,
			hospital,
			department,
			floor,
			room,
		};
		const response = await forwardAiAlert(dataToSend);
		if (response.error) {
			this.setState({ error: response.error.message });
		}
	};

	onAddedManualActivity = result => {
		const videoFeeds = [...this.state.videoFeeds];
		const currentFeed = videoFeeds.find(feed => result.deviceId === feed.deviceId);
		if (!currentFeed) {
			return;
		}
		const nurseStatAlarmGotThisId = 3;
		if (
			(currentFeed?.isStatAlarmActive || currentFeed?.voiceOverAlert) &&
			result.manualAlertActivityTypeId === nurseStatAlarmGotThisId &&
			[ManualAlertTypes.START_STAT_ALARM, ManualAlertTypes.START_VOICE_OVER].includes(result.manualAlertTypeId)
		) {
			const newVideoFeeds = videoFeeds.map(item => {
				if (item.deviceId === result.deviceId) {
					const newItem = { ...item };

					if (result.manualAlertTypeId === ManualAlertTypes.START_STAT_ALARM) {
						newItem.statAlarmConfirmation = translate('stoppedAlarmPopup', {
							value: result.causerFullName,
							role: this.props.customDisplayNames.nurseDisplayName,
						});
					}
					return newItem;
				}
				return item;
			});
			if (result.manualAlertTypeId === ManualAlertTypes.START_STAT_ALARM) {
				this.toggleStatAlarm(currentFeed);
			}
			this.setState({
				videoFeeds: newVideoFeeds,
			});
		}
	};

	toggleStatAlarm = (feed, event = null) => {
		if (event) {
			event.stopPropagation();
		}

		if (!feed) {
			return;
		}
		const { conferenceInfo } = this.state;
		this.context.emit(SocketEvents.Conference.ALERT_PATIENT_AT_RISK, {
			conferenceId: conferenceInfo.conferenceId,
			participantId: conferenceInfo.participantId,
			actioneeParticipantId: feed.participantId,
			data: !feed.isStatAlarmActive,
			shouldForward: true,
		});

		if (event) {
			const preSelected = { ...this.state.selectedFeed };

			if (feed?.deviceId !== preSelected?.deviceId && !feed?.isPeerSpeakerActive) {
				this.togglePeerSpeaker(feed, null, true);
			}
			this.toggleOffOtherMicrophones(feed);

			this.setState({
				selectedFeed: feed,
				isTimelineCollapsed: false,
			});
		}
	};

	shouldAllowForwardStatAlarmToNurses = (currentFeed, result) =>
		!currentFeed.isDefaultOwner &&
		result.shouldForward &&
		result?.actioneeParticipantId === this.state.conferenceInfo?.participantId &&
		(result.data || (!result.data && !currentFeed.statAlarmConfirmation));

	onAlertPatientResponse = result => {
		const videoFeeds = [...this.state.videoFeeds];
		const currentFeed = videoFeeds.find(feed => result.deviceId === feed.deviceId);

		if (!currentFeed) {
			this.finishStatAlarmProcessing();
			return;
		}

		if (!result.isSuccessful) {
			this.setState(
				{
					statAlarmError: translate('errorStatAlarm', {
						value: currentFeed.roomName,
					}),
				},
				() => {
					this.finishStatAlarmProcessing();
				}
			);
			return;
		}

		const newVideoFeeds = videoFeeds.map(item => {
			if (item.deviceId === result.deviceId) {
				const newItem = { ...item };
				newItem.isStatAlarmActive = result.data;
				return newItem;
			}
			return item;
		});

		const statusId = result.data ? ManualAlertTypes.START_STAT_ALARM : ManualAlertTypes.STOP_STAT_ALARM;
		if (this.shouldAllowForwardStatAlarmToNurses(currentFeed, result)) {
			this.forwardStatAlarmStatusToNurses(currentFeed, statusId, '');
		}
		this.setState(
			{
				statAlarmError: null,
				...(result.data && { statAlarmConfirmation: '' }),
				videoFeeds: newVideoFeeds,
			},
			() => {
				this.finishStatAlarmProcessing();
			}
		);
	};

	forwardStatAlarmStatusToNurses = async (feed, manualAlertTypeId, comment) => {
		let patientId = feed.deviceOwner?.healthcareUserId;
		if (!feed.isDefaultOwner && !feed.deviceOwner?.healthcareUserId) {
			const deviceOwnerResponse = await getDeviceOwnerPatient(feed.deviceId);
			if (deviceOwnerResponse.error) {
				this.setState({ error: deviceOwnerResponse.error.message });
				return;
			}
			patientId = deviceOwnerResponse?.healthcareUserId;
		}
		if (patientId) {
			const dataToSend = {
				patientId,
				deviceId: feed.deviceId,
				manualAlertTypeId,
				comment,
				isDraft: manualAlertTypeId === ManualAlertTypes.START_STAT_ALARM,
				conferenceId: this.state.conferenceInfo?.conferenceId,
			};
			const response = await saveManualAlert(dataToSend);
			if (response.error) {
				this.setState({ error: response.error.message });
			}
		}
	};

	closeStatAlarmConfirmation = feed => {
		const videoFeeds = [...this.state.videoFeeds];

		const newVideoFeeds = videoFeeds.map(item => {
			if (item.deviceId === feed.deviceId) {
				const newItem = { ...item };
				newItem.statAlarmConfirmation = null;
				return newItem;
			}
			return item;
		});
		this.setState({
			videoFeeds: newVideoFeeds,
		});
	};

	updateLocalBioBeatItems = (deviceId, selectedFeed) => {
		const getStorageBioBeat = getStorage().activeBioBeatItems ? JSON.parse(getStorage().activeBioBeatItems) : [];
		const foundIndex = getStorageBioBeat.findIndex(item => item.deviceId === selectedFeed.deviceId);

		if (foundIndex !== -1) {
			getStorageBioBeat.splice(foundIndex, 1);
		} else {
			getStorageBioBeat.push({
				deviceId,
				taskId: selectedFeed.bioBeatTaskId,
				patientUserId: selectedFeed.deviceOwner.userId,
				patientUserGuid: selectedFeed.deviceOwner.healthcareUserId,
			});
		}

		getStorage().setItem('activeBioBeatItems', JSON.stringify(getStorageBioBeat));
	};

	onHandleBioBeatData = result => {
		const videoFeeds = _.cloneDeep(this.state.videoFeeds);

		const deviceId = this.bioBeatConfig.find(item => +result.data.device_id === item.biobeatId)?.deviceId;
		const selectedFeedIndex = videoFeeds.findIndex(item => item.deviceId === deviceId);
		if (selectedFeedIndex !== -1) {
			const [selectedFeed] = videoFeeds.splice(selectedFeedIndex, 1);
			if (selectedFeed?.isBioBeatActive && selectedFeed?.bioBeatTaskId === result.taskId) {
				const newObject = {
					rr: null,
					spo2: null,
					hr: null,
					hrv: null,
					sbp: null,
					dbp: null,
					sv: null,
					svr: null,
					co: null,
					ci: null,
					temp: null,
				};

				Object.keys(newObject).forEach(key => {
					if (result?.data[key]) {
						newObject[key] = result?.data[key];
					} else {
						newObject[key] = selectedFeed?.bioBeatmesurements?.[key] || null;
					}
				});

				selectedFeed.bioBeatmesurements = newObject;
				selectedFeed.bioBeatLastMeasurement = result?.data.datetime;
				videoFeeds.splice(selectedFeedIndex, 0, selectedFeed);
				this.setState({ videoFeeds });
			}
		}
	};

	handleDevicesList = result => {
		let selectedFeed = { ...this.state.selectedFeed };
		if (result.command === enums.MediaControlsCommands.DEVICES_LIST) {
			const { videoFeeds } = this.state;
			const newVideoFeeds = videoFeeds.map(item => {
				if (item.participantId === result.participantId) {
					const newItem = { ...item };
					newItem.mediaDevices = result.data;
					if (item.deviceId === selectedFeed.deviceId) {
						selectedFeed = newItem;
					}
					return newItem;
				}
				return item;
			});
			this.setState({ videoFeeds: newVideoFeeds, selectedFeed });
		}
	};

	onTogglePtz = (feed, event) => {
		if (event) {
			event.stopPropagation();
		}
		const { videoFeeds } = this.state;
		const newFeeds = [...videoFeeds].map(item => {
			const newFeed = { ...item };
			if (item.deviceId === feed.deviceId) {
				newFeed.isPtzActive = !feed.isPtzActive;
				newFeed.isVitalSignsVisible = false;
			}
			return newFeed;
		});

		this.setState({ videoFeeds: newFeeds });
	};

	toggleFallPrevention = (status, deviceId) => {
		const { videoFeeds, selectedFeed } = this.state;
		const newFeeds = [...videoFeeds].map(item => ({
			...item,
			isFallPrevention: item.deviceId === deviceId ? status : item.isFallPrevention,
		}));
		this.setState({
			videoFeeds: newFeeds,
			selectedFeed: { ...selectedFeed, isFallPrevention: status },
		});
	};

	onToggleEmergencyButtons = feed => {
		const { videoFeeds } = this.state;
		const selectedFeedIndex = this.state.videoFeeds.findIndex(item => item.deviceId === feed.deviceId);
		const [selectedFeed] = videoFeeds.splice(selectedFeedIndex, 1);
		selectedFeed.areEmergencyButtonsHiden = !feed.areEmergencyButtonsHiden;
		videoFeeds.splice(selectedFeedIndex, 0, selectedFeed);
		this.setState({ videoFeeds });
	};

	onCallNurses = async (feed, user = null) => {
		if (this.state.isObserverConferenceModalOpen) {
			return;
		}

		let checkedInNurses = [];

		if (!user) {
			const { users, error } = await getCheckedInNurses(feed.floorId);
			if (error) {
				// eslint-disable-next-line no-console
				console.warn('Failed to get checked-in nurses!', error);
			} else {
				checkedInNurses = users;
				await outGoingCallSound();
			}
		} else {
			checkedInNurses.push(user);
		}

		this.setState({
			isObserverConferenceModalOpen: true,
			observerConferenceData: {
				conferenceName: 'Frontline',
				callType: CallTypes.FIRST_RESPONDER,
				participants: checkedInNurses.map(nurse => ({ objectId: nurse.userIntId, objectType: 0, ...nurse })),
				roomId: feed.roomId,
			},
		});
	};

	onObserverConferenceStarted = () => {
		this.setState({ hasObserverConferenceStarted: true });

		// Mute all video feeds when observer is talking to nurse
		const { videoFeeds } = this.state;

		videoFeeds.forEach(feed => {
			if (feed.isMyMicActive) {
				this.toggleMyMicrophone(feed);
			}

			if (feed.isPeerSpeakerActive) {
				this.togglePeerSpeaker(feed);
			}
		});
	};

	toggleOffOtherMicrophones = feed => {
		// Mute all video feeds when a feed is selected
		const { videoFeeds } = this.state;

		videoFeeds.forEach(item => {
			if (item.deviceId !== feed?.deviceId && item.isMyMicActive) {
				this.toggleMyMicrophone(item);
			}
		});
	};

	onObserverConferenceEnded = () =>
		this.setState({
			isObserverConferenceModalOpen: false,
			hasObserverConferenceStarted: false,
			observerConferenceData: {
				participants: [],
				conferenceName: '',
				callType: null,
				roomId: null,
			},
			incomingConferenceInfo: null,
		});

	getAlertMessageContent = (objectContent, isEvent) => {
		let content = '';
		if (!isEvent && objectContent.attachments.length > 0) {
			content = 'Shared a media.js';
		}
		if (!isEvent && objectContent.attachments.length === 0) {
			content = objectContent.content;
		}
		if (isEvent) {
			content = '';
		}
		return content;
	};

	getSelectedFloor = deviceId => {
		const { channels } = this.state;
		const newTree = [...this.props.healthSystems.treeData.tree];
		const device = findDeviceById(newTree, deviceId);
		return channels.find(channel => channel.floorId === device?.floorId);
	};

	getSelectedChannel = floorId => {
		const { channels } = this.state;
		return channels.find(channel => channel.floorId === floorId);
	};

	toggleAlerts = feed => {
		const videoFeeds = [...this.state.videoFeeds];
		let selectedFeed;
		let currentTab = 0;

		videoFeeds.forEach(item => {
			if (item.deviceId === feed.deviceId) {
				selectedFeed = item;
				selectedFeed.isAlertTimelineVisible = !feed.isAlertTimelineVisible;
				selectedFeed.isEhrIntegrationVisible = false;
				selectedFeed.isVitalSignsVisible = false;
				if (feed?.deviceId !== this.state.selectedFeed?.deviceId) {
					selectedFeed.isLifeSignalsPopupOpen = false;
				}
			} else {
				// eslint-disable-next-line no-param-reassign
				item.isAlertTimelineVisible = false;
			}
		});
		this.setState({
			videoFeeds,
			selectedFeed,
			currentTab,
			isTimelineCollapsed: false,
		});
	};

	onAiSettingClick = (status, feed, dataAcquisition) => {
		const { videoFeeds } = this.state;
		const selectedFeedIndex = this.state.videoFeeds.findIndex(item => item.deviceId === feed?.deviceId);
		if (selectedFeedIndex === -1) {
			return;
		}
		const [selectedFeed] = videoFeeds.splice(selectedFeedIndex, 1);
		selectedFeed.isAiDrawingsActive = status;
		selectedFeed.dataAcquisition = dataAcquisition;
		videoFeeds.splice(selectedFeedIndex, 0, selectedFeed);
		this.setState({ videoFeeds });
	};

	callHelloPatient = async ({ deviceId, roomName: conferenceName, roomId }) => {
		if (this.state.isObserverConferenceModalOpen) {
			return;
		}
		if (this.micStatus.state === MediaPermissions.DENIED) {
			this.props.healthSystemActions.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.MICROPHONE_BLOCKED.type,
				message: 'allowMicPermissions',
			});
			return;
		}

		this.setState(
			{
				isObserverConferenceModalOpen: true,
				observerConferenceData: {
					conferenceName,
					callType: CallTypes.VIDEO,
					participants: [{ objectId: deviceId, objectType: ObjectType.HELLO_DEVICE }],
					roomId,
				},
			},
			() => {
				outGoingCallSound();
			}
		);
	};

	onAcceptIncomingCall = incomingConferenceInfo => {
		if (this.state.isObserverConferenceModalOpen) {
			return;
		}

		this.setState({ isObserverConferenceModalOpen: true, incomingConferenceInfo });
	};

	endEmergencyCall = () => {
		this.setState({ isEmergencyCallOpen: false });
	};

	toggleConversation = () => {
		const unReadMessages = [...this.state.unReadMessages];
		let selectedChannel = this.state.selectedChannel ? { ...this.state.selectedChannel } : this.state.channels[0];
		if (unReadMessages.length > 0) {
			const removedElement = unReadMessages.shift();
			selectedChannel = this.state.channels.find(channel => channel.id === removedElement);
		}
		this.setState(prevState => ({
			selectedChannel,
			unReadMessages: prevState.unReadMessages.filter(channelId => channelId !== selectedChannel.id),
		}));
	};

	addIdToInitiatedDevice = deviceId => {
		const { initiatedDevices } = this.props.healthSystems.monitoredDevices;
		this.props.healthSystemActions.updateMonitoringDevices({
			...this.props.healthSystems.monitoredDevices,
			initiatedDevices: [...initiatedDevices, deviceId],
		});
	};

	addIdToInCallDevicesDevice = deviceId => {
		const { inCallDevices } = this.props.healthSystems.monitoredDevices;
		this.props.healthSystemActions.updateMonitoringDevices({
			...this.props.healthSystems.monitoredDevices,
			inCallDevices: [...inCallDevices, deviceId],
		});
	};

	removeIdFromMonitoringDevices = deviceId => {
		const { initiatedDevices, inCallDevices } = this.props.healthSystems.monitoredDevices;
		this.props.healthSystemActions.updateMonitoringDevices({
			inCallDevices: inCallDevices.filter(item => item !== deviceId),
			initiatedDevices: initiatedDevices.filter(item => item !== deviceId),
		});
	};

	toggleRTPSgridView = helloDeviceId => {
		const { videoFeeds } = this.state;
		const selectedFeedIndex = videoFeeds.findIndex(item => item.deviceId === helloDeviceId);
		if (selectedFeedIndex === -1) {
			return;
		}
		const [expandedFeed] = videoFeeds.splice(selectedFeedIndex, 1);
		expandedFeed.isGridView = !expandedFeed.isGridView;
		videoFeeds.splice(selectedFeedIndex, 0, expandedFeed);
		this.setState({ videoFeeds }, () => {
			this.context.emit(SocketEvents.HelloDevice.RTSP_CAMERA_EVENT, {
				helloDeviceId,
				coordinates: { x: 1, y: 1 },
				objectType: ObjectType.HELLO_DEVICE,
				isGridView: expandedFeed.isGridView,
			});
		});
	};

	deviceStatusChanged = async ({ objectId }) => {
		if (!this.state.videoFeeds.some(feed => feed.deviceId === objectId)) {
			return;
		}

		const getVideoFeeds = prevVideoFeeds =>
			prevVideoFeeds.map(videoFeed => {
				let feed = videoFeed;

				if (feed.deviceId === objectId) {
					feed = { ...feed };
					if (feed.audioTrack) {
						feed.audioTrack.stop();
						feed.audioTrack = null;
					}
					feed.isPeerSpeakerActive = false;
					feed.isMyMicActive = false;
					feed.status = ParticipantState.RECONNECTING.type;
				}

				return feed;
			});

		this.setState(prevState => ({ videoFeeds: getVideoFeeds(prevState.videoFeeds) }));
	};

	setIvBagPercentage = (deviceId, value) => {
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];
		const selectedFeed = newVideoFeeds.find(item => item.deviceId === deviceId);
		if (!selectedFeed) {
			return;
		}
		selectedFeed.ivBagPercentage = value;
		this.setState({ videoFeeds: newVideoFeeds });
	};

	shouldShowPTZ = feed =>
		(feed.isFeedExpanded || (this.state.videoFeeds.length === 1 && this.state.videoFeeds[0].src)) &&
		!this.state.isVitalSignsVisible &&
		this.state.conferenceInfo &&
		feed.peerConnectionState === RTCPeerConnectionEnum.CONNECTION_STATE.CONNECTED &&
		feed.status === ParticipantState.CONNECTED.type &&
		feed.isPtzActive;

	onAnalyticsToastMessageClose = deviceId => {
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];
		const selectedFeed = newVideoFeeds.find(item => item.deviceId === deviceId);
		if (!selectedFeed) {
			return;
		}
		selectedFeed.analyticsData = {};
		this.setState({ videoFeeds: newVideoFeeds });
		clearTimeout(this.analyticsDataTimeout);
	};

	onAlertInfoClose = (deviceId, isToastMessage = false) => {
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];
		const selectedFeed = newVideoFeeds.find(item => item.deviceId === deviceId);
		if (!selectedFeed) {
			return;
		}
		if (isToastMessage) {
			selectedFeed.aiToastDetails = {};
			selectedFeed.activeAlerts = selectedFeed.activeAlerts?.filter(alert => !alert.isToastDetails);
		} else {
			selectedFeed.alertInfo = {};
			clearTimeout(this.alertInfoTimeout);
		}
		this.setState(
			this.setState(prevState => ({
				videoFeeds: newVideoFeeds,
				selectedFeed: prevState.selectedFeed?.deviceId === selectedFeed.deviceId ? selectedFeed : prevState.selectedFeed,
			}))
		);
	};

	shouldShowAlertTimeline = () =>
		this.state.selectedFeed &&
		this.state.selectedFeed.isAlertTimelineVisible &&
		this.state.conferenceInfo &&
		!this.state.selectedFeed.isDefaultOwner;

	shouldShowLifeSignalsPopup = () =>
		this.state.selectedFeed &&
		this.state.selectedFeed.isLifeSignalsPopupOpen &&
		this.state.conferenceInfo &&
		!this.state.selectedFeed.isDefaultOwner;

	showSessions = () => {
		const adminConfigurableMenu = getConfigurationMenu(this.props.configurations.adminConfigurableMenu, getUserRole());
		const configurableMenu = getConfigurationMenu(this.props.configurations.configurableMenu, getUserRole());
		return (
			adminConfigurableMenu[GeneralSettings.MonitoringSessions]?.value &&
			configurableMenu[GeneralSettings.MonitoringSessions]?.value
		);
	};

	toggleLifeSignalsFrame = (feed, e) => {
		if (e) {
			e.stopPropagation();
		}
		const videoFeeds = [...this.state.videoFeeds];
		let selectedFeed;
		const newFeeds = videoFeeds.map(item => {
			const newFeed = { ...item };
			if (item.deviceId === feed.deviceId) {
				newFeed.isAlertTimelineVisible = false;
				newFeed.isEhrIntegrationVisible = false;
				newFeed.isVitalSignsVisible = false;
				newFeed.isLifeSignalsPopupOpen = !newFeed.isLifeSignalsPopupOpen;
				selectedFeed = { ...newFeed };
			} else {
				// eslint-disable-next-line no-param-reassign
				newFeed.isLifeSignalsPopupOpen = false;
			}
			return newFeed;
		});

		this.setState({
			videoFeeds: newFeeds,
			selectedFeed,
			isTimelineCollapsed: false,
		});
	};

	shouldShowAlerts = () =>
		this.state.selectedFeed &&
		(this.state.selectedFeed.isAlertTimelineVisible ||
			this.state.selectedFeed.isEhrIntegrationVisible ||
			this.state.selectedFeed.isLifeSignalsPopupOpen) &&
		!this.state.selectedFeed.isDefaultOwner;

	onUnassignExternalDevice = data => {
		if (data.patientUserId === this.state.selectedFeed.deviceOwner?.userId) {
			this.toggleLifeSignals(this.state.selectedFeed.deviceId, false);
		}
	};

	handleTreeNodeClick = async option => {
		let isRoomNameEditable = false;
		if (option.type === SectorTypes.ROOM) {
			const response = await getRoomSettings(option.roomId, SettingsCategory.MONITORING);
			if (response.error) {
				this.setState({ error: response.error.message });
			} else {
				isRoomNameEditable =
					this.userRole !== UserRoles.VIRTUAL_SITTER &&
					response.settings.find(config => config.settingTypeId === MonitoringSettings.EditRoomNameMonitoring)?.value === 'true';
			}
		}
		this.setState({
			currentSector: { ...option, isRoomNameEditable },
		});
	};

	shouldAllowForwardManualAlertToNurses = (currentFeed, result) =>
		!currentFeed.isDefaultOwner &&
		result.initiator?.id === result.actioneeParticipantId &&
		(result.data || (!result.data && !currentFeed.voiceOverAlarmConfirmation));

	changeTab = index => {
		this.setState({ currentTab: index });
	};

	setDeviceControlsLockedStatus = (e, feed, status) => {
		if (e) {
			e.stopPropagation();
		}
		const videoFeeds = [...this.state.videoFeeds];
		const newFeeds = videoFeeds.map(item => {
			const newFeed = { ...item };
			if (item.deviceId === feed.deviceId) {
				newFeed.showDeviceControlsLockedModal = status;
			}
			return newFeed;
		});

		this.setState({
			videoFeeds: newFeeds,
		});
	};

	getIconColor = darkMode => {
		return darkMode ? DarkTheme.colors.grayFour : LightTheme.colors.grayFour;
	};

	getSwitchIconColor = darkMode => {
		return darkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFour;
	};

	toggleToMonitoring = async (event, feed) => {
		if (event) {
			event.stopPropagation();
		}

		const { floorId, deviceId, participantId } = feed;

		const response = await this.callManager.monitoringFeedAmbientToggle(participantId, false);

		if (!response.ok) {
			this.setState({ error: 'Failed to switch to monitoring!' });
			return;
		}

		this.callManager.monitoringSendOffer(participantId);

		const isPttConfigOn = await this.getWalkieTalkieSetting(feed.roomId);

		if (isPttConfigOn && !this.state.isPttJoining && !this.state.hasPttJoined) {
			this.setState({ isPttJoining: true, error: null });

			this.ptt
				.join(floorId, this.getSelectedFloor(deviceId)?.conversationId)
				.then(() => {
					this.setState({ isPttJoining: false, hasPttJoined: true });
				})
				.catch(err => {
					// eslint-disable-next-line
					console.warn('Unable to join ptt on feed:', err);
					this.setState(prevState => {
						const feeds = _.cloneDeep(prevState.videoFeeds);
						if (feeds.length > 0) {
							feeds[0].isPttAvailable = false;
						}
						return { ...prevState, videoFeeds: feeds, isPttJoining: false, hasPttJoined: false };
					});
				});
		}

		this.setState(
			prevState => {
				let { selectedFeed } = prevState;
				return {
					videoFeeds: prevState.videoFeeds.map(feed => {
						const newFeed = { ...feed };
						if (feed.deviceId === selectedFeed.deviceId) {
							newFeed.feedType = this.FeedTypes.Monitoring;
							selectedFeed = newFeed;
						}
						return newFeed;
					}),
					selectedFeed,
					isTimelineCollapsed: false,
					isStreamSettingsTabOpen: false,
				};
			},
			() => {
				this.toggleOffOtherMicrophones(this.state.selectedFeed);
			}
		);
	};

	toggleToAmbient = async (event, feed) => {
		if (event) {
			event.stopPropagation();
		}

		let { conferenceInfo } = this.state;

		const { participantId, isFeedExpanded, deviceId } = feed;

		const response = await this.callManager.monitoringFeedAmbientToggle(participantId, true);

		if (!response.ok) {
			this.setState({ error: 'Failed to switch to ambient!' });
			return;
		}

		this.ptt.leave();

		if (feed.audioTrack) {
			feed.audioTrack.stop();
		}

		this.callManager.closePeerConnection(participantId, deviceId);

		const setUpdatedFeedProps = updatedFeed => ({
			...updatedFeed,
			feedType: this.FeedTypes.AmbientMonitoring,
			isFeedExpanded: false,
			audioTrack: null,
			isMyMicActive: false,
			isPeerSpeakerActive: false,
			status: ParticipantState.CONNECTING.type,
			src: null,
			peerConnectionState: RTCPeerConnectionEnum.CONNECTION_STATE.NEW,
		});

		const updatedVideoFeeds = prevVideoFeeds =>
			prevVideoFeeds.map(videoFeed => {
				let updatedFeed = videoFeed;
				if (updatedFeed.deviceId === feed.deviceId) {
					updatedFeed = setUpdatedFeedProps(updatedFeed);
				}

				return updatedFeed;
			});

		this.setState(
			prevState => {
				const feedWithAudio = { ...prevState.previousFeedAudioEnabled };
				delete feedWithAudio[feed.deviceId];

				return {
					videoFeeds: updatedVideoFeeds(prevState.videoFeeds),
					conferenceInfo,
					isFeedZoomed: isFeedExpanded ? !isFeedExpanded : prevState.isFeedZoomed,
					selectedFeed: setUpdatedFeedProps(feed),
					hasPttJoined: false,
					previousFeedAudioEnabled: feedWithAudio,
					isTimelineCollapsed: false,
					isStreamSettingsTabOpen: false,
				};
			},
			() => {
				this.toggleOffOtherMicrophones(this.state.selectedFeed);
			}
		);

		this.callManager.startMeasuringIoTDevice({
			iotDevice: MeasureDeviceType.WATCH,
			helloDeviceId: feed.deviceOwner?.userId,
			conferenceId: conferenceInfo?.conferenceId,
			participantId,
			iotDeviceType: '',
			objectType: enums.ObjectTypes.USER,
			measureDeviceType: MeasureDeviceType.WATCH,
			startMeasure: false,
			doctorId: getUserInfo().userId,
			deviceId: feed.deviceId,
		});
		this.props.healthDataActions.setWatchMeasurements({ value: false, deviceId: feed.deviceId });
	};

	setFeedColor = (color, feed) => {};

	onDragStart = (e, index) => {
		this.setState({ draggingItem: index });
		e.dataTransfer.effectAllowed = 'move';
		e.dataTransfer.setData('text/html', e.target.parentNode.innerHTML);
	};

	onDragOver = index => {
		const { draggingItem, videoFeeds } = this.state;
		if (draggingItem === null || draggingItem === index) {
			return;
		}

		let newItems = videoFeeds.filter((_, idx) => idx !== draggingItem);
		newItems.splice(index, 0, videoFeeds[draggingItem]);

		this.setState({ videoFeeds: newItems, draggingItem: index });
	};

	onDragEnd = () => {
		this.setState({ draggingItem: null });
	};

	handleTimerChange = privacyTimer => {
		let timerError = '';

		if (privacyTimer?.value) {
			if (privacyTimer.value < 1 || privacyTimer.value > 60) {
				timerError = this.props.intl.formatMessage({ id: 'writeWholeNumber' });
			}
		}

		this.setState(prevState => ({
			privacyTimer: timerError ? prevState.privacyTimer : privacyTimer,
			timerError,
			privacyModeOptions: _.cloneDeep(privacyModeOptions(this.props.intl)),
		}));
	};

	handleTimerInputChange = value => {
		if (!value || !value.trim()) {
			this.setState({
				privacyModeOptions: _.cloneDeep(privacyModeOptions(this.props.intl)),
			});
		}
	};

	isWholeNumber = inputValue => {
		const number = Number(inputValue);
		return !isNaN(number) && Number.isInteger(number);
	};

	checkIsValidNewOption = inputValue => {
		const number = Number(inputValue);
		let isValid =
			inputValue.trim() !== '' &&
			!isNaN(number) &&
			Number.isInteger(number) &&
			!this.state.privacyModeOptions.find(item => item.value === number);

		if (isValid) {
			let tempOptions = [
				...this.state.privacyModeOptions,
				{ value: number, label: `${number} ${this.props.intl.formatMessage({ id: 'minutes' })}` },
			];

			tempOptions.sort((a, b) => parseInt(a.value) - parseInt(b.value));

			this.setState({ privacyModeOptions: tempOptions });
		}

		return isValid;
	};

	setDiscontinueMonitoringReasons = (val, healthSystemId) => {
		this.setState(prevState => {
			const newMap = new Map(prevState.discontinueMonitoringReasons);
			newMap.set(healthSystemId, val.teamSettingOptions);
			return { discontinueMonitoringReasons: newMap };
		});
	};

	setPatientOutOfRoomReasons = (val, healthSystemId) => {
		this.setState(prevState => {
			const newMap = new Map(prevState.patientOutOfRoomOptions);
			newMap.set(healthSystemId, val.teamSettingOptions);
			return { patientOutOfRoomOptions: newMap };
		});
	};

	getDiscontinueMonitoringReasonsList = healthSystemId => {
		let newList = [];
		const foundHsReasons = this.state.discontinueMonitoringReasons.get(healthSystemId);

		if (foundHsReasons) {
			newList = foundHsReasons.map(item => ({
				...item,
				value: item.id,
				label: item.option,
			}));
			newList.push({
				id: 'Other',
				option: 'Other',
				value: 'Other',
				label: 'Other',
			});
		}
		return newList;
	};

	render() {
		const {
			videoFeeds,
			conferenceInfo,
			monitoringSessions,
			isSessionLoading,
			disableButtons,
			hasActiveConference,
			conferenceEndReason,
			privacyModeOptions,
		} = this.state;
		const feeds = videoFeeds;
		const shouldShowConferenceEnded = [
			ConferenceEndReason.DROPPED,
			ConferenceEndReason.TERMINATED_BY_ADMINISTRATOR,
			ConferenceEndReason.UNDEFINED,
			ConferenceEndReason.CLEANUP,
			ConferenceEndReason.OWNER_LEFT,
		].includes(conferenceEndReason);

		let roomTreeTab = 0;
		let chatTab = 1;

		if (this.state.selectedFeed && this.state.conferenceInfo) {
			roomTreeTab = 1;
			chatTab = 2;
		}

		const filteredFeeds = feeds.filter(item => item?.deviceId);

		const sharedViewFromAnotherNurse = monitoringSessions.find(item => item.userCreated && item.userCreated?.id !== item.nurseId);

		const haveVideoFeeds = this.state.videoFeeds?.length > 0;

		const isTimelineCollapsed = this.state.isTimelineCollapsed && haveVideoFeeds;

		const isStreamSettingsTabOpen = this.state.isStreamSettingsTabOpen && haveVideoFeeds;

		return (
			<>
				<MainLayout isMonitoring={true}>
					<audio ref={this.pressToTalkAudioRef} />
					{!this.props.healthSystems.allHealthSystems.length && (
						<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
							<Loader />
						</Grid>
					)}
					{this.props.healthSystems.allHealthSystems.length && (
						<Grid
							className={classNames(
								'monitoring-grid',
								this.state.screenType.isSmall ? 'mobile-monitoring-grid' : '',
								this.shouldShowLifeSignalsPopup() ? 'grid-template-columns-1' : '',
								!this.shouldShowLifeSignalsPopup() && isTimelineCollapsed ? 'timeline-collapsed' : ''
							)}
							stretch='100%'>
							{((this.state.screenType.isSmall && !this.props.healthSystems.isLeftNavigationExpanded) ||
								!this.state.screenType.isSmall) && (
								<div className='users-view'>
									<Grid
										stretch='100%'
										className={classNames(
											this.shouldShowAlerts() ? 'alert-view-on' : 'alert-view-off',
											this.state.selectedFeed?.isLifeSignalsPopupOpen ? 'life-signals-view-on' : ''
										)}>
										<main className='main-view monitoring-view'>
											<section className='not-expanded-monitoring-grid'>
												{!shouldShowConferenceEnded && (
													<>
														{!hasActiveConference && (
															<>
																{feeds.length !== 0 && !isSessionLoading && (
																	<>
																		{getConfigurationValue(
																			this.props.healthSystemConfigurations[MonitoringSettings.EnableMultipleFeedAudio]
																		) && (
																			<div className='monitoring-header audio-monitoring-header'>
																				<div>
																					<i className='material-icons'>volume_up</i>
																					<p>{translate('audioFromMonitoringFeeds')}</p>
																					<div
																						className='rounded-slider-switch'
																						onClick={() => {
																							this.toggleAllSpeakers(!this.state.audioFromMonitoringFeeds);
																						}}>
																						<input
																							type='checkbox'
																							checked={this.state.audioFromMonitoringFeeds}
																							onChange={() => null}
																						/>
																						<span className='rounded-slider' />
																					</div>
																				</div>
																				<Button
																					border='none'
																					background={this.state.isMicrophoneForAllFeedsEnabled ? 'var(--red-1)' : 'inherit'}
																					color={this.state.isMicrophoneForAllFeedsEnabled ? 'var(--gray-0)' : 'inherit'}
																					text={translate(
																						this.state.isMicrophoneForAllFeedsEnabled ? 'microphoneIsOn' : 'talkToPatients'
																					)}
																					icon='mic'
																					onClick={this.toggleAllMicrophones}
																				/>
																			</div>
																		)}

																		<Grid
																			className={classNames(
																				'monitoring-feeds',
																				`feeds-${filteredFeeds.length}`,
																				this.state.isFeedZoomed && filteredFeeds.some(item => item.isFeedExpanded)
																					? 'zoomed-feed'
																					: '',
																				getConfigurationValue(
																					this.props.healthSystemConfigurations[MonitoringSettings.EnableMultipleFeedAudio]
																				)
																					? 'has-enable-audio'
																					: '',
																				filteredFeeds.length > 0 ? 'no-monitoring-list' : '',
																				filteredFeeds.length > 1 ? 'feeds-with-basic-icons' : '',
																				filteredFeeds.length > 16 ? 'smaller-icons-wrapper' : '',
																				this.shouldShowLifeSignalsPopup() ? 'iframe-is-active' : '',
																				filteredFeeds.length > 12 ? 'more-12-feeds' : ''
																			)}
																			gridGap='5px'>
																			{filteredFeeds.map((feed, index) => {
																				return (
																					<React.Fragment key={feed.deviceId}>
																						{feed.feedType === this.FeedTypes.AmbientMonitoring ? (
																							<AlertFeed
																								callManager={this.callManager}
																								onDragStart={e => this.onDragStart(e, index)}
																								onDragOver={() => this.onDragOver(index)}
																								onDragEnd={this.onDragEnd}
																								feed={feed}
																								className={classNames(
																									feeds.length > 1 && this.state.selectedFeed?.deviceId === feed.deviceId
																										? 'selected-feed'
																										: ''
																								)}
																								intl={this.props.intl}
																								onCloseClick={discontinueReasonsStatus => {
																									if (feed.isPatientDischarged) {
																										this.removeFeed(feed?.deviceId);
																										return;
																									}
																									this.setState({
																										feedToClose: {
																											...feed,
																											shouldShowDiscontinueReasons: discontinueReasonsStatus,
																										},
																									});
																								}}
																								onSettingsClick={() => this.toggleAlerts(feed)}
																								onManageAlertClick={event => this.toggleToMonitoring(event, feed)}
																								toggleToMonitoring={event => this.toggleToMonitoring(event, feed)}
																								onProviderIntervening={() => null}
																								isFromMonitoring={true}
																								onToggleAlert={() => {
																									if (feed.isPatientDischarged) {
																										return;
																									}
																									this.toggleAlerts(feed);
																									videoFeeds.forEach(feed => {
																										if (feed.isMyMicActive) {
																											this.toggleMyMicrophone(feed);
																										}

																										if (feed.isPeerSpeakerActive) {
																											this.togglePeerSpeaker(feed);
																										}
																									});
																								}}
																								reAddFeed={deviceId =>
																									this.addFeed({
																										deviceId,
																										feedType: this.FeedTypes.AmbientMonitoring,
																										isInitial: false,
																									})
																								}
																								closeDeviceLockedModal={e => {
																									this.setDeviceControlsLockedStatus(e, feed, false);
																								}}
																								privacyModeError={feed.privacyModeError}
																								closePrivacyModeError={event => this.closePrivacyModeError(event, feed)}
																								setDiscontinueMonitoringReasons={response =>
																									this.setDiscontinueMonitoringReasons(response, feed?.healthSystemId)
																								}
																								setPatientOutOfRoomReasons={response =>
																									this.setPatientOutOfRoomReasons(response, feed?.healthSystemId)
																								}
																							/>
																						) : (
																							conferenceInfo?.conferenceId && (
																								<VideoFeed
																									isDraggingItem={this.state.draggingItem === index}
																									onDragStart={e => this.onDragStart(e, index)}
																									onDragOver={() => this.onDragOver(index)}
																									onDragEnd={this.onDragEnd}
																									className={classNames(
																										feed.isFeedExpanded ? 'expand-feed' : '',
																										feeds.length > 1 && this.state.selectedFeed?.deviceId === feed.deviceId
																											? 'selected-feed'
																											: ''
																									)}
																									isFitToScreen={this.props.isFitToScreen}
																									feed={feed}
																									disableButtons={disableButtons}
																									onCloseClick={discontinueReasonsStatus => {
																										if (feed.isPatientDischarged) {
																											this.removeFeed(feed?.deviceId);
																											return;
																										}
																										this.setState({
																											feedToClose: {
																												...feed,
																												shouldShowDiscontinueReasons: discontinueReasonsStatus,
																											},
																										});
																									}}
																									onExpandClick={event => this.toggleExpandFeed(feed, event)}
																									onToggleNightVision={(feed, event) => this.toggleNightVision(feed, event)}
																									onToggleMyMicrophone={(item, event) => this.toggleMyMicrophone(item, event)}
																									onTogglePeerSpeaker={(item, event, status) =>
																										this.togglePeerSpeaker(item, event, status)
																									}
																									onToggleWalkieTalkie={this.toggleWalkieTalkie}
																									conferenceInfo={conferenceInfo}
																									callManager={this.callManager}
																									stopVoiceOver={this.stopVoiceOver}
																									toggleVitalSigns={() => this.toggleVitalSigns(feed)}
																									togglePrivacyMode={(privacy = feed.aiPrivacyStatus) =>
																										this.togglePrivacyMode(
																											feed.deviceId,
																											privacy,
																											feed.deviceOwner?.healthcareUserId
																										)
																									}
																									onTogglePtz={e => this.onTogglePtz(feed, e)}
																									setIvBagPercentage={this.setIvBagPercentage}
																									onToggleEmergencyButtons={() => this.onToggleEmergencyButtons(feed)}
																									numberOfFeeds={feeds.length}
																									onCallNurses={user => this.onCallNurses(feed, user)}
																									ptt={this.ptt}
																									participantsTalking={this.state.participantsTalking}
																									hierarchyNaming={this.getHierarchyNaming(feed)}
																									floorName={this.getSelectedFloor(feed.deviceId)?.floorName}
																									conversationId={this.getSelectedFloor(feed.deviceId)?.conversationId}
																									toggleAlerts={() => {
																										if (feed.isPatientDischarged) {
																											return;
																										}
																										this.toggleAlerts(feed);
																									}}
																									channelId={this.getSelectedChannel(feed.floorId)?.id}
																									callHelloPatient={() => this.callHelloPatient(feed)}
																									startEmergencyCall={() => this.setState({ isEmergencyCallOpen: true })}
																									context={this.context}
																									toggleRTPSgridView={this.toggleRTPSgridView}
																									onAiSettingClick={status => this.onAiSettingClick(status, feed)}
																									careEventFired={this.careEventFired}
																									reAddFeed={deviceId =>
																										this.addFeed({
																											deviceId,
																											feedType: this.FeedTypes.Monitoring,
																											isInitial: false,
																										})
																									}
																									aiSettings={this.props.aiSettings}
																									videoFeeds={this.state.videoFeeds}
																									setAiSettings={this.props.aiSettingsActions.setPatientAiSettings}
																									setSnoozeType={this.setAlertSnoozePerFeed}
																									toggleBioBeat={
																										this.state.selectedFeed?.isBioBeatActive ? this.toggleBioBeat : null
																									}
																									toggleLifeSignals={
																										this.state.selectedFeed?.isLifeSignalsActive ? this.toggleLifeSignals : null
																									}
																									toggleMindray={
																										this.state.selectedFeed?.toggleMindrayActive ? this.toggleMindray : null
																									}
																									watchMeasurementsVisible={this.props.watchMeasurementsVisible}
																									isDarkMode={this.props.user.darkMode}
																									onAlertInfoClose={this.onAlertInfoClose}
																									onAnalyticsToastMessageClose={this.onAnalyticsToastMessageClose}
																									showLifeSignalsIframe={e => this.toggleLifeSignalsFrame(feed, e)}
																									setIsNoteShowing={val => this.setState({ isNoteShowing: val })}
																									toggleStatAlarm={e => this.toggleStatAlarm(feed, e)}
																									setAlertToMap={alertToMap => this.setState({ alertToMap })}
																									voiceOverLanguages={this.voiceOverLanguages}
																									closeStatAlarmConfirmation={() => this.closeStatAlarmConfirmation(feed)}
																									showDeviceControlsLockedModal={e => {
																										this.setDeviceControlsLockedStatus(e, feed, true);
																									}}
																									closeDeviceLockedModal={e => {
																										this.setDeviceControlsLockedStatus(e, feed, false);
																									}}
																									customDisplayNames={this.props.customDisplayNames}
																									setActiveDevice={this.setActiveDevice}
																									sendCameraEvent={(eventType, data) =>
																										this.sendCameraEvent(eventType, data, feed)
																									}
																									shouldShowPTZ={this.shouldShowPTZ(feed)}
																									toggleFallPrevention={this.toggleFallPrevention}
																									patientNotes={this.props.patientNotes}
																									toggleOffOtherMicrophones={() => this.toggleOffOtherMicrophones(feed)}
																									isSimplifiedPatientForm={this.props.isSimplifiedPatientForm}
																									showDeviceControlsLockedModalState={this.state.showDeviceControlsLockedModal}
																									toggleToAmbient={event => this.toggleToAmbient(event, feed)}
																									selectedFeed={this.state.selectedFeed}
																									verbalRedirection={this.state.verbalRedirection}
																									privacyModeError={feed.privacyModeError}
																									closePrivacyModeError={event => this.closePrivacyModeError(event, feed)}
																									configurations={this.props.configurations}
																									setOutOfRoomOn={value =>
																										this.setOutOfRoomOn(value, feed?.deviceId, feed?.healthSystemId)
																									}
																									setDiscontinueMonitoringReasons={response =>
																										this.setDiscontinueMonitoringReasons(response, feed?.healthSystemId)
																									}
																									setPatientOutOfRoomReasons={response =>
																										this.setPatientOutOfRoomReasons(response, feed?.healthSystemId)
																									}
																								/>
																							)
																						)}
																					</React.Fragment>
																				);
																			})}
																		</Grid>
																	</>
																)}
																{!isSessionLoading && feeds.length === 0 && (
																	<Grid width='100%' rows='auto' vertAlign='center' gridGap='15px' stretch='100%'>
																		<div style={{ textAlign: 'center' }}>
																			{this.state.currentSector?.type === SectorTypes.ROOM && (
																				<>
																					<ChangeRoomName
																						currentRoom={{
																							...this.state.currentSector,
																							sectorName: this.state.currentSector.name,
																						}}
																						setError={msg => this.setState({ error: msg })}
																					/>
																					{!this.state.currentSector.helloDeviceId && (
																						<p>
																							{translate('roomNoDeviceAssigned')}, <br />
																							{translate('roomsNeedDevice')} {translate('monitorRoom')}.
																						</p>
																					)}
																					{this.state.currentSector.helloDeviceId && (
																						<p>
																							{translate('monitorRoom')}, <br />
																							{translate('clickAddIconInTree')}.
																						</p>
																					)}
																				</>
																			)}
																			{this.state.currentSector?.type !== SectorTypes.ROOM &&
																				!this.state.isFetchingSessionsLoading && (
																					<>
																						{(!this.showSessions() || !sharedViewFromAnotherNurse) && (
																							<p>
																								{translate('monitorRoom')}, <br />
																								{translate('openHospitalChooseDepartment')}, <br />
																								{translate('clickFloorSelectRoom')}.
																							</p>
																						)}
																						{this.showSessions() && sharedViewFromAnotherNurse && (
																							<>
																								<p className='handover-main'>
																									{translate('assignedSessionFrom')}{' '}
																									{sharedViewFromAnotherNurse.userCreated.firstName}{' '}
																									{sharedViewFromAnotherNurse.userCreated.lastName}
																								</p>
																								<Button
																									text={this.props.intl.formatMessage({ id: 'openSession' })}
																									size='medium'
																									onClick={event => this.openSharedView(event, sharedViewFromAnotherNurse)}
																								/>
																								<small className='handover-small'>
																									{translate('disregardOpenHospital')}
																									<br />
																									{translate('chooseDepartmentFloorRoom')}
																								</small>
																							</>
																						)}
																					</>
																				)}
																		</div>
																	</Grid>
																)}
																{isSessionLoading && (
																	<div className='loading-session'>
																		<p>{translate('loadingSession')}</p>
																		<Loader />
																	</div>
																)}
															</>
														)}
														{hasActiveConference && (
															<Grid width='100%' rows='auto' vertAlign='center' gridGap='15px' stretch='100%'>
																<div style={{ textAlign: 'center' }}>
																	<p>
																		{translate('alreadyMonitoringPatients')} <br />
																		{translate('tryAgainAfterSessionEnds')}
																	</p>
																</div>
															</Grid>
														)}
													</>
												)}
												{shouldShowConferenceEnded && (
													<Grid width='100%' rows='auto' vertAlign='center' gridGap='15px' stretch='100%'>
														<div style={{ textAlign: 'center' }}>
															<p>
																{this.getConferenceEndedText(conferenceEndReason)} <br />
															</p>
														</div>
													</Grid>
												)}

												<Alert
													display={this.state.shouldShowSwitchToHelloCamError}
													onClose={() => {
														this.setState({ shouldShowSwitchToHelloCamError: false });
													}}
													fixed={true}
													persist={true}
													message={translate('youCantSwitchCam', { value: this.props.customDisplayNames.helloName })}
													variant='dark'
													position='top'
												/>
											</section>
										</main>
										{this.state.selectedFeed && this.state.selectedFeed.isEhrIntegrationVisible && (
											<EhrIntegration
												onClose={() => this.toggleVitalSigns(this.state.selectedFeed)}
												patientName={this.state.selectedFeed.deviceOwner?.patientFormattedName}
											/>
										)}
										{this.shouldShowLifeSignalsPopup() && (
											<LifeSignalsPopup
												onClose={e => this.toggleLifeSignalsFrame(this.state.selectedFeed, e)}
												feed={this.state.selectedFeed}
												isDefaultOwner={this.state.selectedFeed?.isDefaultOwner}
											/>
										)}
									</Grid>
								</div>
							)}
							{this.state.screenType.isSmall && (
								<span
									className='collapse-second-column collapse-icon'
									onClick={this.props.healthSystemActions.toggleLeftNavigation}>
									<i className='material-icons-outlined'>
										{this.props.healthSystems.isLeftNavigationExpanded ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}
									</i>
								</span>
							)}
							{((this.state.screenType.isSmall && this.props.healthSystems.isLeftNavigationExpanded) ||
								(!this.state.screenType.isSmall && !this.shouldShowLifeSignalsPopup())) && (
								<aside
									className={classNames('right-side-monitoring', 'hello-list', 'hospitals-list', {
										collapsed: isTimelineCollapsed,
										hidden: this.state.isConversationShown,
									})}>
									{haveVideoFeeds && (
										<div
											className={classNames('monitoring-right-side-action-bar flex flex-space-between', {
												'column-direction': isTimelineCollapsed,
											})}>
											<Button
												icon={isTimelineCollapsed ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}
												onClick={() =>
													this.setState(prevState => ({
														isTimelineCollapsed: !prevState.isTimelineCollapsed,
													}))
												}
											/>
											<div
												className={classNames('flex ', {
													'column-direction': isTimelineCollapsed,
												})}>
												{this.state.conferenceInfo && (
													<Button
														position='left'
														tooltip={this.props.intl.formatMessage({ id: this.state.sessionIdMessage })}
														icon='content_copy'
														onClick={evt => {
															evt.stopPropagation();
															navigator.clipboard.writeText(this.state.conferenceInfo.conferenceId);
															this.setState(() => ({
																sessionIdMessage: 'copiedSessionId',
															}));
															setTimeout(() => {
																this.setState(() => ({
																	sessionIdMessage: 'copySessionId',
																}));
															}, 2000);
														}}
													/>
												)}
												{!isTimelineCollapsed &&
													this.state.selectedFeed?.roomId &&
													getConfigurationValue(this.props.healthSystemConfigurations[MonitoringSettings.CallPatient]) && (
														<Button
															position='left'
															tooltip={this.props.intl.formatMessage({ id: 'streamSettingsModalTitle' })}
															className={isStreamSettingsTabOpen && 'active'}
															icon='settings'
															onClick={evt => {
																evt.stopPropagation();
																this.setState(prevState => ({
																	isStreamSettingsTabOpen: !prevState.isStreamSettingsTabOpen,
																}));
															}}
														/>
													)}
											</div>
										</div>
									)}
									{!isTimelineCollapsed && !isStreamSettingsTabOpen && (
										<Tabs
											activeIndex={this.state.currentTab}
											className={isTimelineCollapsed ? 'hidden' : ''}
											onChange={index =>
												this.setState({ currentTab: index }, () => {
													if (this.state.currentTab === chatTab) {
														this.toggleConversation();
													}
												})
											}>
											<TabList>
												{this.state.selectedFeed?.roomId && (
													<Tab className='cursor-pointer'>
														<RoomTree
															color={this.state.currentTab === 0 ? LightTheme.colors.blueSeven : this.getIconColor(user.darkMode)}
														/>
														{this.state.selectedFeed.roomName}
													</Tab>
												)}
												<Tab className='cursor-pointer'>
													<TreeViewIcon
														color={
															this.state.currentTab === roomTreeTab
																? LightTheme.colors.blueSeven
																: this.getIconColor(user.darkMode)
														}
													/>
													{translate('allRooms')}
												</Tab>
											</TabList>
											<TabPanels>
												{this.state.selectedFeed?.roomId && (
													<TabPanel>
														<MonitoringTimeline
															isDarkMode={this.props.user.darkMode}
															conferenceInfo={this.state.conferenceInfo}
															feed={this.state.selectedFeed}
															togglePrivacyMode={(deviceId, aiPrivacyStatus, patientId) =>
																this.toggleOrShowPrivacyTimer(deviceId, aiPrivacyStatus, patientId)
															}
															toggleOffPrivacy={() => this.toggleOffPrivacy(this.state.selectedFeed?.deviceId)}
															setIvBagPercentage={this.setIvBagPercentage}
															isSimplifiedPatientForm={this.props.isSimplifiedPatientForm}
															hierarchyNaming={this.getHierarchyNaming(this.state.selectedFeed)}
															isNoteShowing={this.state.isNoteShowing}
															toggleFallPrevention={this.toggleFallPrevention}
															toggleNightVision={() => this.toggleNightVision(this.state.selectedFeed)}
															onAiSettingClick={status => this.onAiSettingClick(status, this.state.selectedFeed)}
															numberOfFeeds={feeds.length}
															setSnoozeType={this.setAlertSnoozePerFeed}
															stopVoiceOver={this.stopVoiceOver}
															onAlertInfoClose={this.onAlertInfoClose}
															verbalRedirection={this.state.verbalRedirection}
															setVerbalRedirection={item => this.setState({ verbalRedirection: item })}
															aiSettings={this.props.aiSettings}
															isDefaultOwner={this.state.selectedFeed.isDefaultOwner}
															patientId={this.state.selectedFeed.deviceOwner?.healthcareUserId}
															conversationId={this.getSelectedFloor(this.state.selectedFeed.deviceId)?.conversationId}
															isBioBeatActive={this.state.selectedFeed?.isBioBeatActive || false}
															toggleBioBeat={this.toggleBioBeat}
															toggleLifeSignals={this.toggleLifeSignals}
															toggleMindray={this.toggleMindray}
															isLifeSignalsActive={this.state.selectedFeed?.isLifeSignalsActive || false}
															isMindrayActive={this.state.selectedFeed?.isMindrayActive || false}
															handleDiscontinue={discontinueReasonsStatus =>
																this.setState({
																	feedToClose: {
																		...this.state.selectedFeed,
																		shouldShowDiscontinueReasons: discontinueReasonsStatus,
																	},
																})
															}
															setDeviceOwner={() => this.setDeviceOwner(this.state.selectedFeed?.deviceId)}
														/>
													</TabPanel>
												)}
												<TabPanel>
													<div>
														<div className='flex flex-align-center patient-view'>
															<h4 className='margin-bottom-0 no-padding'>{translate('fitToScreenVideoFeeds')}</h4>
															<div
																className='toggle-switch toggle-blue'
																onClick={() => this.props.healthDataActions.setIsFitToScreen(!this.props.isFitToScreen)}>
																<input type='checkbox' checked={this.props.isFitToScreen} readOnly />
																<span className='toggle-body'>
																	<span className='on-text'>{translate('on')}</span>
																	<span className='off-text'>{translate('off')}</span>
																</span>
															</div>
															{/* <div
															className='rounded-slider-switch'
															onClick={() => this.props.healthDataActions.setIsFitToScreen(!this.props.isFitToScreen)}>
															<input type='checkbox' checked={this.props.isFitToScreen} onChange={null}/>
															<span className='rounded-slider' />
														</div> */}
														</div>
													</div>
													{this.showSessions() && (
														<Sessions>
															{sharedViewFromAnotherNurse && (
																<HandoverSession
																	isDarkMode={this.props.user.darkMode}
																	sharedViewFromAnotherNurse={sharedViewFromAnotherNurse}
																	videoFeeds={videoFeeds}
																	openSharedView={this.openSharedView}
																	saveSession={this.saveSession}
																	toggleTransfer={this.toggleTransfer}
																	toggleReOrder={this.toggleReOrder}
																	toggleDeleteSessionModal={this.toggleDeleteSessionModal}
																	setSessionToClose={() =>
																		this.setState({
																			sessionToClose: sharedViewFromAnotherNurse,
																		})
																	}
																/>
															)}

															<h4>{translate('savedSessions')}</h4>
															{this.state.errorFetchingSessions && <ul>{this.state.errorFetchingSessions}</ul>}
															<ul className='monitoring-session-ul'>
																{monitoringSessions
																	.filter(item => !item.userCreated || item.userCreated?.id === item.nurseId)
																	.map(session => (
																		<li className={session.isActive ? 'active-monitoring-session' : ''} key={session.id}>
																			<div
																				onClick={event => {
																					if (
																						this.state.videoFeeds.length === 0 ||
																						(this.state.currentSessionId && this.state.currentSessionId === session?.id)
																					) {
																						this.openSession(event, session);
																						return;
																					}
																					this.setState({
																						sessionToOpen: {
																							event,
																							session,
																						},
																					});
																				}}>
																				<img
																					src={`${healthCareCdnUrl}monitoring/session${session.isActive ? '-active' : ''}.svg`}
																					alt='close_session'
																				/>
																				<span> {session.name}</span>
																			</div>
																			{session.isActive && (
																				<div className='flex'>
																					<div
																						className={videoFeeds.length === 0 || !session.isActive ? 'disabled' : ''}
																						onClick={() => this.saveSession(session)}
																						id='save-session'>
																						<img
																							src={`${healthCareCdnUrl}${
																								this.props.user.darkMode ? 'dark-mode/save-gray.svg' : 'monitoring/save.svg'
																							}`}
																							alt='save_session'
																						/>
																						<img
																							className='active-session-img'
																							src={`${healthCareCdnUrl}monitoring/save-active.svg`}
																							alt='save_session'
																						/>
																						<span>{translate('save')}</span>
																					</div>
																					<div
																						className={videoFeeds.length < 1 || !session.isActive ? 'disabled' : ''}
																						onClick={() => this.toggleTransfer(session)}
																						id='transfer-session'>
																						<i
																							className='material-symbols-outlined'
																							style={{
																								color: this.props.user.darkMode ? '#787F89' : '#3C4257',
																							}}>
																							forward
																						</i>
																						<span>{translate('transfer')}</span>
																					</div>

																					<div
																						className={videoFeeds.length <= 1 || !session.isActive ? 'disabled' : ''}
																						onClick={() => this.toggleReOrder(session)}
																						id='reorder-session'>
																						<img
																							src={`${healthCareCdnUrl}${
																								this.props.user.darkMode ? 'dark-mode/sort-gray.svg' : 'monitoring/sort.svg'
																							}`}
																							alt='re-order-session'
																							className='position-relative edit-order-img'
																						/>
																						<img
																							src={`${healthCareCdnUrl}monitoring/sort-active.svg`}
																							alt='re-order-session'
																							className='position-relative edit-order-img active-session-img'
																						/>
																						<span>{translate('sort')}</span>
																					</div>
																					<div
																						onClick={() => this.toggleDeleteSessionModal(session)}
																						className={session.isCurrent || !session.isActive ? 'disabled' : ''}
																						id='delete-session'>
																						<img
																							src={`${healthCareCdnUrl}${
																								this.props.user.darkMode ? 'dark-mode/delete-gray.svg' : 'monitoring/trash.svg'
																							}`}
																							alt='delete_session'
																						/>
																						<img
																							className='active-session-img'
																							src={`${healthCareCdnUrl}monitoring/trash-active.svg`}
																							alt='delete_session'
																						/>
																						<span>{translate('delete')}</span>
																					</div>

																					<div
																						className={videoFeeds.length === 0 || !session.isActive ? 'disabled' : ''}
																						onClick={() =>
																							this.setState({
																								sessionToClose: session,
																							})
																						}
																						id='close-session'>
																						<img
																							src={`${healthCareCdnUrl}${
																								this.props.user.darkMode ? 'dark-mode/remove-gray.svg' : 'monitoring/remove.svg'
																							}`}
																							alt='close_session'
																						/>
																						<img
																							className='active-session-img'
																							src={`${healthCareCdnUrl}monitoring/remove-active.svg`}
																							alt='close_session'
																						/>
																						<span>{translate('close')}</span>
																					</div>
																				</div>
																			)}
																		</li>
																	))}
															</ul>
														</Sessions>
													)}
													{getConfigurationValue(
														this.props.healthSystemConfigurations[MonitoringSettings.MixedMonitoringView]
													) && (
														<div>
															<ul className='tabs'>
																<li
																	className={classNames(
																		'tab cursor-pointer',
																		this.state.feedType === this.FeedTypes.Monitoring ? 'active' : ''
																	)}
																	onClick={() =>
																		this.setState({
																			feedType: this.FeedTypes.Monitoring,
																		})
																	}>
																	<a>
																		<VideoMonitoring
																			color={
																				this.state.feedType === this.FeedTypes.Monitoring
																					? LightTheme.colors.blueSeven
																					: this.getSwitchIconColor(this.props.user.darkMode)
																			}
																		/>
																		Video Monitoring
																	</a>
																</li>
																<li
																	className={classNames(
																		'tab cursor-pointer',
																		this.state.feedType === this.FeedTypes.AmbientMonitoring ? 'active' : ''
																	)}
																	onClick={() =>
																		this.setState({
																			feedType: this.FeedTypes.AmbientMonitoring,
																		})
																	}>
																	<a>
																		<AmbientMonitoring
																			color={
																				this.state.feedType === this.FeedTypes.AmbientMonitoring
																					? LightTheme.colors.blueSeven
																					: this.getSwitchIconColor(this.props.user.darkMode)
																			}
																		/>
																		{translate('ambientMonitoring')}
																	</a>
																</li>
															</ul>
														</div>
													)}
													<SectorListMayo
														isMonitoring={true}
														isSavedSessionsHidden={!this.showSessions()}
														onAddDevice={this.onAddDevice}
														onRemoveFeed={async (deviceId, roomName) => {
															const found = this.state.videoFeeds.find(item => item.deviceId === deviceId);
															if (found?.isPatientDischarged) {
																this.removeFeed(deviceId);
																return;
															}
															this.setState({
																feedToClose: {
																	deviceId,
																	roomName,
																	isDefaultOwner: found?.isDefaultOwner,
																	healthSystemId: found?.healthSystemId,
																	shouldShowDiscontinueReasons: await this.getDiscontinueReasonSetting(found.roomId),
																},
															});
														}}
														onTreeViewLinkClick={option => this.handleTreeNodeClick(option)}
														videoFeeds={this.state.videoFeeds}
													/>
												</TabPanel>
											</TabPanels>
										</Tabs>
									)}
									{!isTimelineCollapsed && isStreamSettingsTabOpen && (
										<StreamSettingsTab
											onDismiss={() =>
												this.setState(() => ({
													isStreamSettingsTabOpen: false,
												}))
											}
										/>
									)}
								</aside>
							)}
							<Modal
								display={this.state.showMaxPeerConnectionsModal}
								position='center'
								closeButtonText={translate('dismiss')}
								primaryButtonLabel=''
								onModalClose={() => {
									this.setState({ showMaxPeerConnectionsModal: false });
								}}>
								<Form title={translate('warning')}>
									<p>{translate('browserPeerConnectionsLimitReached')}</p>
								</Form>
							</Modal>
							<Modal
								display={this.state.showSessionPopup}
								position='center'
								onModalClose={() => this.toggleSessionModal(false)}
								onModalSubmit={() => this.createNewSession(this.state.newSessionName)}>
								<div className='save-session'>
									<h3>{translate('saveSession')}</h3>
									<Input
										type='text'
										onChange={this.setSessionName}
										name='newSessionName'
										value={this.state.newSessionName}
										validationOptions={{}}
										label={translate('sessionName')}
										placeholder={this.props.intl.formatMessage({ id: 'enterSessionName' })}
										maxLength={100}
									/>
								</div>
							</Modal>
							<Modal
								className='wrapper-modal border-radius-modal-wrapper disable-modal'
								display={this.state.showDeleteSessionModal}
								position='center'
								onModalClose={() => this.toggleDeleteSessionModal(null)}
								onModalSubmit={async () => {
									await this.saveAllSessionDraftsToHistory();
									this.deleteSession(this.state.sessionToDelete);
								}}
								primaryButtonLabel={translate('delete')}>
								<Form title={translate('deleteSession')}>
									<p>{translate('sureDeleteSession')}</p>
								</Form>
							</Modal>
							<Modal
								className='wrapper-modal border-radius-modal-wrapper disable-modal discontinue-monitoring'
								display={this.state.feedToClose}
								position='center'
								onModalClose={() =>
									this.setState({
										feedToClose: null,
										reasonToDiscontinueMonitoring: null,
									})
								}
								onModalSubmit={() => {
									this.removeFeed(
										this.state.feedToClose?.deviceId,
										false,
										this.state.reasonToDiscontinueMonitoring?.label || '',
										!this.state.feedToClose?.shouldShowDiscontinueReasons
									);
									this.setState({
										feedToClose: null,
									});
								}}
								primaryButtonLabel={translate('continue')}
								isSubmitDisabled={
									this.state.feedToClose?.isDefaultOwner || !this.state.feedToClose?.shouldShowDiscontinueReasons
										? false
										: !this.state.reasonToDiscontinueMonitoring
								}>
								<Form
									title={
										this.state.feedToClose?.isDefaultOwner || !this.state.feedToClose?.shouldShowDiscontinueReasons
											? translate('removeRoomMonitoring', {
													value: this.state.feedToClose?.roomName,
												})
											: translate('discontinuePatientMonitoring')
									}>
									<p>
										{(this.state.feedToClose?.isDefaultOwner || !this.state.feedToClose?.shouldShowDiscontinueReasons) &&
											translate('sureRemoveRoomMonitoring')}
										{!this.state.feedToClose?.isDefaultOwner && this.state.feedToClose?.shouldShowDiscontinueReasons && (
											<>
												{translate('room')}: {this.state.feedToClose?.roomName}
											</>
										)}
									</p>
									{!this.state.feedToClose?.isDefaultOwner && this.state.feedToClose?.shouldShowDiscontinueReasons && (
										<p className='top-15'>{translate('stopVideoMonitoring')}</p>
									)}
									{!this.state.feedToClose?.isDefaultOwner && this.state.feedToClose?.shouldShowDiscontinueReasons && (
										<ReactSelect
											options={this.getDiscontinueMonitoringReasonsList(this.state.feedToClose?.healthSystemId)}
											value={this.state.reasonToDiscontinueMonitoring}
											onChange={event =>
												this.setState({
													reasonToDiscontinueMonitoring: event,
												})
											}
											placeholder={this.props.intl.formatMessage({ id: 'reason' })}
											classNamePrefix='react-select'
											className='react-select'
											styles={generateCustomStyles({
												darkMode: this.props.user.darkMode,
												menuListMaxHeight: 275,
												menuMaxHeight: 275,
											})}
										/>
									)}
								</Form>
							</Modal>
							<Modal
								className='wrapper-modal border-radius-modal-wrapper disable-modal'
								display={this.state.sessionToClose}
								position='center'
								onModalClose={() =>
									this.setState({
										sessionToClose: null,
									})
								}
								onModalSubmit={async () => {
									await this.saveAllSessionDraftsToHistory();
									this.closeCurrentSession(true, this.state.sessionToClose);

									this.setState({
										sessionToClose: null,
									});
								}}
								primaryButtonLabel={translate('continue')}>
								<Form title={translate('removeSessionMonitoring')}>
									<p>{translate('sureRemoveSessionMonitoring')}</p>
								</Form>
							</Modal>
							<Modal
								className='wrapper-modal border-radius-modal-wrapper disable-modal'
								display={this.state.sessionToOpen}
								position='center'
								onModalClose={() =>
									this.setState({
										sessionToOpen: null,
									})
								}
								onModalSubmit={async () => {
									await this.saveAllSessionDraftsToHistory();
									this.openSession(this.state.sessionToOpen?.event, this.state.sessionToOpen?.session);

									this.setState({
										sessionToOpen: null,
									});
								}}
								primaryButtonLabel={translate('continue')}>
								<Form title={translate('removeSessionMonitoring')}>
									<p>{translate('sureRemoveSessionMonitoring')}</p>
								</Form>
							</Modal>
							<Modal
								display={this.state.feedToSetPrivacyTimer}
								position='center'
								onModalClose={() => {
									this.setState({
										feedToSetPrivacyTimer: null,
										privacyTimer: null,
									});
								}}
								isSubmitDisabled={!this.state.privacyTimer}
								onModalSubmit={this.onPrivacyTimerSubmitModal}
								className='patient-privacy-modal'>
								<form>
									<h3>{translate('patientPrivacy')}</h3>
									<p>{translate('enablingPrivacyMode')}</p>
									<div>
										<CreatableSelect
											isClearable
											options={privacyModeOptions}
											onInputChange={this.handleTimerInputChange}
											onChange={this.handleTimerChange}
											value={this.state.privacyTimer}
											placeholder={this.props.intl.formatMessage({ id: 'enterSelectTimer' })}
											formatCreateLabel={inputValue => `${inputValue} ${this.props.intl.formatMessage({ id: 'minutes' })}`}
											styles={generateCustomStyles({ darkMode: this.props.user.darkMode })}
											isValidNewOption={inputValue => this.checkIsValidNewOption(inputValue)}
											onKeyDown={e => {
												if (e.key === 'Enter') {
													e.preventDefault();
												}
											}}
										/>
										{this.state.timerError && <span className='red-error'>{this.state.timerError}</span>}
									</div>
								</form>
							</Modal>
							<Modal
								display={shouldShowConferenceEnded}
								position='center'
								onModalClose={() => {
									window.location.reload();
								}}
								hideActionButtons={true}>
								<Form title={this.getConferenceEndedText(conferenceEndReason)}>
									<p>{translate('pleaseRefreshThePage')}</p>
								</Form>
							</Modal>
							<Prompt
								when={this.state.videoFeeds.length > 0}
								message={this.props.intl.formatMessage({ id: 'switchingToOtherTab' })}
							/>
							{this.state.isReorderFeedsVissible && (
								<ReOrderFeeds
									feeds={this.state.videoFeeds}
									toggleReOrder={() => this.toggleReOrder(null)}
									saveReorder={items => this.saveReorder(items)}
								/>
							)}
							{this.state.isTransferSessionVisible && (
								<TransferFeeds
									submitTransfer={this.onSubmitTransferSession}
									onClose={() => {
										this.setState({
											isTransferSessionVisible: false,
										});
									}}
									isDarkMode={this.props.user.darkMode}
									feeds={this.state.videoFeeds}
								/>
							)}
							{this.state.isSessionNotificationVissible && <Notification text={translate('sessionUpdatedSuccessfully')} />}
							{this.state.isObserverConferenceModalOpen && (
								<ConferenceModal
									startConferenceData={this.state.observerConferenceData}
									incomingConferenceInfo={this.state.incomingConferenceInfo}
									onCallStarted={this.onObserverConferenceStarted}
									onCallEnded={this.onObserverConferenceEnded}
									isEmergencyCallOpen={this.state.isEmergencyCallOpen}
									isDarkMode={this.props.user.darkMode}
								/>
							)}
							{this.state.isEmergencyCallOpen && <EmergencyCall onCallEnded={this.endEmergencyCall} />}
						</Grid>
					)}
					{this.props.incomingCallRenderedElsewhere && (
						<div className='monitoring-incoming-call'>
							<IncomingCall />
						</div>
					)}
					<Alert display={this.state.error} fixed hideCloseButton message={this.state.error} variant='dark' />
					<Alert
						display={this.state.statAlarmError}
						onClose={() => this.setState({ statAlarmError: null })}
						persist={true}
						message={this.state.statAlarmError}
						variant='dark'
						position='top'
					/>
					<Alert
						display={this.state.voiceOverError}
						onClose={() => this.setState({ voiceOverError: null })}
						persist={true}
						message={this.state.voiceOverError}
						variant='dark'
						position='top'
					/>
					{this.state.streamPermission && <StreamPermissions reason={this.state.streamPermission} />}
				</MainLayout>
			</>
		);
	}
}

const Sessions = styled.div`
	+ div {
		padding: 0 !important;
	}

	> h4 {
		padding: 0;
		font-size: 16px;
		font-weight: 600;
	}

	ul {
		margin: var(--spacing-m) 0;
		li {
			div:first-of-type {
				display: flex;
				align-items: center;
				color: var(--gray-5);
				font-size: 14px;

				i {
					margin-right: var(--spacing-s);
					color: var(--gray-4);
					font-size: 18px;
				}

				&:not(:first-of-type) {
					margin-left: 5px;
				}

				&:first-of-type {
					flex: 1;
				}

				img {
					height: 18px;
					margin-right: 5px;
				}
			}

			&:last-of-type {
				div:first-of-type {
					span {
						font-weight: 500;
						margin-bottom: 0;
					}
				}
			}
		}
	}
`;

Monitoring.contextType = SocketContext;

const mapStateToProps = state => {
	return {
		healthSystems: state.healthSystems,
		patients: state.myPatients.patients,
		incomingCallRenderedElsewhere: state.calls.incomingCallRenderedElsewhere,
		user: state.user,
		aiSettings: state.aiSettingsList.aiSettings,
		customDisplayNames: state.company.companySettings,
		companyConfigurations: state.company.companySettings.companyConfigurations,
		configurations: state.configurations,
		healthSystemConfigurations: state.configurations.healthSystemConfigurations,
		watchMeasurementsVisible: state.watchMeasurementsList.watchMeasurementsVisible,
		isFitToScreen: state.watchMeasurementsList.isFitToScreen,
		isSimplifiedPatientForm: state.watchMeasurementsList.isSimplifiedPatientForm,
		patientNotes: state.patientNotes,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		healthSystemActions: bindActionCreators(healthSystemsActionCreators, dispatch),
		setIncomingCallRenderedElsewhere: isRenderedElsewhere =>
			dispatch(callsActionCreators.setIncomingCallRenderedElsewhere(isRenderedElsewhere)),
		aiSettingsActions: bindActionCreators(aiSettingsActionCreators, dispatch),
		healthDataActions: bindActionCreators(healthDataActionCreators, dispatch),
		patientNotesActions: bindActionCreators(patientNotesActionCreators, dispatch),
	};
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withSocketFunctionsContext(Monitoring)));
